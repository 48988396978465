<template>
  <div>
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>
    <van-form
      ><van-field
        readonly
        clickable
        required
        :value="form.startTime"
        label="推广开始时间"
        placeholder="请选择推广开始时间"
        @click="startTimeShow = true"
      />
      <van-popup v-model="startTimeShow" position="bottom">
        <van-datetime-picker
          :min-date="startDefaultTime.min()"
          :max-date="startDefaultTime.max()"
          type="datetime"
          @confirm="
            (v) => {
              const date = new Date(v);
              const year = date.getFullYear();
              const month = date.getMonth() + 1; // 月份是从0开始的
              const day = date.getDate();
              const hour = date.getHours();
              const minute = date.getMinutes();

              // 格式化输出
              const formattedDate = `${year}-${month
                .toString()
                .padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hour
                .toString()
                .padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
              form.startTime = formattedDate;
              startTimeShow = false;
            }
          "
          @cancel="startTimeShow = false"
        />
      </van-popup>
      <van-field
        readonly
        clickable
        required
        :value="form.endTime"
        label="推广结束时间"
        placeholder="请选择推广结束时间"
        @click="endTimeShow = true"
      />

      <van-popup v-model="endTimeShow" position="bottom">
        <van-datetime-picker
          :min-date="endDefaultTime.min()"
          :max-date="endDefaultTime.max()"
          type="datetime"
          @cancel="endTimeShow = false"
          @confirm="
            (v) => {
              const date = new Date(v);
              const year = date.getFullYear();
              const month = date.getMonth() + 1; // 月份是从0开始的
              const day = date.getDate();
              const hour = date.getHours();
              const minute = date.getMinutes();

              // 格式化输出
              const formattedDate = `${year}-${month
                .toString()
                .padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hour
                .toString()
                .padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
              form.endTime = formattedDate;
              endTimeShow = false;
            }
          "
        />
      </van-popup>
      <van-field required name="radio" label="推广平台">
        <template #input>
          <van-checkbox
            v-model="form.douyin"
            @change="form3dycheckboxChange"
            shape="square"
          >
            抖音
          </van-checkbox>
          <van-checkbox
            shape="square"
            style="margin-left: 1em"
            v-model="form.xiaohongshu"
            @change="form3xhscheckboxChange"
          >
            小红书
          </van-checkbox>
          <!-- <van-checkbox-group
            v-model="form.pingtaiTypes"
            direction="horizontal"
          >
            <van-checkbox
              name="1"
              shape="square"
              @change="form3dycheckboxChange"
              >抖音</van-checkbox
            >
            <van-checkbox
              name="2"
              shape="square"
              @change="form3xhscheckboxChange"
              >小红书</van-checkbox
            >
          </van-checkbox-group> -->
        </template>
      </van-field>
      <van-field required name="checkboxGroup" label="抖音粉丝量">
        <template #input>
          <div>
            <van-row
              v-for="(item, index) in form.dyFensiliangArr"
              :key="item.name"
            >
              <van-col :span="7">
                <van-checkbox
                  @change="
                    (val) => {
                      form3dyclear(val, index);
                    }
                  "
                  :disabled="dyDisabled"
                  style="padding: 3px 0px"
                  v-model="item.checked"
                  shape="square"
                  ><span style="color: rgb(108 108 110)">{{ item.name }}</span>
                </van-checkbox></van-col
              >
              <van-col :span="7">
                <input
                  :disabled="dyDisabled"
                  :id="'dyRenInput-' + (index + 1)"
                  style="
                    width: 70%;
                    height: 0.8rem;
                    border: 1px solid #c4c9cf;
                    color: #000;
                  "
                  placeholder="0"
                  type="text"
                  maxlength="6"
                  onkeyup="value=value.replace(/\D/g,'')"
                  onafterpaste="value=value.replace(/\D/g,'')"
                  @blur="form.testA = $event.target.value"
              /></van-col>
              <van-col :span="2"> 人</van-col>
            </van-row>
          </div>
        </template>
      </van-field>
      <van-field required name="checkboxGroup" label="小红书粉丝量">
        <template #input>
          <div>
            <van-row
              v-for="(item, index) in form.xhsFensiliangArr"
              :key="item.name"
            >
              <van-col :span="7">
                <van-checkbox
                  @change="
                    (val) => {
                      form3xhsclear(val, index);
                    }
                  "
                  :disabled="xhsDisabled"
                  style="padding: 3px 0px"
                  v-model="item.checked"
                  shape="square"
                  ><span style="color: rgb(108 108 110)">{{ item.name }}</span>
                </van-checkbox></van-col
              >
              <van-col :span="7">
                <input
                  :disabled="xhsDisabled"
                  :id="'xhsRenInput-' + (index + 1)"
                  style="
                    width: 70%;
                    height: 0.8rem;
                    border: 1px solid #c4c9cf;
                    color: #000;
                  "
                  placeholder="0"
                  type="text"
                  maxlength="6"
                  onkeyup="value=value.replace(/\D/g,'')"
                  onafterpaste="value=value.replace(/\D/g,'')"
                  @blur="form.testA = $event.target.value"
              /></van-col>
              <van-col :span="2"> 人</van-col>
            </van-row>
          </div>
        </template>
      </van-field>
      <van-field
        required
        v-model="form.tiyanguan"
        type="digit"
        label="大人体验官"
      />
      <van-field required name="radio" label="到店消费金额">
        <template #input>
          <div>
            <van-row>
              <el-col :span="24">
                <van-radio-group v-model="form.jineType" direction="horizontal">
                  <van-radio name="1">现金</van-radio>
                  <van-radio name="2" @click="initQuan"
                    >无门槛的消费券</van-radio
                  >
                </van-radio-group>
              </el-col>
            </van-row>
            <div v-if="form.jineType == '2'">
              <van-row style="display: flex; margin-top: 10px">
                <van-col :span="8" style="display: flex; width: 6em"
                  ><van-radio-group v-model="form.quan1" direction="horizontal">
                    <van-radio @click="quan1Change" name="1">代金券</van-radio>
                  </van-radio-group></van-col
                >
                <van-col :span="16">
                  <input
                    id="daijinquan"
                    class="myInput"
                    style="width: 59%; margin-top: 0px"
                    type="text"
                    placeholder=""
                    maxlength="6"
                    onkeyup="value=value.replace(/\D/g,'')"
                    onafterpaste="value=value.replace(/\D/g,'')"
                    @blur="form.testA = $event.target.value"
                  /><span style="margin-left: 0.2em">份</span></van-col
                >
              </van-row>
              <van-row style="display: flex; margin-top: 10px">
                <van-col :span="8" style="display: flex; width: 6em"
                  ><van-radio-group v-model="form.quan2" direction="horizontal">
                    <van-radio @click="quan2Change" name="1">产品券</van-radio>
                  </van-radio-group></van-col
                >
                <van-col :span="16">
                  <input
                    id="chanpinquan"
                    class="myInput"
                    style="width: 59%; margin-top: 0px"
                    type="text"
                    placeholder=""
                    maxlength="6"
                    onkeyup="value=value.replace(/\D/g,'')"
                    onafterpaste="value=value.replace(/\D/g,'')"
                    @blur="form.testA = $event.target.value"
                  /><span style="margin-left: 0.2em">份</span></van-col
                >
              </van-row>
              <van-row>
                <van-col
                  >代金券、产品券设置建议不能低于该店铺的人均消费。</van-col
                >
              </van-row>
            </div>
          </div>
        </template>
      </van-field>

      <div v-if="form.jineType == '1'">
        <van-field
          required
          v-model="form.darencanbiao"
          label="达人："
          type="digit"
          placeholder="请输入达人到店消费金额"
        />
        <van-field
          required
          v-model="form.renqicanbiao"
          label="大人体验官："
          type="digit"
          placeholder="请输入大人体验官到店消费金额"
        />
      </div>

      <van-field
        label="估价结果（含税）"
        placeholder=""
        :rules="[{ required: true, message: '' }]"
      >
        <template #input>
          <span v-if="money == 0" style="color: red"
            >您未开始估价，请填写您的需求，快速获取估价结果。</span
          >
          <span v-else style="color: red"
            >您的推广引流估价总计为：￥{{
              money
            }}元，具体以实际付款费用为准。</span
          >
        </template></van-field
      >
      <div style="margin: 23px; font-weight: bold">
        <van-button
          round
          block
          type="danger"
          native-type="submit"
          @click="gujia"
          >开始估价</van-button
        >
        <van-button
          v-if="money > 0"
          style="margin-top: 15px; font-weight: bold"
          round
          block
          type="danger"
          native-type="submit"
          @click="next"
          >下一步</van-button
        >
      </div>
    </van-form>
    <van-popup
      v-model="showTwo"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <customizedTwo
        :title="title"
        :parentData="form"
        @custom-event="onClickSubmit"
        @custom-event-left="onClicHideTwo"
      ></customizedTwo>
    </van-popup>
  </div>
</template>
<script>
import $ from "jquery";
import wx from "weixin-js-sdk";
import trends from "@/components/home/trends.vue";
import customizedTwo from "@/views/appAttractUsers/customized_two.vue";
export default {
  components: {
    trends,
    customizedTwo,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      jsApiList: [
        "checkJsApi", //检测接口权限
        "updateTimelineShareData",
        "updateAppMessageShareData",
        "onMenuShareAppMessage", //老版本分享接口(注意这两个旧版本的必须要放的不然会有问题)
        "onMenuShareTimeline", //老版本分享接口。
      ],
      shuifei: 0.06,
      startDefaultTime: {
        min: () => {
          const starttime = new Date().getTime() - 0 * (1000 * 60 * 60 * 24);
          let date = new Date(starttime + 0 * (1000 * 60 * 60 * 24));
          let dateWithoutTime = new Date(date.setHours(0, 0, 0, 0));
          return dateWithoutTime;
        },
        max: () => {
          const starttime = new Date().getTime() - 0 * (1000 * 60 * 60 * 24);
          let date = new Date(starttime + 180 * (1000 * 60 * 60 * 24));
          let dateWithoutTime = new Date(date.setHours(0, 0, 0, 0));
          return dateWithoutTime;
        },
      },
      endDefaultTime: {
        min: () => {
          const starttime = new Date().getTime() - 0 * (1000 * 60 * 60 * 24);
          let date = new Date(starttime + 0 * (1000 * 60 * 60 * 24));
          let dateWithoutTime = new Date(date.setHours(23, 59, 59, 0));
          return dateWithoutTime;
        },
        max: () => {
          const starttime = new Date().getTime() - 0 * (1000 * 60 * 60 * 24);
          let date = new Date(starttime + 180 * (1000 * 60 * 60 * 24));
          let dateWithoutTime = new Date(date.setHours(23, 59, 59, 0));
          return dateWithoutTime;
        },
      },
      dyDisabled: false,
      xhsDisabled: false,
      minOnew: "1-5w",
      minFivew: "5-10w",
      minTenw: "10w+",
      min3k: "3000+",
      minThreew: "3w+",
      fuwufei: 10000,
      dyGujiaMap: {},
      xhsGujiaMap: {},
      money: 0,
      startTimeShow: false,
      endTimeShow: false,
      show: true,
      showTwo: false,
      huodong: false,
      appShow: true,
      form: {
        darenfeiyong: 0,
        darentiyanguanfeiyong: 0,
        douyin: false,
        xiaohongshu: false,
        money: 0,
        tuiguangzhouqi: [],
        pingtaiType: "1",
        pingtaiTypes: [],
        tiyanguan: "100",
        jineType: "1",
        darencanbiao: "",
        renqicanbiao: "",
        quan1: "1",
        quan2: "",
        daijinquan: "",
        chanpinquan: "",
        tgItmes: [],
        xhsFensiliangArr: [
          { name: "3000+", count: 0, checked: false },
          { name: "3w+", count: 0, checked: false },
        ],
        dyFensiliangArr: [
          { name: "1-5w", count: 0, checked: false },
          { name: "5-10w", count: 0, checked: false },
          { name: "10w+", count: 0, checked: false },
        ],
      },
    };
  },
  watch: {},
  computed: {
    baseUrl() {
      if (location.href.indexOf("www.darenlaiye.com") > 0) {
        return "https://e.darenlaiye.com/application/api";
      }
      return "https://e_test.darenlaiye.com/application/api";
    },
    selectedLabels() {
      return this.options
        .filter((option) => this.selectedValues.includes(option.value))
        .map((option) => option.label);
    },
  },
  methods: {
    form3dyclear(v, index) {
      let value = document.getElementById("dyRenInput-" + (index + 1));
      if (v) {
        if (!value) {
          value.value = null;
        }
      } else {
        value.value = null;
      }
    },
    form3xhsclear(v, index) {
      let value = document.getElementById("xhsRenInput-" + (index + 1));
      if (v) {
        if (!value) {
          value.value = null;
        }
      } else {
        value.value = null;
      }
    },
    form3dycheckboxChange(value) {
      if (value) {
        this.dyDisabled = false;
        this.form.dyFensiliangArr.forEach((e) => {
          e.checked = true;
        });
      } else {
        this.dyDisabled = true;
        for (let i = 0; i < this.form.dyFensiliangArr.length; i++) {
          let e = this.form.dyFensiliangArr[i];
          e.checked = false;
          let v = document.getElementById("dyRenInput-" + (i + 1));
          v.value = null;
        }
      }
    },
    form3xhscheckboxChange(value) {
      if (value) {
        this.xhsDisabled = false;
        this.form.xhsFensiliangArr.forEach((e) => {
          e.checked = true;
        });
      } else {
        this.xhsDisabled = true;
        for (let i = 0; i < this.form.xhsFensiliangArr.length; i++) {
          let e = this.form.xhsFensiliangArr[i];
          e.checked = false;
          let v = document.getElementById("xhsRenInput-" + (i + 1));
          v.value = null;
        }
      }
    },
    gujia() {
      var money = 0;
      let baifenbai = 100;
      let shuifeibfb = this.shuifei * baifenbai;

      let countsum = 0;

      var dy = this.form.dyFensiliangArr;
      if (dy) {
        for (let i = 0; i < dy.length; i++) {
          let element = dy[i];
          let count = document.getElementById("dyRenInput-" + (i + 1)).value;
          if (element.checked && parseInt(count) > 0) {
            countsum += parseInt(count);
            let name = element.name;
            var danjia = this.dyGujiaMap[name];
            for (let i = 0; i < parseInt(count); i++) {
              money +=
                danjia * baifenbai + (danjia * baifenbai * shuifeibfb) / 100;
            }
          }
        }
      }
      var xhs = this.form.xhsFensiliangArr;
      if (xhs) {
        for (let i = 0; i < xhs.length; i++) {
          let element = xhs[i];
          let count = document.getElementById("xhsRenInput-" + (i + 1)).value;
          if (element.checked && parseInt(count) > 0) {
            countsum += parseInt(count);
            let name = element.name;
            var danjia = this.xhsGujiaMap[name];
            for (let i = 0; i < parseInt(count); i++) {
              money +=
                danjia * baifenbai + (danjia * baifenbai * shuifeibfb) / 100;
            }
          }
        }
      }
      this.form.darenfeiyong = money;

      let darenfeiyongsuan = 0;
      if (this.form.tiyanguan) {
        for (let i = 0; i < parseInt(this.form.tiyanguan); i++) {
          money += 100 * baifenbai + (100 * baifenbai * shuifeibfb) / 100;
          money +=
            this.form.renqicanbiao * baifenbai +
            (this.form.renqicanbiao * baifenbai * shuifeibfb) / 100;
          darenfeiyongsuan +=
            100 * baifenbai + (100 * baifenbai * shuifeibfb) / 100;
        }
      }
      this.form.darentiyanguanfeiyong = darenfeiyongsuan;
      if (this.form.jineType == "1") {
        for (let i = 0; i < countsum; i++) {
          money +=
            this.form.darencanbiao * baifenbai +
            (this.form.darencanbiao * baifenbai * shuifeibfb) / 100;
        }
      }

      money += (this.fuwufei + this.fuwufei * this.shuifei) * 100;
      money = money / 100;
      if (money <= 50000) {
        this.$toast(
          "您当前估价金额为" +
            money +
            "元，不满足定制版最低消费要求50000元，请调整后重新输入。"
        );
        return;
      }
      if (money) {
        this.money = money;
        this.form.money = money;
      }
    },
    initQuan() {
      const chanpinquan = document.getElementById("chanpinquan");
      chanpinquan.disabled = true;

      this.form.darencanbiao = "";
      this.form.renqicanbiao = "";

      this.form.quan1 = "1";
      this.form.quan2 = "";
    },
    quan1Change() {
      this.form.quan1 = "1";
      this.form.quan2 = "";
      const chanpinquan = document.getElementById("chanpinquan");
      chanpinquan.value = "";
      chanpinquan.disabled = true;

      const daijinquan = document.getElementById("daijinquan");
      daijinquan.disabled = false;
    },
    quan2Change() {
      this.form.quan1 = "";
      this.form.quan2 = "1";
      const daijinquan = document.getElementById("daijinquan");
      daijinquan.value = "";
      daijinquan.disabled = true;

      const chanpinquan = document.getElementById("chanpinquan");
      chanpinquan.disabled = false;
    },
    next() {
      if (!this.form.startTime) {
        this.$toast("请选择推广开始时间");
        return;
      }
      if (!this.form.endTime) {
        this.$toast("请选择推广结束时间");
        return;
      }
      if (this.form.startTime > this.form.endTime) {
        this.$message.error("推广周期开始时间不能大于结束时间");
        return false;
      }
      const date1 = new Date(this.form.startTime);
      const date2 = new Date(this.form.endTime);
      const oneDay = 24 * 60 * 60 * 1000; // 每天的毫秒数
      const daysDifference = Math.abs((date2 - date1) / oneDay);
      if (daysDifference < 29) {
        this.$toast("推广周期至少间隔30天");
        return false;
      }
      if (!this.form.douyin && !this.form.xiaohongshu) {
        this.$toast("请至少选择1项推广平台");
        return false;
      } else {
        if (this.form.douyin) {
          if (this.form.dyFensiliangArr) {
            let bool = false;
            let check = 0;
            let message = "";
            for (let i = 0; i < this.form.dyFensiliangArr.length; i++) {
              let e = this.form.dyFensiliangArr[i];
              if (!bool) {
                if (e.checked) {
                  let count = document.getElementById(
                    "dyRenInput-" + (i + 1)
                  ).value;
                  if (count == null || count == undefined) {
                    bool = true;
                    message = "抖音" + e.name + "：请填写粉丝量";
                  } else {
                    if (Number(count) == 0) {
                      bool = true;
                      message = "抖音" + e.name + "：粉丝量至少填写1人";
                    } else {
                      e.count = count;
                    }
                  }
                } else {
                  check += 1;
                }
              }
            }
            if (bool) {
              this.$toast(message);
              return false;
            }
            if (check == 3) {
              this.$toast("抖音粉丝量最少选择1项");
              return false;
            }
          }
        }
        if (this.form.xiaohongshu) {
          if (this.form.xhsFensiliangArr) {
            let bool = false;
            let check = 0;
            let message = "";
            for (let i = 0; i < this.form.xhsFensiliangArr.length; i++) {
              let e = this.form.xhsFensiliangArr[i];
              if (!bool) {
                if (e.checked) {
                  let count = document.getElementById(
                    "xhsRenInput-" + (i + 1)
                  ).value;
                  if (count == null || count == undefined) {
                    bool = true;
                    message = "小红书" + e.name + "：请填写粉丝量";
                  } else {
                    if (Number(count) == 0) {
                      bool = true;
                      message = "小红书" + e.name + "：粉丝量至少填写1人";
                    } else {
                      e.count = count;
                    }
                  }
                } else {
                  check += 1;
                }
              }
            }
            if (bool) {
              this.$toast(message);
              return false;
            }
            if (check == 2) {
              this.$toast("小红书粉丝量最少选择1项");
              return false;
            }
          }
        }
      }
      if (!this.form.tiyanguan) {
        this.$toast("输入大人体验官人数");
        return false;
      } else if (Number(this.form.tiyanguan) < 100) {
        this.$toast("最少填写100位大人体验官");
        return false;
      } else if (Number(this.form.tiyanguan) > 1000) {
        this.$toast("最高填写1000位大人体验官");
        return false;
      }
      if (this.form.jineType == "1") {
        if (!this.form.darencanbiao) {
          this.$toast("请输入达人到店消费金额");
          return false;
        } else if (Number(this.form.darencanbiao) < 25) {
          this.$toast("达人到店消费金额不能低于25元");
          return false;
        }
        if (!this.form.renqicanbiao) {
          this.$toast("请输入大人体验官消费金额");
          return false;
        } else if (Number(this.form.renqicanbiao) < 25) {
          this.$toast("大人体验官消费金额不能低于25元");
          return false;
        }
      } else {
        if (this.form.quan1 == "1") {
          const daijinquan = document.getElementById("daijinquan");
          this.form.daijinquan = daijinquan.value;
          if (!this.form.daijinquan) {
            this.$toast("请输入代金券份数");
            return false;
          } else if (Number(this.form.daijinquan) == 0) {
            this.$toast("请至少输入1份代金券");
            return false;
          }
        } else {
          const chanpinquan = document.getElementById("chanpinquan");
          this.form.chanpinquan = chanpinquan.value;
          if (!this.form.chanpinquan) {
            this.$toast("请输入产品券份数");
            return false;
          } else if (Number(this.form.chanpinquan) == 0) {
            this.$toast("请至少输入1份产品券");
            return false;
          }
        }
      }
      this.showTwo = true;
    },
    onClicHideTwo() {
      this.showTwo = false;
    },
    onClickSubmit() {
      this.showTwo = false;
      this.$emit("custom-event");
    },
    onClickLeft() {
      this.$emit("custom-event");
    },
    clearChanpinVal() {
      const chanpinquan = document.getElementById("chanpinquan");
      chanpinquan.value = "";
      this.form.chanpinquan = "";
    },
    clearDaijinVal() {
      const daijinquan = document.getElementById("daijinquan");
      daijinquan.value = "";
      this.form.daijinquan = "";
    },
    requestApi(actions, params) {
      params.platform = "guanwang";
      return new Promise((resolve, reject) => {
        fetch(actions, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ params: params }),
        })
          .then((response) => response.json()) // 如果服务器返回JSON，则解析它
          .then((resp) => {
            if (resp.code == 0) {
              resolve(resp);
            } else {
              reject(resp);
            }
          }) // 处理服务器返回的数据
          .catch((error) => {
            reject(error);
          }); // 处理错误
      });
    },
    fenxiang() {
      $.ajax({
        type: "POST",
        async: true,
        contentType: "application/json",
        url: "https://yygj.darenlaiye.com/mer99/api/YeWu/getACWithUrl",
        dataType: "json",
        data: JSON.stringify({
          url: "",
        }),
        success: (res) => {
          if (res.code == 0) {
            wx.config({
              debug: false,
              appId: "wx90ef5660420fdeff",
              timestamp: res.data.ts,
              nonceStr: res.data.noncestr,
              signature: res.data.sign,
              jsApiList: this.jsApiList,
            });
            wx.ready(() => {
              alert("11");
              let tempParam = {
                title: "",
                desc: "",
                link: "",
                imgUrl: "",
                success: (res) => {
                  alert("huidiao");
                },
                cancel: (res) => {
                  alert("huidiaoerror");
                },
              };
              console.log(wx);
              tempParam.title = "大人来也实证调研平台";
              tempParam.desc = "灵活、透明、高效的现场调研";
              tempParam.link = res.data.url;
              console.log(tempParam.link);
              tempParam.imgUrl =
                "https://m99.darenlaiye.com/mer99/img/logo.bde56f2c.png";
              if (wx.updateAppMessageShareData) {
                wx.updateAppMessageShareData(tempParam);
              } else {
                wx.onMenuShareAppMessage(tempParam);
              }
              wx.onMenuShareTimeline(tempParam);
            });
            wx.error(function (res) {
              console.log("error");
              console.log(res.errMsg);
            });
            // 判断客户端是否支持指定js的接口
            wx.checkJsApi({
              jsApiList: this.jsApiList,
              success: function (res) {
                console.log("判断客户端支持js接口", res);
              },
            });
          } else {
            this.$notify({ type: "danger", message: res.msg });
          }
        },
        error: () => {
          this.$notify({ type: "danger", message: "系统异常" });
        },
      });
    },
  },
  created() {},
  mounted() {
    this.dyGujiaMap[this.minOnew] = 600;
    this.dyGujiaMap[this.minFivew] = 1300;
    this.dyGujiaMap[this.minTenw] = 1600;
    this.xhsGujiaMap[this.min3k] = 500;
    this.xhsGujiaMap[this.minThreew] = 1300;
    this.dyDisabled = true;
    this.xhsDisabled = true;
  },
  beforeDestroy() {},
};
</script>
<style lang='less' scoped>
/deep/ .van-cell__value {
  border: none;
}
/deep/ .van-grid-item__content {
  padding: 0px 0px;
}
/deep/ .van-grid-item__content--center {
  align-items: flex-start;
}
/deep/ .myInput {
  border: 1px solid #c4c9cf;
  margin-top: 8px;
  height: 29px;
}
/deep/ .van-checkbox__icon--disabled .van-icon {
  background-color: #dddddd; /* 禁用状态下的背景颜色 */
}
input:disabled {
  background-color: #c8c9cc; /* 禁用状态下的背景颜色 */
}
</style>