<template>
  <div class="wechat_paper">
    <div class="body">
      <div v-if="src">
        <el-image :src="src" style="width: 100%" fit="cover"></el-image>
      </div>
      <el-container class="rongqiBg" id="page4_3">
        <el-main>
          <el-row v-if="paperObj != null">
            <el-row
              v-for="(qo, i) in paperObj.paperJG[0].pQuestion"
              class="qContainer"
              :key="'ques' + i"
            >
              <el-col :span="24" v-bind:key="i" :data="qo">
                <div v-show="qo.isskip == 0">
                  <el-row
                    v-if="qo.ques.showTitle == 1"
                    class="qTitle"
                    :id="getId(qo.ques.ID)"
                    >{{ qo.ques.qName
                    }}<span style="color: red">{{
                      qo.ques.isPass
                    }}</span></el-row
                  >
                  <template v-for="(selO, j) in qo.bodys">
                    <el-row
                      v-if="qo.ques.type == 'checkbox'"
                      :key="'body' + i + '-' + j"
                    >
                      <el-row v-bind:key="j" :data="selO" class="qOption">
                        <el-checkbox
                          v-model="selO.ischecked"
                          @change="ckClick(selO.ID, qo.ques.ID)"
                          :label="selO.ID"
                          >{{ selO.bName }}</el-checkbox
                        >
                      </el-row>
                    </el-row>
                  </template>
                  <el-row v-if="qo.ques.type == 'radio'">
                    <el-row
                      v-for="(selO, k) in qo.bodys"
                      class="qOption"
                      :key="'opt' + i + '-' + '-' + k"
                    >
                      <el-radio
                        :key="k"
                        v-model="qo.answer"
                        :label="selO.ID"
                        @change="radioChange"
                        >{{ selO.bName }}</el-radio
                      >
                    </el-row>
                  </el-row>
                  <el-row v-if="qo.ques.type == 'textarea'" class="qTextarea">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入内容"
                      v-model="qo.answer"
                    >
                    </el-input>
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <el-row class="pageFoot">
              <el-button type="danger" @click="subButton()" round
                >提交</el-button
              >
            </el-row>
          </el-row>
          <el-row v-else>
            <p v-if="this.isdone == 0" class="txtp" style="font-size: 2em">
              正在加载...
            </p>
            <p v-else class="txtp" style="font-size: 2em">
              提交成功，我们会尽快联系您。
            </p>
          </el-row>
        </el-main>
      </el-container>

      <!-- <iframe
        :src="src"
        width="100%"
        ref="iframe"
      ></iframe> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  props: {},
  data() {
    return {
      src: null,
      taskid: null,
      paperObj: null,
      apiPath: "https://yygj.darenlaiye.com/drzf/wj.aspx",
      ucaid: 0,
      isdone: 0,
      bodyBook: new Array(),
    };
  },
  watch: {},
  computed: {},
  methods: {
    initValue() {
      if (localStorage.getItem("ucaid") != null) {
        this.ucaid = localStorage.getItem("ucaid");
      } else {
        this.ucaid = 0;
      }
      //console.log(Vue.axios);
      axios
        .get(this.apiPath, {
          params: {
            task: "createPaper",
            taskid: this.taskid,
            ucaid: this.ucaid,
          },
        })
        .then((res) => {
          console.log(res.data);
          var tempo = res.data;
          if (tempo.msg == "old") {
            this.ucaid = tempo.data;
          } else {
            localStorage.setItem("ucaid", tempo.data);
            this.ucaid = tempo.data;
          }
          axios
            .get(this.apiPath, {
              params: {
                task: "selPaper",
                ucaid: tempo.data,
              },
            })
            .then((res) => {
              console.log(res.data);
              //this.paperObj = res.data.data;
              this.zhengheData(res.data.data);
              //this.paperObj = res.data.data;
              //console.log(this.paperObj.paperJG[0].pQuestion);
            });
        });
      // this.axios.get(this.apiPath).then((response)=>{
      //     console.log(response.data);
      // });
    },
    zhengheData(t_paperObj) {
      //console.log(t_paperObj);
      if (t_paperObj != null) {
        this.bodyBook = new Array();
        for (var i = 0; i < t_paperObj.paperJG[0].pQuestion.length; i++) {
          t_paperObj.paperJG[0].pQuestion[i].ques.isPass = "";
          t_paperObj.paperJG[0].pQuestion[i].isskip = 0;
          if (t_paperObj.paperJG[0].pQuestion[i].answer.toString() == "") {
            t_paperObj.paperJG[0].pQuestion[i].answer = "";
          }
          if (
            t_paperObj.paperJG[0].pQuestion[i].ques.type == "checkbox" ||
            t_paperObj.paperJG[0].pQuestion[i].ques.type == "radio"
          ) {
            var tempAns = t_paperObj.paperJG[0].pQuestion[i].answer
              .toString()
              .split(",");
            for (
              var j = 0;
              j < t_paperObj.paperJG[0].pQuestion[i].bodys.length;
              j++
            ) {
              if (
                t_paperObj.paperJG[0].pQuestion[i].bodys[j].ischecked == null
              ) {
                t_paperObj.paperJG[0].pQuestion[i].bodys[j].ischecked = false;
              }
              if (t_paperObj.paperJG[0].pQuestion[i].answer == "") {
                var oo = new Object();
                oo.bid = t_paperObj.paperJG[0].pQuestion[i].bodys[j].ID;
                oo.qid = t_paperObj.paperJG[0].pQuestion[i].ques.ID;
                oo.ischecked = -1;
                this.bodyBook.push(oo);
              } else {
                if (
                  this.findStr(
                    t_paperObj.paperJG[0].pQuestion[i].bodys[j].ID.toString(),
                    tempAns
                  )
                ) {
                  t_paperObj.paperJG[0].pQuestion[i].bodys[j].ischecked = true;
                  var oo = new Object();
                  oo.bid = t_paperObj.paperJG[0].pQuestion[i].bodys[j].ID;
                  oo.qid = t_paperObj.paperJG[0].pQuestion[i].ques.ID;
                  oo.ischecked = 1;
                  this.bodyBook.push(oo);
                } else {
                  t_paperObj.paperJG[0].pQuestion[i].bodys[j].ischecked = false;
                  var oo = new Object();
                  oo.bid = t_paperObj.paperJG[0].pQuestion[i].bodys[j].ID;
                  oo.qid = t_paperObj.paperJG[0].pQuestion[i].ques.ID;
                  oo.ischecked = 0;
                  this.bodyBook.push(oo);
                }
              }
            }
          }
        }
        //查是不是skip
        if (t_paperObj.pswitch != null && t_paperObj.pswitch.length > 0) {
          for (var a = 0; a < t_paperObj.pswitch.length; a++) {
            var ids = t_paperObj.pswitch[a].optionIdStr.split(",");
            var famStr = t_paperObj.pswitch[a].calFormula;
            if (ids != null && ids.length > 0) {
              for (var b = 0; b < ids.length; b++) {
                var tempoo = this.findOO(ids[b]);
                if (tempoo != null) {
                  var tihuanStr = "";
                  if (tempoo.ischecked == -1) {
                    tihuanStr = "false";
                  } else if (tempoo.ischecked == 1) {
                    tihuanStr = "true";
                  } else if (tempoo.ischecked == 0) {
                    tihuanStr = "false";
                  } else {
                    tihuanStr = "false";
                  }
                  //ab"{12345}".replace(eval('/\{(12345)\}/'),"false")
                  //var reg = new RegExp("/{"+ids[b]+"}/");
                  famStr = famStr.replace(
                    eval("/{(" + ids[b] + ")}/"),
                    tihuanStr
                  );
                }
              }
            }
            var jieguo = true;
            if (famStr == "") {
              jieguo = true;
            } else {
              jieguo = eval(famStr);
            }
            var t_qid = t_paperObj.pswitch[a].qId;
            for (var c = 0; c < t_paperObj.paperJG[0].pQuestion.length; c++) {
              if (t_qid == t_paperObj.paperJG[0].pQuestion[c].ques.ID) {
                if (jieguo == true) {
                  if (t_paperObj.pswitch[a].formulaType == "show") {
                    t_paperObj.paperJG[0].pQuestion[c].isskip = 0;
                  } else {
                    t_paperObj.paperJG[0].pQuestion[c].isskip = 1;
                  }
                } else {
                  if (t_paperObj.pswitch[a].formulaType == "show") {
                    t_paperObj.paperJG[0].pQuestion[c].isskip = 1;
                  } else {
                    t_pt_paperObj.paperJG[0].pQuestion[c].isskip = 0;
                  }
                }
              }
            }
          }
        }
        this.paperObj = t_paperObj;
      }
    },
    findOO(bid) {
      if (this.bodyBook != null && this.bodyBook.length > 0) {
        for (var i = 0; i < this.bodyBook.length; i++) {
          var tempoo = this.bodyBook[i];
          if (tempoo.bid == bid) {
            return tempoo;
          }
        }
      }
      return null;
    },
    findStr(str, aO) {
      if (aO != null && aO.length > 0) {
        for (var i = 0; i < aO.length; i++) {
          if (aO[i] == str) {
            return true;
          }
        }
      }
      return false;
    },
    radioChange(value) {
      console.log(value);
      //this.refreshBook(value);
      this.zhengheData(this.paperObj);
    },
    ckClick(bid, qid) {
      //console.log(value);
      //this.refreshBook(value);
      for (var i = 0; i < this.paperObj.paperJG[0].pQuestion.length; i++) {
        if (this.paperObj.paperJG[0].pQuestion[i].ques.ID == qid) {
          var ansStr = new Array();
          for (
            var j = 0;
            j < this.paperObj.paperJG[0].pQuestion[i].bodys.length;
            j++
          ) {
            if (
              this.paperObj.paperJG[0].pQuestion[i].bodys[j].ischecked == true
            ) {
              ansStr.push(this.paperObj.paperJG[0].pQuestion[i].bodys[j].ID);
            }
          }
          this.paperObj.paperJG[0].pQuestion[i].answer = ansStr.join(",");
          break;
        }
      }
      this.zhengheData(this.paperObj);
    },
    subButton() {
      var ansArray = new Array();
      if (this.paperObj != null) {
        for (var i = 0; i < this.paperObj.paperJG[0].pQuestion.length; i++) {
          if (this.paperObj.paperJG[0].pQuestion[i].ques.type != "string") {
            var answerStr = this.paperObj.paperJG[0].pQuestion[i].answer + "";
            var isskip = this.paperObj.paperJG[0].pQuestion[i].isskip;
            var max = this.paperObj.paperJG[0].pQuestion[i].ques.max;
            var min = this.paperObj.paperJG[0].pQuestion[i].ques.min;
            //checkbox radio textarea 个数
            // objct ucaid qId isskip answer

            //题目未跳过验证是否合格
            if (isskip != 1) {
              //获取答案长度
              var answerLength = -1;
              if (
                this.paperObj.paperJG[0].pQuestion[i].ques.type == "textarea"
              ) {
                answerLength = answerStr.length;
              } else {
                if (!answerStr) {
                  //如果未选择不需要分割直接赋值为0
                  answerLength = 0;
                } else {
                  answerLength = answerStr.split().length;
                }
              }

              //查看是否满足
              if (answerLength > max || answerLength < min) {
                if (
                  this.paperObj.paperJG[0].pQuestion[i].ques.isPageTitle == 1
                ) {
                  this.paperObj.paperJG[0].pQuestion[i].ques.isPass =
                    "请将信息填写完整";
                  window.location.hash =
                    "#title_" + this.paperObj.paperJG[0].pQuestion[i].ques.ID;
                } else {
                  this.paperObj.paperJG[0].pQuestion[i - 1].ques.isPass =
                    "请将信息填写完整";
                  window.location.hash =
                    "#title_" +
                    this.paperObj.paperJG[i - 1].pQuestion[i].ques.ID;
                }
                return;
              }
            }
            //装载数据
            var ans = {
              ucaid: this.ucaid,
              qId: this.paperObj.paperJG[0].pQuestion[i].ques.ID,
              isskip: isskip,
              answer: answerStr,
            };
            ansArray.push(ans);
          }
        }
      }
      console.log(JSON.stringify(ansArray));
      //alert("提交");
      //提交完成后清空ucaid
      //提交参数 task=subPaper ucaid ansJson
      axios
        .get(this.apiPath, {
          params: {
            task: "subPaper",
            ansJson: JSON.stringify(ansArray),
            ucaid: this.ucaid,
          },
        })
        .then((res) => {
          this.paperObj = null;
          this.isdone = 1;
        });

      localStorage.removeItem("ucaid");
      //然后页面变成 问卷已提交，我们会尽快联系您
    },
    getId(id) {
      return "title_" + id;
    },
  },
  created() {},
  mounted() {
    this.taskid = this.$route.params.data.contentLink;
    this.src = this.$route.params.data.headImageMax;
    this.initValue();
  },
};
</script>
<style lang='less' scoped>
@media screen and (min-width: 1280px) {
  .wechat_paper {
    margin-top: 80px;
    .body {
      width: 100%;
      max-width: 1280px;
      margin: 0px auto;
      iframe {
        height: calc(100vh - 80px - 327px);
        margin-bottom: 10px;
        overflow: hidden;

        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 阴影效果 */
      }
      .qContainer {
        text-align: left;
      }

      .qTitle {
        padding: 0.5em;
      }

      table,
      table tr th,
      table tr td {
        border: 1px solid #aaaaaa;
      }

      .qOption {
        padding: 0.5em;
      }

      .qTextarea {
        padding-top: 0.5em;
      }

      .pageFoot {
        padding-top: 1em;
      }
    }
  }
}
@media screen and (max-width: 1279px) {
  .wechat_paper {
    margin-top: 60px;
    .body {
      width: 100%;
      max-width: 1280px;
      margin: 0px auto;
      iframe {
        height: calc(100vh - 80px - 327px);
        margin-bottom: 10px;
        overflow: hidden;

        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 阴影效果 */
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .wechat_paper {
    margin-top: 60px;
    .body {
      width: 100%;
      max-width: 1280px;
      margin: 0px auto;
      iframe {
        height: calc(100vh - 80px - 327px);
        margin-bottom: 10px;
        overflow: hidden;

        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 阴影效果 */
      }
    }
  }
}
</style>