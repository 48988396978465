<template>
  <div class="drInstitute" style="background-color: #f7f8f9">
    <div
      class="bH"
      :style="{
        backgroundImage: 'url(' + require('@/assets/home/bh_center.svg') + ')',
      }"
    >
      <div
        class="bh_left"
        :style="{
          backgroundImage: 'url(' + require('@/assets/home/bh_left.svg') + ')',
        }"
      />

      <div
        class="bh_right"
        :style="{
          backgroundImage: 'url(' + require('@/assets/home/bh_right.svg') + ')',
          height: '374px',
        }"
      ></div>

      <div>
        <div class="rightAligned">
          <div style="display: grid; place-items: center">
            <img class="image1" :src="require('@/assets/dingwei1.png')" />
          </div>
          <div>
            <el-image
              class="image2"
              :src="require('@/assets/dingwei2.jpg')"
              :preview-src-list="[require('@/assets/dingwei2.jpg')]"
              fit="contain"
            />
          </div>
          <div class="button1">扫描了解大人来也</div>
          <div class="button2" @click="liaojie" style="font-weight: bold">
            点击了解大人来也
          </div>
        </div>
      </div>
      <div class="body" ref="body">
        <div class="title">
          一键召集<span style="color: #ff7831">大人</span>与<span
            style="color: #ff7831"
            >达人</span
          >，快速实现业务增长
        </div>
        <div class="title1">
          一键召集<span style="color: #ff7831">大人</span>与<span
            style="color: #ff7831"
            >达人</span
          >
          <div style="margin-top: 2%">快速实现业务增长</div>
        </div>
        <div class="sub_container">
          <el-row>
            <el-col :span="4" v-for="(item, index) in titleContent" :key="index"
              ><span class="sub_container_item">{{ item }}</span></el-col
            >
          </el-row>
        </div>
        <div class="sub_container1">
          <el-row>
            <el-col
              :span="8"
              v-for="(item, index) in titleContent"
              :key="index"
              style="margin-top: 1em"
              ><span class="sub_container_item">{{ item }}</span></el-col
            >
          </el-row>
        </div>
      </div>
    </div>

    <div class="tg_body" ref="tgBody">
      <div class="trends_row">
        <div class="trends_card" v-for="(item, index) in tgItmes" :key="index">
          <div class="tg_item_body">
            <div v-if="index == 0">
              <img
                style="width: 40%; position: absolute; left: 0; top: 0"
                :src="require('@/assets/home/tiyan.png')"
              />
            </div>
            <div v-if="index == 1">
              <img
                style="width: 40%; position: absolute; left: 0; top: 0"
                :src="require('@/assets/home/biaozhun.png')"
              />
            </div>
            <div v-if="index == 2">
              <img
                style="width: 40%; position: absolute; left: 0; top: 0"
                :src="require('@/assets/home/dingzhi.png')"
              />
            </div>

            <div class="youhui">{{ index == 2 ? "最受欢迎" : "优惠体验" }}</div>
            <div class="tg_item_title">{{ item.title }}</div>
            <div class="tg_item_top">
              <div class="row">
                <span class="row_red">{{
                  item.frequency + (index == 2 ? "" : "元")
                }}</span
                >&nbsp;/次
              </div>
              <div class="row_remark">
                <div class="zhuti">{{ item.remarkLevelOne }}</div>
                <div class="citi">
                  <div v-if="item.remarkLevelTwo">
                    {{ item.remarkLevelTwo }}
                  </div>
                  <div v-else style="color: #ffffff">字</div>
                </div>
              </div>
              <div class="row_button">
                <div class="zixun" @click="consultClick(index)">立即咨询</div>
              </div>
            </div>
            <div class="tg_item_bottom">
              <div class="row_fw">
                <el-row>
                  <!-- <el-col :span="emptyCol" style="padding: 4px 2px">
                    <div
                      style="border-left: 3px solid black; height: 13px"
                    ></div>
                  </el-col> -->
                  <el-col :span="22">
                    <span style="display: inline-block; width: 0.8em"
                      ><div
                        style="
                          border-left: 3px solid black;
                          height: 13px;
                          margin-left: 0.2em;
                        "
                      ></div
                    ></span>
                    产品服务</el-col
                  >
                  <el-col :span="2">
                    <div></div>
                  </el-col>
                </el-row>
              </div>
              <div class="content_head">
                <div class="title">
                  <el-row>
                    <!-- <el-col :span="emptyCol">●</el-col> -->
                    <el-row class="biaoti">
                      <!-- <el-col :span="emptyCol" style="padding: 0px 2px">●</el-col> -->
                      <el-col :span="22">
                        <span class="yuan">●</span>
                        {{ index == 0 ? "达人推广2选1" : "达人推广" }}</el-col
                      >
                      <el-col :span="2">
                        <div
                          class="duihao"
                          :style="{
                            backgroundImage:
                              'url(' +
                              require('@/assets/home/tg_duihao.svg') +
                              ')',
                          }"
                        ></div>
                      </el-col>
                    </el-row>
                  </el-row>
                </div>
                <div class="content_xhs">
                  <el-row>
                    <!-- <el-col
                      :span="emptyCol"
                      style="border: 1px solid transparent"
                    ></el-col> -->
                    <el-col :span="22">
                      <span style="display: inline-block; width: 0.8em"></span>
                      小红书爆文达人：{{
                        Array.isArray(item.xhs) ? item.xhs.join(",") : item.xhs
                      }}</el-col
                    >
                  </el-row>
                </div>
                <div class="content_dy">
                  <el-row>
                    <!-- <el-col
                      :span="emptyCol"
                      style="border: 1px solid transparent"
                    ></el-col> -->
                    <el-col :span="22">
                      <span style="display: inline-block; width: 0.8em"></span>
                      抖音品宣达人：<span style="padding-left: 1em">{{
                        Array.isArray(item.dy) ? item.dy.join(",") : item.dy
                      }}</span></el-col
                    >
                  </el-row>
                </div>
              </div>
              <div class="content_bottom">
                <div class="title">
                  <el-row>
                    <!-- <el-col :span="emptyCol">●</el-col> -->
                    <el-row class="biaoti">
                      <!-- <el-col :span="emptyCol" style="padding: 0px 2px">●</el-col> -->
                      <el-col :span="22">
                        <span class="yuan">●</span>
                        召集实名顾客到店</el-col
                      >
                      <el-col :span="2">
                        <div
                          class="duihao"
                          :style="{
                            backgroundImage:
                              'url(' +
                              require('@/assets/home/tg_duihao.svg') +
                              ')',
                          }"
                        ></div>
                      </el-col>
                    </el-row>
                  </el-row>
                </div>
                <div class="content_drly">
                  <el-row>
                    <!-- <el-col
                      :span="emptyCol"
                      style="border: 1px solid transparent"
                    ></el-col> -->

                    <el-row v-if="index == 2">
                      <el-col :span="22">
                        <span
                          style="display: inline-block; width: 0.8em"
                        ></span>
                        大人来也体验官：{{ item.drly }}</el-col
                      >
                    </el-row>
                    <el-row v-else>
                      <el-col :span="22">
                        <span
                          style="display: inline-block; width: 0.8em"
                        ></span>
                        大人来也：{{ item.drly }}</el-col
                      >
                    </el-row>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="微信号示例图"
      :visible.sync="wxImageShow"
      width="235px"
      custom-class="dialog-with-underline"
      append-to-body
    >
      <img :src="require('@/assets/home/wxshili.png')" style="height: 385px" />
    </el-dialog>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisibleLevelOne"
      width="840px"
      custom-class="dialog-with-underline"
      append-to-body
      v-if="dialogVisibleLevelOne"
      @close="hideOver"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form" label-width="10em">
        <el-form-item label="" prop="tuiguangzhouqi">
          <el-date-picker
            style="width: 70%"
            v-model="form.tuiguangzhouqi"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            placeholder="请选择推广周期"
          >
          </el-date-picker>
          <template #label>
            <span style="color: red">*</span> <span>推广周期：</span>
          </template>
        </el-form-item>

        <el-form-item label="" prop="tuiguanghuodong">
          <el-select
            style="width: 70%"
            v-model="form.tuiguanghuodong"
            multiple
            placeholder="请选择推广活动"
          >
            <el-option
              v-for="item in selOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <template #label>
            <span style="color: red">*</span> <span>推广活动：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="companyName">
          <el-input
            style="width: 70%"
            v-model="form.companyName"
            placeholder="请输入企业名称"
          ></el-input>
          <template #label>
            <span style="color: red">*</span> <span>企业名称：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="shopAddress">
          <el-input
            style="width: 70%"
            v-model="form.shopAddress"
            type="textarea"
            placeholder="请输入门店地址"
          ></el-input>
          <template #label>
            <span style="color: red">*</span> <span>门店地址：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="comment">
          <el-input
            style="width: 70%"
            v-model="form.comment"
            type="textarea"
            placeholder="请输入门店业务简介，如：甜品店"
          ></el-input>

          <template #label>
            <span style="color: red">*</span> <span>业务简介：</span>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          style="
            color: #ffffff;
            font-weight: bold;
            background: rgb(241, 82, 54);
          "
          @click="maxTwoNext"
          >下一步</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisibleLevelTwo"
      width="840px"
      custom-class="dialog-with-underline"
      append-to-body
      placement="top"
      v-if="dialogVisibleLevelTwo"
      @close="hideOver"
      :close-on-click-modal="false"
    >
      <el-form :model="form2" :rules="rules2" ref="form2" label-width="11em">
        <el-form-item label="">
          <div v-if="index == 0">
            <el-radio di v-model="form2.pingtaiType" label="1">抖音</el-radio>
            <el-radio v-model="form2.pingtaiType" label="2">小红书</el-radio>
          </div>
          <div v-else>
            <el-checkbox-group v-model="form2.pingtaiTypes">
              <el-checkbox label="1" @change="form2dycheckboxChange"
                >抖音</el-checkbox
              >
              <el-checkbox label="2" @change="form2xhscheckboxChange"
                >小红书</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <template #label>
            <span style="color: red">*</span> <span>推广平台：</span>
          </template>
        </el-form-item>
        <div v-if="index == 0">
          <el-form-item label="">
            <el-row>
              <el-col
                :span="7"
                v-for="(item, index) in form2.pingtaiType == '1'
                  ? form2.tgItmes.dy
                  : form2.tgItmes.xhs"
                :key="index"
              >
                <el-row>
                  <el-col :span="9">
                    <el-checkbox
                      disabled
                      v-model="item.checked"
                      :key="index"
                      :label="item.name"
                    ></el-checkbox>
                  </el-col>
                  <el-col :span="11">
                    <el-input-number
                      disabled
                      v-model="item.count"
                      :controls="false"
                      style="width: 95%"
                    ></el-input-number>
                  </el-col>
                  <el-col :span="2"> 人 </el-col>
                </el-row>
              </el-col>
            </el-row>
            <template #label>
              <span style="color: red">*</span> <span>粉丝量：</span>
            </template>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="">
            <el-row>
              <el-col
                :span="7"
                v-for="(item, index) in form2.tgItmes.dy"
                :key="index"
              >
                <el-row>
                  <el-col :span="9">
                    <el-checkbox
                      disabled
                      v-model="item.checked"
                      :key="index"
                      :label="item.name"
                    ></el-checkbox>
                  </el-col>
                  <el-col :span="11">
                    <el-input-number
                      disabled
                      v-model="item.count"
                      :controls="false"
                      style="width: 95%"
                    ></el-input-number>
                  </el-col>
                  <el-col :span="2"> 人 </el-col>
                </el-row>
              </el-col>
            </el-row>
            <template #label>
              <span style="color: red">*</span> <span>抖音粉丝量：</span>
            </template>
          </el-form-item>
          <el-form-item label="">
            <el-row>
              <el-col
                :span="7"
                v-for="(item, index) in form2.tgItmes.xhs"
                :key="index"
              >
                <el-row>
                  <el-col :span="9">
                    <el-checkbox
                      disabled
                      v-model="item.checked"
                      :key="index"
                      :label="item.name"
                    ></el-checkbox>
                  </el-col>
                  <el-col :span="11">
                    <el-input-number
                      disabled
                      v-model="item.count"
                      :controls="false"
                      style="width: 95%"
                    ></el-input-number>
                  </el-col>

                  <el-col :span="2"> 人 </el-col>
                </el-row>
              </el-col>
            </el-row>
            <template #label>
              <span style="color: red">*</span> <span>小红书粉丝量：</span>
            </template>
          </el-form-item>
        </div>
        <el-form-item label="" prop="tiyanguan">
          <el-row>
            <el-col :span="7">
              <el-input style="width: 90%" v-model="form2.tiyanguan" disabled
                ><template slot="append"></template
              ></el-input>
            </el-col>
            <el-col :span="16"> 人（人气顾客到店消费人数） </el-col>
          </el-row>
          <template #label>
            <span style="color: red">*</span> <span>大人体验官：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="jine">
          <el-radio
            v-model="form2.jineType"
            label="1"
            @change="form2JineTypeChange"
            >现金</el-radio
          >
          <el-radio
            v-model="form2.jineType"
            label="2"
            @change="form2JineTypeChange"
            >无门槛的消费券</el-radio
          >
          <div v-if="form2.jineType == '2'">
            <el-row style="padding: 13px 0px">
              <el-col :span="4">
                <el-radio
                  v-model="form2.quan"
                  label="1"
                  @change="form2quanChange"
                  >代金券</el-radio
                ></el-col
              >
              <el-col :span="10">
                <el-input
                  :disabled="form2.quan == '2'"
                  v-model="form2.daijinquan"
                  maxlength="6"
                  @input="(v) => (form2.daijinquan = v.replace(/[^\d]/g, ''))"
                ></el-input>
              </el-col>
              <el-col :span="2" style="text-align: center">份</el-col>
            </el-row>
            <el-row style="padding: 13px 0px">
              <el-col :span="4">
                <el-radio
                  v-model="form2.quan"
                  label="2"
                  @change="form2quanChange"
                  >产品券</el-radio
                ></el-col
              >
              <el-col :span="10"
                ><el-input
                  :disabled="form2.quan == '1'"
                  v-model="form2.chanpinquan"
                  maxlength="6"
                  @input="(v) => (form2.chanpinquan = v.replace(/[^\d]/g, ''))"
                ></el-input
              ></el-col>
              <el-col :span="2" style="text-align: center">份</el-col>
            </el-row>
            <el-row>
              <el-col>代金券、产品券设置建议不能低于该店铺的人均消费。</el-col>
            </el-row>
          </div>
          <template #label>
            <span style="color: red">*</span> <span>到店消费金额：</span>
          </template>
        </el-form-item>

        <div v-if="form2.jineType == '1'">
          <el-form-item label="">
            <el-row>
              <el-col :span="14">
                <el-input
                  v-model="form2.darencanbiao"
                  placeholder="请输入达人到店消费金额"
                  maxlength="6"
                  @input="(v) => (form2.darencanbiao = v.replace(/[^\d]/g, ''))"
                  ><template slot="append"></template></el-input
              ></el-col>
              <el-col :span="2" style="text-align: center">元</el-col>
            </el-row>
            <template #label>
              <span style="color: red">*</span> <span>达人：</span>
            </template>
          </el-form-item>
          <el-form-item label="">
            <el-row>
              <el-col :span="14">
                <el-input
                  v-model="form2.renqicanbiao"
                  placeholder="请输入大人体验官到店消费金额"
                  maxlength="6"
                  @input="(v) => (form2.renqicanbiao = v.replace(/[^\d]/g, ''))"
                  ><template slot="append"></template></el-input
              ></el-col>
              <el-col :span="2" style="text-align: center">元</el-col>
            </el-row>
            <el-row>
              <el-col
                >建议不能低于该店铺的人均消费，且达人建议高于大人体验官到店消费金额。</el-col
              >
            </el-row>
            <template #label>
              <span style="color: red">*</span> <span>大人体验官：</span>
            </template>
          </el-form-item>
        </div>

        <el-form-item label="" prop="wxno">
          <el-row>
            <el-col :span="14">
              <el-input
                v-model="form2.wxno"
                placeholder="请输入微信号"
              ></el-input
            ></el-col>
            <el-col :span="4" style="text-align: center">
              <span
                @click="showWxImage"
                style="color: #3894ff; cursor: pointer"
              >
                示例</span
              >
            </el-col>
          </el-row>
          <template #label>
            <span style="color: red">*</span> <span>微信号：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="email">
          <el-row>
            <el-col :span="14">
              <el-input
                v-model="form2.email"
                placeholder="请输入邮箱"
              ></el-input
            ></el-col>
            <el-col :span="2"></el-col>
          </el-row>
          <template #label>
            <span style="color: red">*</span> <span>邮箱：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="phone">
          <el-row>
            <el-col :span="14">
              <el-input
                v-model="form2.phone"
                placeholder="请输入联系电话"
                maxlength="11"
                @input="(v) => (form2.phone = v.replace(/[^\d]/g, ''))"
              ></el-input
            ></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <template #label>
            <span style="color: red">*</span> <span>联系电话：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="code">
          <el-row>
            <el-col :span="14">
              <el-input
                v-model="form2.code"
                placeholder="请输入验证码"
                maxlength="6"
                @input="(v) => (form2.code = v.replace(/[^\d]/g, ''))"
              >
              </el-input
            ></el-col>
            <el-col :span="4" style="text-align: center">
              <span v-if="countDownTimer" disabled style="color: #3894ff">
                {{ countDownTime }}</span
              >
              <span
                v-else
                @click="sendSmsCode"
                style="color: #3894ff; cursor: pointer"
              >
                获取验证码</span
              >
            </el-col>
          </el-row>

          <template #label>
            <span style="color: red">*</span> <span>验证码：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="code">
          提交后，平台将在3个工作日与您联系。
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          style="
            color: #ffffff;
            font-weight: bold;
            background: rgb(241, 82, 54);
          "
          @click="maxTwoBack"
          >上一步</el-button
        >
        <el-button
          style="
            color: #ffffff;
            font-weight: bold;
            background: rgb(241, 82, 54);
          "
          @click="submitBiaozhun"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <!-- 定制版 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisibleLevelThree"
      width="840px"
      custom-class="dialog-with-underline"
      append-to-body
      placement="top"
      v-if="dialogVisibleLevelThree"
      @close="hideOver"
      :close-on-click-modal="false"
    >
      <el-form :model="form3" :rules="rules3" ref="form3" label-width="10em">
        <el-form-item label="">
          <el-row>
            <el-col :span="14">
              <el-date-picker
                v-model="form3.tuiguangzhouqi"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker
            ></el-col>
            <el-col :span="2" style="text-align: center"></el-col>
          </el-row>
          <template #label>
            <span style="color: red">*</span> <span>推广周期：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="type">
          <el-checkbox-group v-model="form3.pingtaiTypes">
            <el-checkbox label="1" @change="form3dycheckboxChange"
              >抖音</el-checkbox
            >
            <el-checkbox label="2" @change="form3xhscheckboxChange"
              >小红书</el-checkbox
            >
          </el-checkbox-group>
          <template #label>
            <span style="color: red">*</span> <span>推广平台：</span>
          </template>
        </el-form-item>
        <el-form-item label="">
          <el-row>
            <el-col
              :span="7"
              v-for="(item, index) in form3.tgItmes.dy"
              :key="index"
            >
              <el-row>
                <el-col :span="9">
                  <el-checkbox
                    @change="
                      (val) => {
                        form3dyclear(val, index);
                      }
                    "
                    :disabled="dyDisabled"
                    v-model="item.checked"
                    :key="index"
                    :label="item.name"
                  ></el-checkbox>
                </el-col>
                <el-col :span="11">
                  <el-input-number
                    :disabled="dyDisabled"
                    v-model="item.count"
                    placeholder="0"
                    :controls="false"
                    style="width: 95%"
                  ></el-input-number>
                </el-col>
                <el-col :span="2"> 人 </el-col>
              </el-row>
            </el-col>
          </el-row>
          <template #label>
            <span style="color: red">*</span> <span>抖音粉丝量：</span>
          </template>
        </el-form-item>
        <el-form-item label="">
          <el-row>
            <el-col
              :span="7"
              v-for="(item, index) in form3.tgItmes.xhs"
              :key="index"
            >
              <el-row>
                <el-col :span="9">
                  <el-checkbox
                    @change="
                      (val) => {
                        form3xhsclear(val, index);
                      }
                    "
                    :disabled="xhsDisabled"
                    v-model="item.checked"
                    :key="index"
                    :label="item.name"
                  ></el-checkbox>
                </el-col>
                <el-col :span="11">
                  <el-input-number
                    :disabled="xhsDisabled"
                    v-model="item.count"
                    :controls="false"
                    style="width: 95%"
                  ></el-input-number>
                </el-col>
                <el-col :span="2"> 人 </el-col>
              </el-row>
            </el-col>
          </el-row>
          <template #label>
            <span style="color: red">*</span> <span>小红书粉丝量：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="tiyanguan">
          <el-row>
            <el-col :span="7">
              <el-input-number
                v-model="form3.tiyanguan"
                :min="100"
                :max="10000"
                :controls="false"
                ><template slot="append"></template
              ></el-input-number>
            </el-col>
            <el-col :span="16"> 人（人气顾客到店消费人数） </el-col>
          </el-row>

          <template #label>
            <span style="color: red">*</span> <span>大人体验官：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="canbiao">
          <el-radio
            v-model="form3.jineType"
            label="1"
            @change="form3jineTypechange"
            >现金</el-radio
          >
          <el-radio
            v-model="form3.jineType"
            label="2"
            @change="form3jineTypechange"
            >无门槛的消费券</el-radio
          >
          <div v-if="form3.jineType == '2'">
            <el-row style="padding: 13px 0px">
              <el-col :span="4">
                <el-radio
                  v-model="form3.quan"
                  label="1"
                  @change="form3quanChange"
                  >代金券</el-radio
                ></el-col
              >
              <el-col :span="11">
                <el-input
                  :disabled="form3.quan == '2'"
                  v-model="form3.daijinquan"
                  maxlength="6"
                  @input="(v) => (form3.daijinquan = v.replace(/[^\d]/g, ''))"
                ></el-input>
              </el-col>
              <el-col :span="2" style="text-align: center">份</el-col>
            </el-row>
            <el-row style="padding: 13px 0px">
              <el-col :span="4">
                <el-radio
                  v-model="form3.quan"
                  label="2"
                  @change="form3quanChange"
                  >产品券</el-radio
                ></el-col
              >
              <el-col :span="11"
                ><el-input
                  :disabled="form3.quan == '1'"
                  v-model="form3.chanpinquan"
                  maxlength="6"
                  @input="(v) => (form3.chanpinquan = v.replace(/[^\d]/g, ''))"
                ></el-input
              ></el-col>
              <el-col :span="2" style="text-align: center">份</el-col>
            </el-row>
            <el-row>
              <el-col>代金券、产品券设置建议不能低于该店铺的人均消费。</el-col>
            </el-row>
          </div>

          <template #label>
            <span style="color: red">*</span> <span>到店消费金额：</span>
          </template>
        </el-form-item>

        <div v-if="form3.jineType == '1'">
          <el-form-item label="">
            <el-row>
              <el-col :span="14">
                <el-input
                  v-model="form3.darencanbiao"
                  placeholder="请输入达人到店消费金额"
                  maxlength="6"
                  @input="(v) => (form3.darencanbiao = v.replace(/[^\d]/g, ''))"
                  ><template slot="append"></template></el-input
              ></el-col>
              <el-col :span="2" style="text-align: center">元</el-col>
            </el-row>
            <template #label>
              <span style="color: red">*</span> <span>达人：</span>
            </template>
          </el-form-item>
          <el-form-item label="">
            <el-row>
              <el-col :span="14">
                <el-input
                  v-model="form3.renqicanbiao"
                  placeholder="请输入大人体验官到店消费金额"
                  maxlength="6"
                  @input="(v) => (form3.renqicanbiao = v.replace(/[^\d]/g, ''))"
                  ><template slot="append"></template></el-input
              ></el-col>
              <el-col :span="2" style="text-align: center">元</el-col>
            </el-row>
            <el-row>
              <el-col
                >建议不能低于该店铺的人均消费，且达人建议高于大人体验官到店消费金额。</el-col
              >
            </el-row>
            <template #label>
              <span style="color: red">*</span> <span>大人体验官：</span>
            </template>
          </el-form-item>
        </div>

        <el-form-item label="估价结果（含税）：">
          <span v-if="money == 0" style="color: red"
            >您未开始估价，请填写您的需求，快速获取估价结果。</span
          >
          <span v-else style="color: red"
            >您的推广引流估价总计为：￥{{
              money
            }}元，具体以实际付款费用为准。</span
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close1">取 消</el-button>
        <el-button
          style="
            color: #ffffff;
            font-weight: bold;
            background: rgb(241, 82, 54);
          "
          @click="gujia"
          >开始估价</el-button
        >
        <el-button
          style="
            color: #ffffff;
            font-weight: bold;
            background: rgb(241, 82, 54);
          "
          @click="maxThreeNext"
          v-if="money > 0"
          >下一步</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisibleLevelFource"
      width="840px"
      custom-class="dialog-with-underline"
      append-to-body
      v-if="dialogVisibleLevelFource"
      @close="hideOver"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules4" ref="form4" label-width="10em">
        <el-form-item label="">
          <el-select
            style="width: 71%"
            v-model="form4.tuiguanghuodong"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in selOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <template #label>
            <span style="color: red">*</span> <span>推广活动：</span>
          </template>
        </el-form-item>
        <el-form-item label="">
          <el-row>
            <el-col :span="17">
              <el-input
                v-model="form4.companyName"
                placeholder="请输入企业名称"
              ></el-input
            ></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <template #label>
            <span style="color: red">*</span> <span>企业名称：</span>
          </template>
        </el-form-item>
        <el-form-item label="">
          <el-row>
            <el-col :span="17">
              <el-input
                v-model="form4.shopAddress"
                type="textarea"
                placeholder="请输入门店地址"
              ></el-input
            ></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <template #label>
            <span style="color: red">*</span> <span>门店地址：</span>
          </template>
        </el-form-item>
        <el-form-item label="">
          <el-row>
            <el-col :span="17">
              <el-input
                v-model="form4.comment"
                type="textarea"
                placeholder="请输入门店业态简介，如：甜品店"
              ></el-input
            ></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <template #label>
            <span style="color: red">*</span> <span>业务简介：</span>
          </template>
        </el-form-item>
        <el-form-item label="" prop="wxno">
          <el-row>
            <el-col :span="17">
              <el-input
                v-model="form4.wxno"
                placeholder="请输入微信号"
              ></el-input
            ></el-col>
            <el-col :span="4" style="text-align: center">
              <span
                @click="showWxImage"
                style="color: #3894ff; cursor: pointer"
              >
                示例</span
              >
            </el-col>
          </el-row>
          <template #label>
            <span style="color: red">*</span> <span>微信号：</span>
          </template>
        </el-form-item>
        <el-form-item label="">
          <el-row>
            <el-col :span="17">
              <el-input
                v-model="form4.email"
                placeholder="请输入邮箱"
              ></el-input
            ></el-col>
            <el-col :span="2"></el-col>
          </el-row>
          <template #label>
            <span style="color: red">*</span> <span>邮箱：</span>
          </template>
        </el-form-item>
        <el-form-item label="">
          <el-row>
            <el-col :span="17">
              <el-input
                v-model="form4.phone"
                placeholder="请输入联系电话"
                @input="(v) => (form4.phone = v.replace(/[^\d]/g, ''))"
              ></el-input
            ></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <template #label>
            <span style="color: red">*</span> <span>联系电话：</span>
          </template>
        </el-form-item>
        <el-form-item label="">
          <el-row>
            <el-col :span="17">
              <el-input
                v-model="form4.code"
                placeholder="请输入验证码"
                @input="(v) => (form4.code = v.replace(/[^\d]/g, ''))"
              >
              </el-input
            ></el-col>
            <el-col :span="4" style="text-align: center">
              <span v-if="countDownTimer1" disabled style="color: #3894ff">
                {{ countDownTime1 }}</span
              >
              <span
                v-else
                @click="sendSmsCode1"
                style="color: #3894ff; cursor: pointer"
              >
                获取验证码</span
              >
            </el-col>
          </el-row>

          <template #label>
            <span style="color: red">*</span> <span>验证码：</span>
          </template>
        </el-form-item>
        <el-form-item label="">
          提交后，平台将在3个工作日与您联系。
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close1">取 消</el-button>
        <el-button
          style="
            color: #ffffff;
            font-weight: bold;
            background: rgb(241, 82, 54);
          "
          @click="maxThreeBack"
          >上一步</el-button
        >
        <el-button
          style="
            color: #ffffff;
            font-weight: bold;
            background: rgb(241, 82, 54);
          "
          @click="submitDingzhi"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <van-popup
      v-model="show"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      v-if="show"
    >
      <standardOne
        :title="title"
        :index="index"
        @custom-event="closeOne"
      ></standardOne>
    </van-popup>
    <van-popup
      v-model="show1"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      v-if="show1"
    >
      <customizedOne
        @custom-event="closeDingzhiOne"
        :title="title"
      ></customizedOne>
    </van-popup>
  </div>
</template>
<script>
import trends from "@/components/home/trends.vue";
import standardOne from "@/views/appAttractUsers/standard_one.vue";
import customizedOne from "@/views/appAttractUsers/customized_one.vue";
export default {
  components: {
    trends,
    standardOne,
    customizedOne,
  },
  props: {},
  data() {
    return {
      darenfeiyong: 0,
      darentiyanguanfeiyong: 0,
      shuifei: 0.06,
      wxImageShow: false,
      tyoneFiveNumber: 8,
      tyfiveTenNumber: 2,
      tytenNumber: 0,
      tythreeQNumber: 7,
      tythreeWNumber: 3,
      bzoneFiveNumber: 18,
      bzfiveTenNumber: 5,
      bztenNumber: 3,
      bzthreeQNumber: 20,
      bzthreeWNumber: 10,
      dyDisabled: false,
      xhsDisabled: false,
      countDownTimer: false,
      countDownTime: 60, // 假设60秒
      countDownTimer1: false,
      countDownTime1: 60, // 假设60秒
      widthBfb: "40%",
      show: false,
      show1: false,
      selectedValues: [], // 选中的值
      appShow: true,
      emptyCol: null,
      fwCol: null,
      checked: true,
      index: null,
      dialogVisibleLevelOne: false,
      dialogVisibleLevelTwo: false,
      dialogVisibleLevelThree: false,
      dialogVisibleLevelFource: false,
      title: "",
      selOptions: [
        {
          value: "1",
          label: "新店体验",
        },
        {
          value: "2",
          label: "新品体验",
        },
        {
          value: "3",
          label: "节假日活动",
        },
        {
          value: "4",
          label: "数字营销",
        },
        {
          value: "5",
          label: "口碑传播",
        },
        {
          value: "6",
          label: "获客消费",
        },
      ],
      form: {
        tuiguangzhouqi: [],
        tuiguanghuodong: [],
        companyName: "",
        shopAddress: "",
        comment: "",
      },
      rules: {},
      form2: {
        pingtaiType: "1",
        pingtaiTypes: [],
        tgItmes: [],
        tiyanguan: "",
        jineType: "1",
        quan: "1",
        darencanbiao: "",
        renqicanbiao: "",
        daijinquan: "",
        chanpinquan: "",
        email: "",
        phone: "",
        phoneObj: {},
        code: "",
        wxno: "",
      },
      rules2: {},
      form3: {
        tuiguangzhouqi: [],
        pingtaiType: "",
        tiyanguan: "",
        jineType: "1",
        darencanbiao: "",
        renqicanbiao: "",
        quan: "1",
        daijinquan: "",
        chanpinquan: "",
        tgItmes: [],
        pingtaiTypes: [],
      },
      rules3: {},
      form4: {
        tuiguanghuodong: [],
        companyName: "",
        shopAddress: "",
        comment: "",
        email: "",
        phone: "",
        phoneObj: {},
        code: "",
      },
      rules4: {},
      titleContent: [
        "新店体验",
        "新品体验",
        "节假日活动",
        "数字营销",
        "口碑传播",
        "获客消费",
      ],
      pickerOptions: {
        disabledDate: (time) => {
          let starttime = new Date().getTime() - 1 * (1000 * 60 * 60 * 24);

          let currentDate = new Date();
          currentDate.setDate(currentDate.getDate() + 180);
          // return time.getTime() < starttime || time.getTime() > lasttime;
          if (time.getTime() < starttime) {
            return true;
          }
          if (time.getTime() > currentDate.getTime()) {
            return true;
          }
        },
      },
      tgItmes: [
        {
          title: "推广体验版",
          frequency: "10000",
          remarkLevelOne: "一个店铺体验1次",
          remarkLevelTwo: "指定消费另行支付",
          xhs: ["10人"],
          dy: ["10人"],
          drly: "大人体验官30人",
        },
        {
          title: "推广标准版",
          frequency: "30000",
          remarkLevelOne: "不限制店铺购买体验次数",
          remarkLevelTwo: "指定消费另行支付",
          xhs: ["30人"],
          dy: ["26人"],
          drly: "大人体验官50人",
        },
        {
          title: "推广定制版",
          frequency: "自定义估价",
          remarkLevelOne: "不限制店铺购买体验次数",
          remarkLevelTwo: "",
          xhs: "自定义人数",
          dy: "自定义人数",
          drly: "自定义人数",
        },
      ],
      minOnew: "1-5w",
      minFivew: "5-10w",
      minTenw: "10w+",
      min3k: "3000+",
      minThreew: "3w+",
      fuwufei: 10000,
      dyGujiaMap: {},
      xhsGujiaMap: {},
      money: 0,
    };
  },
  watch: {},
  computed: {
    baseUrl() {
      if (location.href.indexOf("www.darenlaiye.com") > 0) {
        return "https://e.darenlaiye.com/application/api";
      } else if (location.href.indexOf("localhost") > 0) {
        return "http://127.0.0.1:9090/api";
      }
      return "https://e_test.darenlaiye.com/application/api";
    },
    // selectedLabels() {
    //   return this.options
    //     .filter((option) => this.selectedValues.includes(option.value))
    //     .map((option) => option.label);
    // },
  },
  methods: {
    liaojie() {
      window.open("https://yygj.darenlaiye.com/tuiguang2/index.html");
    },
    form3jineTypechange() {
      if (this.form3.jineType == "1") {
        this.form3.daijinquan = "";
        this.form3.chanpinquan = "";
      } else {
        this.form3.darencanbiao = "";
        this.form3.renqicanbiao = "";
      }
    },
    form3quanChange() {
      if (this.form3.quan == "1") {
        this.form3.chanpinquan = "";
      } else {
        this.form3.daijinquan = "";
      }
    },
    form3dyclear(v, index) {
      if (v) {
        if (!this.form3.tgItmes.dy[index].count) {
          this.form3.tgItmes.dy[index].count = null;
        }
      } else {
        this.form3.tgItmes.dy[index].count = null;
      }
    },
    form3xhsclear(v, index) {
      if (v) {
        if (!this.form3.tgItmes.xhs[index].count) {
          this.form3.tgItmes.xhs[index].count = null;
        }
      } else {
        this.form3.tgItmes.xhs[index].count = null;
      }
    },
    form2JineTypeChange() {
      if (this.form2.jineType == "1") {
        this.form2.daijinquan = "";
        this.form2.chanpinquan = "";
      } else {
        this.form2.darencanbiao = "";
        this.form2.renqicanbiao = "";
      }
    },
    form2quanChange() {
      if (this.form2.quan == "1") {
        this.form2.chanpinquan = "";
      } else {
        this.form2.daijinquan = "";
      }
    },
    hideOver() {
      document.body.style.overflow = "";
    },
    form2dycheckboxChange(value) {
      if (value) {
        this.form2.tgItmes.dy.forEach((e) => {
          e.checked = true;
        });
      } else {
        this.form2.tgItmes.dy.forEach((e) => {
          e.checked = false;
        });
      }
    },
    form2xhscheckboxChange(value) {
      if (value) {
        this.form2.tgItmes.xhs.forEach((e) => {
          e.checked = true;
        });
      } else {
        this.form2.tgItmes.xhs.forEach((e) => {
          e.checked = false;
        });
      }
    },
    form3dycheckboxChange(value) {
      if (value) {
        this.dyDisabled = false;
        this.form3.tgItmes.dy.forEach((e) => {
          e.checked = true;
        });
      } else {
        this.dyDisabled = true;
        this.form3.tgItmes.dy.forEach((e) => {
          e.checked = false;
          e.count = null;
        });
      }
    },
    form3xhscheckboxChange(value) {
      if (value) {
        this.xhsDisabled = false;
        this.form3.tgItmes.xhs.forEach((e) => {
          e.checked = true;
        });
      } else {
        this.xhsDisabled = true;
        this.form3.tgItmes.xhs.forEach((e) => {
          e.checked = false;
          e.count = null;
        });
      }
    },
    showWxImage() {
      this.wxImageShow = true;
    },
    requestApi(actions, params) {
      params.platform = "guanwang";
      return new Promise((resolve, reject) => {
        fetch(`${this.baseUrl}?action=${actions}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ params: params }),
        })
          .then((response) => response.json()) // 如果服务器返回JSON，则解析它
          .then((resp) => {
            if (resp.code == 0) {
              resolve(resp);
            } else {
              reject(resp);
            }
          }) // 处理服务器返回的数据
          .catch((error) => {
            reject(error);
          }); // 处理错误
      });
    },
    sendSmsCode() {
      if (this.countDownTimer) {
        return;
      }

      if (!this.form2.phone) {
        this.$message.error("请输入联系电话");
        return false;
      } else {
        let reg = /^1\d{10}$/;
        if (!reg.test(this.form2.phone)) {
          this.$message.error("联系电话格式不正确");
          return false;
        }
      }
      this.countDownTimer = true;
      this.countDownTime = 60;
      // 发送短信验证码逻辑
      // api.biz.business.eval.log.sms
      this.requestApi("api.biz.business.eval.log.sms", {
        phone: this.form2.phone,
      })
        .then((resp) => {
          this.$message.success("获取验证码成功！");
          this.form2.phoneObj.timer = setInterval(() => {
            if (this.countDownTime == 1) {
              this.countDownFinished();
            }
            this.countDownTime = this.countDownTime - 1;
          }, 1000);
        })
        .catch((err) => {
          // 模拟计时器
          this.countDownTimer = false;
          this.$message.error(err.msg);
        })
        .finally(() => {});
    },
    countDownFinished() {
      if (this.form2.phoneObj.timer) {
        clearInterval(this.form2.phoneObj.timer);
      }
      this.countDownTimer = false;
      this.countDownTime = 60;
    },

    sendSmsCode1() {
      if (this.countDownTimer1) {
        return;
      }
      if (!this.form4.phone) {
        this.$message.error("请输入联系电话");
        return false;
      } else {
        let reg = /^1\d{10}$/;
        if (!reg.test(this.form4.phone)) {
          this.$message.error("联系电话格式不正确");
          return false;
        }
      }
      this.countDownTimer1 = true;
      this.countDownTime1 = 60;
      // 发送短信验证码逻辑
      // api.biz.business.eval.log.sms
      this.requestApi("api.biz.business.eval.log.sms", {
        phone: this.form4.phone,
      })
        .then((resp) => {
          this.$message.success("获取验证码成功！");
          this.form4.phoneObj.timer = setInterval(() => {
            if (this.countDownTime1 == 1) {
              this.countDownFinished1();
            }
            this.countDownTime1 = this.countDownTime1 - 1;
          }, 1000);
        })
        .catch((err) => {
          // 模拟计时器
          this.countDownTimer1 = false;
          this.$message.error(err.msg);
        })
        .finally(() => {});
    },
    countDownFinished1() {
      if (this.form4.phoneObj.timer) {
        clearInterval(this.form4.phoneObj.timer);
      }
      this.countDownTimer1 = false;
      this.countDownTime1 = 60;
    },
    gujia() {
      var money = 0;
      if (this.form3.pingtaiTypes.length == 0) {
        this.$message.error("推广平台至少选择1项");
        return;
      }
      let baifenbai = 100;
      let shuifeibfb = this.shuifei * baifenbai;

      let countsum = 0;

      if (this.form3.pingtaiTypes.includes("1")) {
        this.form3.tgItmes.dy.forEach((element) => {
          if (element.checked && parseInt(element.count) > 0) {
            countsum += element.count;
            let name = element.name;
            var danjia = this.dyGujiaMap[name];
            for (let i = 0; i < parseInt(element.count); i++) {
              money +=
                danjia * baifenbai + (danjia * baifenbai * shuifeibfb) / 100;
            }
          }
        });
      }
      if (this.form3.pingtaiTypes.includes("2")) {
        this.form3.tgItmes.xhs.forEach((element) => {
          if (element.checked && parseInt(element.count) > 0) {
            countsum += element.count;
            let name = element.name;
            var danjia = this.xhsGujiaMap[name];
            for (let i = 0; i < parseInt(element.count); i++) {
              money +=
                danjia * baifenbai + (danjia * baifenbai * shuifeibfb) / 100;
            }
          }
        });
      }
      this.darenfeiyong = money;

      let darenfeiyongsuan = 0;
      if (this.form3.tiyanguan) {
        for (let i = 0; i < parseInt(this.form3.tiyanguan); i++) {
          money += 100 * baifenbai + (100 * baifenbai * shuifeibfb) / 100;
          money +=
            this.form3.renqicanbiao * baifenbai +
            (this.form3.renqicanbiao * baifenbai * shuifeibfb) / 100;
          darenfeiyongsuan +=
            100 * baifenbai + (100 * baifenbai * shuifeibfb) / 100;
        }
      }
      this.darentiyanguanfeiyong = darenfeiyongsuan;
      if (this.form3.jineType == "1") {
        for (let i = 0; i < countsum; i++) {
          money +=
            this.form3.darencanbiao * baifenbai +
            (this.form3.darencanbiao * baifenbai * shuifeibfb) / 100;
        }
      }

      money += (this.fuwufei + this.fuwufei * this.shuifei) * 100;
      money = money / 100;
      if (money < 50000) {
        this.$message.error(
          "您当前估价金额为" +
            money +
            "元，不满足定制版最低消费要求50000元，请调整后重新输入。"
        );
        return;
      }
      if (money) {
        this.money = money;
      }
    },
    submitBiaozhun() {
      if (this.index == 0) {
        if (this.form2.pingtaiType == "0") {
          this.$message.error("推广平台至少选择1项");
          return false;
        }
      } else {
        if (this.form2.pingtaiTypes.length == 0) {
          this.$message.error("推广平台至少选择1项");
          return false;
        }
      }

      if (this.form2.jineType == "1") {
        if (!this.form2.darencanbiao) {
          this.$message.error("请输入达人到店消费金额");
          return false;
        } else if (Number(this.form2.darencanbiao) < 25) {
          this.$message.error("达人到店消费金额不能低于25元");
          return false;
        }

        if (!this.form2.renqicanbiao) {
          this.$message.error("请输入大人体验官消费金额");
          return false;
        } else if (Number(this.form2.renqicanbiao) < 25) {
          this.$message.error("大人体验官消费金额不能低于25元");
          return false;
        }
      } else {
        if (this.form2.quan == "1") {
          if (!this.form2.daijinquan) {
            this.$message.error("请输入代金券份数");
            return false;
          } else if (Number(this.form2.daijinquan) == 0) {
            this.$message.error("请至少输入1份代金券");
            return false;
          }
        } else {
          if (!this.form2.chanpinquan) {
            this.$message.error("请输入产品券份数");
            return false;
          } else if (Number(this.form2.chanpinquan) == 0) {
            this.$message.error("请至少输入1份产品券");
            return false;
          }
        }
      }
      if (!this.form2.wxno) {
        this.$message.error("请输入微信号");
        return false;
      } else {
        this.form2.wxno = this.form2.wxno.replace(/\s+/g, "");
        if (!this.form2.wxno) {
          this.$message.error("请输入微信号");
          return false;
        }
      }

      if (!this.form2.email) {
        this.$message.error("请输入邮箱");
        return false;
      } else {
        this.form2.email = this.form2.email.replace(/\s+/g, "");
        if (!this.form2.email) {
          this.$message.error("请输入邮箱");
          return false;
        }
      }

      if (!this.form2.phone) {
        this.$message.error("请输入联系电话");
        return false;
      } else {
        let reg = /^1\d{10}$/;
        if (!reg.test(this.form2.phone)) {
          this.$message.error("联系电话格式不正确");
          return false;
        }
      }
      if (!this.form2.code) {
        this.$message.error("请输入验证码");
        return false;
      }

      let productType = 0;
      let dyFlag = 0;
      let xhsFlag = 0;
      let oneFiveWNumber = 0;
      let fiveTenWNumber = 0;
      let tenPlusWNumber = 0;
      let threeQNumber = 0;
      let threeWNumber = 0;

      let expertTotalMoney = 0;
      let drUserTotalMoney = 0;
      let consultTotalMoney = 0;
      let platformTotalMoney = 0;

      let appraisalMoney = 0;

      if (this.index == 0) {
        if (this.form2.pingtaiType == "1") {
          dyFlag = 1;
          xhsFlag = 0;
          oneFiveWNumber = this.tyoneFiveNumber;
          fiveTenWNumber = this.tyfiveTenNumber;
          tenPlusWNumber = this.tytenNumber;
        } else {
          xhsFlag = 1;
          dyFlag = 0;
          threeQNumber = this.tythreeQNumber;
          threeWNumber = this.tythreeWNumber;
        }
        productType = 1;

        expertTotalMoney = 7420;
        drUserTotalMoney = 3180;

        appraisalMoney = 10000;
      } else if (this.index == 1) {
        productType = 2;
        if (this.form2.pingtaiTypes.includes("1")) {
          dyFlag = 1;
          oneFiveWNumber = this.bzoneFiveNumber;
          fiveTenWNumber = this.bzfiveTenNumber;
          tenPlusWNumber = this.bztenNumber;
          expertTotalMoney += 23426;
          drUserTotalMoney += 8374;
          appraisalMoney += 30000;
        }
        if (this.form2.pingtaiTypes.includes("2")) {
          xhsFlag = 1;
          threeQNumber = this.bzthreeQNumber;
          threeWNumber = this.bzthreeWNumber;
          expertTotalMoney += 24380;
          drUserTotalMoney += 7420;
          appraisalMoney += 30000;
        }
      }

      let baifenbai = 100;
      let shuifeibfb = this.shuifei * baifenbai;

      let expertMoney = 0;
      let drUserMoney = 0;
      let consumeTicketType = 0;
      let consumeTicketNumber = 0;
      if (this.form2.jineType == "1") {
        if (this.form3.jineType == "1") {
          expertMoney = this.form2.darencanbiao;
          drUserMoney = this.form2.renqicanbiao;
        }
      } else {
        consumeTicketType = this.form2.quan;
        if (consumeTicketType == "1") {
          consumeTicketNumber = this.form2.daijinquan;
        } else {
          consumeTicketNumber = this.form2.chanpinquan;
        }
      }
      this.requestApi("api.internet.traffic.promotion.create", {
        productType: productType,
        startDate: new Date(this.form.tuiguangzhouqi[0]).getTime(),
        endDate: new Date(this.form.tuiguangzhouqi[1]).getTime(),
        activity: this.form.tuiguanghuodong.join(","),
        companyName: this.form.companyName,
        shopAddress: this.form.shopAddress,
        companyProfile: this.form.comment,
        dyFlag: Number(dyFlag),
        xhsFlag: Number(xhsFlag),
        oneFiveWNumber: Number(oneFiveWNumber),
        fiveTenWNumber: Number(fiveTenWNumber),
        tenPlusWNumber: Number(tenPlusWNumber),
        threeQNumber: Number(threeQNumber),
        threeWNumber: Number(threeWNumber),
        drUserNumber: Number(this.form2.tiyanguan),
        consumeType: Number(this.form2.jineType),
        expertMoney: Number(expertMoney),
        drUserMoney: Number(drUserMoney),
        consumeTicketType: Number(consumeTicketType),
        consumeTicketNumber: Number(consumeTicketNumber),
        userWechat: this.form2.wxno,
        userEmail: this.form2.email,
        userPhone: this.form2.phone,
        appraisalMoney: appraisalMoney,
        code: this.form2.code,
        expertTotalMoney: Number(expertTotalMoney),
        drUserTotalMoney: Number(drUserTotalMoney),
        consultTotalMoney: Number(consultTotalMoney),
        platformTotalMoney: Number(platformTotalMoney),
      })
        .then((resp) => {
          this.$message.success("提交成功");
          this.close();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          return false;
        })
        .finally(() => {});
    },
    submitDingzhi() {
      if (this.form4.tuiguanghuodong.length == 0) {
        this.$message.error("请选择推广活动");
        return false;
      }
      if (!this.form4.companyName) {
        this.$message.error("请输入企业名称");
        return false;
      } else {
        this.form4.companyName = this.form4.companyName.replace(/\s+/g, "");
        if (!this.form4.companyName) {
          this.$message.error("请输入企业名称");
          return false;
        }
      }
      if (!this.form4.shopAddress) {
        this.$message.error("请输入门店地址");
        return false;
      } else {
        this.form4.shopAddress = this.form4.shopAddress.replace(/\s+/g, "");
        if (!this.form4.shopAddress) {
          this.$message.error("请输入门店地址");
          return false;
        }
      }
      if (!this.form4.comment) {
        this.$message.error("请输入业务简介");
        return false;
      } else {
        this.form4.comment = this.form4.comment.replace(/\s+/g, "");
        if (!this.form4.comment) {
          this.$message.error("请输入业务简介");
          return false;
        }
      }
      if (!this.form4.wxno) {
        this.$message.error("请输入微信号");
        return false;
      } else {
        this.form4.wxno = this.form4.wxno.replace(/\s+/g, "");
        if (!this.form4.wxno) {
          this.$message.error("请输入微信号");
          return false;
        }
      }
      if (!this.form4.email) {
        this.$message.error("请输入邮箱");
        return false;
      } else {
        this.form4.email = this.form4.email.replace(/\s+/g, "");
        if (!this.form4.email) {
          this.$message.error("请输入邮箱");
          return false;
        }
      }
      if (!this.form4.phone) {
        this.$message.error("请输入联系电话");
        return false;
      } else {
        let reg = /^1\d{10}$/;
        if (!reg.test(this.form4.phone)) {
          this.$message.error("联系电话格式不正确");
          return false;
        }
      }
      if (!this.form4.code) {
        this.$message.error("请输入验证码");
        return false;
      }
      let dyFlag = 0;
      let xhsFlag = 0;

      let oneFiveWNumber = 0;
      let fiveTenWNumber = 0;
      let tenPlusWNumber = 0;
      let threeQNumber = 0;
      let threeWNumber = 0;

      let expertTotalMoney = 0;
      let drUserTotalMoney = 0;
      let consultTotalMoney = 0;
      let platformTotalMoney = 0;

      expertTotalMoney = this.darenfeiyong / 100;
      drUserTotalMoney = this.darentiyanguanfeiyong / 100;
      consultTotalMoney = 10000;

      if (this.form3.pingtaiTypes.includes("1")) {
        dyFlag = 1;
        for (let i = 0; i < this.form3.tgItmes.dy.length; i++) {
          let obj = this.form3.tgItmes.dy[i];
          if (i == 0 && obj.checked && obj.count) {
            oneFiveWNumber = obj.count;
          }
          if (i == 1 && obj.checked && obj.count) {
            fiveTenWNumber = obj.count;
          }
          if (i == 2 && obj.checked && obj.count) {
            tenPlusWNumber = obj.count;
          }
        }
      }
      if (this.form3.pingtaiTypes.includes("2")) {
        xhsFlag = 1;
        for (let i = 0; i < this.form3.tgItmes.xhs.length; i++) {
          let obj = this.form3.tgItmes.xhs[i];
          if (i == 0 && obj.checked && obj.count) {
            threeQNumber = obj.count;
          }
          if (i == 1 && obj.checked && obj.count) {
            threeWNumber = obj.count;
          }
        }
      }

      let baifenbai = 100;
      let shuifeibfb = this.shuifei * baifenbai;

      let expertMoney = 0;
      let drUserMoney = 0;
      let consumeTicketType = 0;
      let consumeTicketNumber = 0;
      if (this.form3.jineType == "1") {
        expertMoney = this.form3.darencanbiao;
        drUserMoney = this.form3.renqicanbiao;
      } else {
        consumeTicketType = this.form3.quan;
        if (this.form3.quan == "1") {
          consumeTicketNumber = this.form3.daijinquan;
        } else {
          consumeTicketNumber = this.form3.chanpinquan;
        }
      }
      this.requestApi("api.internet.traffic.promotion.create", {
        productType: 3,
        startDate: new Date(this.form3.tuiguangzhouqi[0]).getTime(),
        endDate: new Date(this.form3.tuiguangzhouqi[1]).getTime(),
        activity: this.form4.tuiguanghuodong.join(","),
        companyName: this.form4.companyName,
        shopAddress: this.form4.shopAddress,
        companyProfile: this.form4.comment,
        dyFlag: Number(dyFlag),
        xhsFlag: Number(xhsFlag),
        oneFiveWNumber: Number(oneFiveWNumber),
        fiveTenWNumber: Number(fiveTenWNumber),
        tenPlusWNumber: Number(tenPlusWNumber),
        threeQNumber: Number(threeQNumber),
        threeWNumber: Number(threeWNumber),
        drUserNumber: Number(this.form3.tiyanguan),
        consumeType: Number(this.form3.jineType),
        expertMoney: Number(expertMoney),
        drUserMoney: Number(drUserMoney),
        consumeTicketType: Number(consumeTicketType),
        consumeTicketNumber: Number(consumeTicketNumber),
        userWechat: this.form4.wxno,
        userEmail: this.form4.email,
        userPhone: this.form4.phone,
        appraisalMoney: this.money,
        code: this.form4.code,
        expertTotalMoney: Number(expertTotalMoney),
        drUserTotalMoney: Number(drUserTotalMoney),
        consultTotalMoney: Number(consultTotalMoney),
        platformTotalMoney: Number(platformTotalMoney),
      })
        .then((resp) => {
          this.$message.success("提交成功");
          this.close1();
        })
        .catch((err) => {
          this.$message.error(err.msg);
          return false;
        })
        .finally(() => {});
    },
    consultClick(index) {
      this.index = index;
      const width = this.$refs.body.clientWidth;
      if (width > 767) {
        //初始化
        document.body.style.overflow = "hidden";
        this.title = this.tgItmes[index].title;
        //定制版
        if (index == 2) {
          this.form3 = {
            tuiguangzhouqi: [],
            pingtaiType: "",
            tiyanguan: "",
            jineType: "1",
            darencanbiao: "",
            renqicanbiao: "",
            quan: "1",
            daijinquan: "",
            chanpinquan: "",
            tgItmes: [],
            pingtaiTypes: [],
          };
          this.form4 = {
            tuiguanghuodong: [],
            companyName: "",
            shopAddress: "",
            comment: "",
            email: "",
            phone: "",
            phoneObj: {},
            code: "",
          };

          this.money = 0;
          this.$set(this.form3.tgItmes, "xhs", [
            { name: this.min3k, count: null, checked: false },
            { name: this.minThreew, count: null, checked: false },
          ]);
          this.$set(this.form3.tgItmes, "dy", [
            { name: this.minOnew, count: null, checked: false },
            { name: this.minFivew, count: null, checked: false },
            { name: this.minTenw, count: null, checked: false },
          ]);
          this.dialogVisibleLevelThree = true;
          this.dyDisabled = true;
          this.xhsDisabled = true;
        } else {
          this.form = {
            tuiguangzhouqi: [],
            tuiguanghuodong: [],
            companyName: "",
            shopAddress: "",
            comment: "",
          };
          this.form2 = {
            pingtaiType: "1",
            pingtaiTypes: [],
            tgItmes: [],
            tiyanguan: "",
            jineType: "1",
            quan: "1",
            darencanbiao: "",
            renqicanbiao: "",
            daijinquan: "",
            chanpinquan: "",
            email: "",
            phone: "",
            phoneObj: {},
            code: "",
            wxno: "",
          };

          //体验版
          let oneFive = this.tyoneFiveNumber;
          let fiveTen = this.tyfiveTenNumber;
          let ten = this.tytenNumber;
          let threeQ = this.tythreeQNumber;
          let threeW = this.tythreeWNumber;
          this.form2.tiyanguan = 30;
          let checkbool = true;
          if (index == 1) {
            //标准版
            oneFive = this.bzoneFiveNumber;
            fiveTen = this.bzfiveTenNumber;
            ten = this.bztenNumber;
            threeQ = this.bzthreeQNumber;
            threeW = this.bzthreeWNumber;
            this.form2.pingtaiTypes = [];
            this.form2.tiyanguan = 50;
            checkbool = false;
          }

          this.$set(this.form2.tgItmes, "xhs", [
            { name: this.min3k, count: threeQ, checked: checkbool },
            { name: this.minThreew, count: threeW, checked: checkbool },
          ]);
          this.$set(this.form2.tgItmes, "dy", [
            { name: this.minOnew, count: oneFive, checked: checkbool },
            { name: this.minFivew, count: fiveTen, checked: checkbool },
            { name: this.minTenw, count: ten, checked: checkbool },
          ]);
          this.dialogVisibleLevelOne = true;
        }
      } else {
        if (index != 2) {
          this.show = true;
        } else {
          this.show1 = true;
        }
        this.title = this.tgItmes[index].title;
      }
    },
    close() {
      this.dialogVisibleLevelOne = false;
      this.dialogVisibleLevelTwo = false;
      setTimeout(() => {
        document.body.style.overflow = "";
      }, 100);
    },
    close1() {
      this.dialogVisibleLevelThree = false;
      this.dialogVisibleLevelFource = false;
      setTimeout(() => {
        document.body.style.overflow = "";
      }, 100);
    },
    maxTwoNext() {
      if (
        this.form.tuiguangzhouqi == null ||
        this.form.tuiguangzhouqi.length == 0
      ) {
        this.$message.error("请选择推广周期");
        return false;
      } else {
        let st = new Date(this.form.tuiguangzhouqi[0]);
        let en = new Date(this.form.tuiguangzhouqi[1]);
        if (st > en) {
          this.$message.error("推广周期开始时间不能大于结束时间");
          return false;
        }
        const diffTime = Math.abs(en - st);
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 29) {
          this.$message.error("推广周期至少间隔30天");
          return false;
        }
      }
      if (this.form.tuiguanghuodong.length == 0) {
        this.$message.error("请选择推广活动");
        return false;
      }
      if (!this.form.companyName) {
        this.$message.error("请输入企业名称");
        return false;
      } else {
        this.form.companyName = this.form.companyName.replace(/\s+/g, "");
        if (!this.form.companyName) {
          this.$message.error("请输入企业名称");
          return false;
        }
      }
      if (!this.form.shopAddress) {
        this.$message.error("请输入门店地址");
        return false;
      } else {
        this.form.shopAddress = this.form.shopAddress.replace(/\s+/g, "");
        if (!this.form.shopAddress) {
          this.$message.error("请输入门店地址");
          return false;
        }
      }
      if (!this.form.comment) {
        this.$message.error("请输入业务简介");
        return false;
      } else {
        this.form.comment = this.form.comment.replace(/\s+/g, "");
        if (!this.form.comment) {
          this.$message.error("请输入业务简介");
          return false;
        }
      }
      setTimeout(() => {
        this.dialogVisibleLevelOne = false;
      }, 10);
      this.dialogVisibleLevelTwo = true;
    },
    maxTwoBack() {
      setTimeout(() => {
        this.dialogVisibleLevelTwo = false;
      }, 10);
      this.dialogVisibleLevelOne = true;
    },
    maxThreeNext() {
      if (
        this.form3.tuiguangzhouqi == null ||
        this.form3.tuiguangzhouqi.length == 0
      ) {
        this.$message.error("请选择推广周期");
        return false;
      } else {
        let st = new Date(this.form3.tuiguangzhouqi[0]);
        let en = new Date(this.form3.tuiguangzhouqi[1]);
        if (st > en) {
          this.$message.error("推广周期开始时间不能大于结束时间");
          return false;
        }
        const diffTime = Math.abs(en - st);
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 29) {
          this.$message.error("推广周期至少间隔30天");
          return false;
        }
      }
      if (this.form3.pingtaiTypes.length == 0) {
        this.$message.error("请至少选择1项推广平台");
        return false;
      } else {
        if (this.form3.pingtaiTypes.includes("1")) {
          if (this.form3.tgItmes.dy) {
            let bool = false;
            let check = 0;
            let message = "";
            this.form3.tgItmes.dy.forEach((e) => {
              if (!bool) {
                if (e.checked) {
                  if (e.count == null || e.count == undefined) {
                    bool = true;
                    message = "抖音" + e.name + "：请输入粉丝量";
                  } else {
                    if (Number(e.count) == 0) {
                      bool = true;
                      message = "抖音" + e.name + "：粉丝量至少输入1人";
                    }
                  }
                } else {
                  check += 1;
                }
              }
            });
            if (bool) {
              this.$message.error(message);
              return false;
            }
            if (check == 3) {
              this.$message.error("抖音粉丝量最少选择1项");
              return false;
            }
          }
        }
        if (this.form3.pingtaiTypes.includes("2")) {
          if (this.form3.tgItmes.xhs) {
            let bool = false;
            let check = 0;
            let message = "";
            this.form3.tgItmes.xhs.forEach((e) => {
              if (!bool) {
                if (e.checked) {
                  if (e.count == null || e.count == undefined) {
                    bool = true;
                    message = "小红书" + e.name + "：请输入粉丝量";
                  } else {
                    if (Number(e.count) == 0) {
                      bool = true;
                      message = "小红书" + e.name + "：粉丝量至少输入1人";
                    }
                  }
                } else {
                  check += 1;
                }
              }
            });
            if (bool) {
              this.$message.error(message);
              return false;
            }
            if (check == 2) {
              this.$message.error("小红书粉丝量最少选择1项");
              return false;
            }
          }
        }
      }

      if (!this.form3.tiyanguan) {
        this.$message.error("请输入大人体验官人数");
        return false;
      } else if (Number(this.form3.tiyanguan) < 100) {
        this.$toast("最少填写100位大人体验官");
        return false;
      } else if (Number(this.form3.tiyanguan) > 1000) {
        this.$toast("最高填写1000位大人体验官");
        return false;
      }
      if (this.form3.jineType == "1") {
        if (!this.form3.darencanbiao) {
          this.$message.error("请输入达人到店消费金额");
          return false;
        } else if (Number(this.form3.darencanbiao) < 25) {
          this.$message.error("达人到店消费金额不能低于25元");
          return false;
        }
        if (!this.form3.renqicanbiao) {
          this.$message.error("请输入大人体验官消费金额");
          return false;
        } else if (Number(this.form3.renqicanbiao) < 25) {
          this.$message.error("大人体验官消费金额不能低于25元");
          return false;
        }
      } else {
        if (this.form3.quan == "1") {
          if (!this.form3.daijinquan) {
            this.$message.error("请输入代金券份数");
            return false;
          } else if (Number(this.form3.daijinquan) == 0) {
            this.$message.error("请至少输入1份代金券");
            return false;
          }
        } else {
          if (!this.form3.chanpinquan) {
            this.$message.error("请输入产品券份数");
            return false;
          } else if (Number(this.form3.chanpinquan) == 0) {
            this.$message.error("请至少输入1份产品券");
            return false;
          }
        }
      }
      setTimeout(() => {
        this.dialogVisibleLevelThree = false;
      }, 10);
      this.dialogVisibleLevelFource = true;
    },
    maxThreeBack() {
      this.dialogVisibleLevelThree = true;
      setTimeout(() => {
        this.dialogVisibleLevelFource = false;
      }, 10);
    },
    handleResize() {
      const width = this.$refs.body.clientWidth;
      if (width >= 1280) {
      } else if (width <= 1279 && width > 767) {
      } else {
      }
    },
    closeOne() {
      this.show = false;
    },
    closeDingzhiOne() {
      this.show1 = false;
    },
  },
  created() {},
  mounted() {
    this.dyGujiaMap[this.minOnew] = 600; //1-5w
    this.dyGujiaMap[this.minFivew] = 1300; //5-10w
    this.dyGujiaMap[this.minTenw] = 1600; //10w+
    this.xhsGujiaMap[this.min3k] = 500; //3k+
    this.xhsGujiaMap[this.minThreew] = 1300; //3w+
  },
  beforeDestroy() {},
};
</script>
<style lang='less' scoped>
/deep/ .el-dialog__header {
  border-bottom: 1px solid #eee;
}
/deep/ .el-dialog__title {
  font-weight: bold;
  color: #000;
}
/deep/ .el-form-item__label {
  // font-weight: bold;
  color: #000;
}
/deep/ .el-popup-parent--hidden {
  padding-right: 0px !important;
  overflow: hidden !important;
}
/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb !important;
}
/deep/ .el-textarea {
  .el-textarea__inner {
    resize: none; // 去除右下角图标
  }
}
/deep/ .el-input-number .el-input__inner {
  text-align: left;
}

.rightAligned {
  z-index: 999;
  position: fixed;
  right: 10px; /* 这将元素定位到最右边 */
  top: 350px; /* 根据需要调整，这里是定位到最顶部 */
  .image1 {
    width: 110px;
    height: 75px;
  }
  .image2 {
    width: 150px;
    height: 150px;
    border-radius: 7px;
  }
  .button2 {
    display: none;
    font-weight: bold;
  }
  .button1 {
    margin-top: 2px;
    font-weight: bold;
    width: 150px;
    height: 30px;
    line-height: 17px;
    border-radius: 38px;
    background: linear-gradient(
      96.62deg,
      rgba(238, 75, 55, 1) 6.4%,
      rgba(222, 165, 79, 1) 95.57%
    );
    color: rgba(247, 248, 250, 1);
    font-size: 12px;
    text-align: center;
    font-family: Roboto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tg_body {
  background-color: #f7f8f9;
  max-width: 1280px;
  padding: 0px 20px;
  margin: 0px auto;
  .trends_row {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    .trends_card {
      display: flex;
      justify-content: center;
      width: calc(33.333333% - 20px);
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 1.25rem;
      .tg_item_body {
        position: relative;
        .youhui {
          font-weight: bold;
          position: absolute;
          top: 0;
          right: 0;
          width: 70px;
          color: white;
          padding: 7px;
          border-radius: 0px 0px 0px 10px;

          background: linear-gradient(
            93.25deg,
            rgba(242, 68, 41, 1) 3.99%,
            rgba(232, 102, 61, 1) 37.31%,
            rgba(242, 112, 50, 1) 74.45%,
            rgba(253, 143, 70, 1) 97.3%
          );
          color: rgba(255, 255, 255, 1);
        }
        position: relative;
        .tg_item_title {
          color: rgba(38, 51, 95, 1);
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 3%;
        }
        width: 83%;
        // color: rgba(38, 51, 95, 1);
        padding: 20px 12px; /* 上下间隙和左右间隙不同 */
        border-radius: 6px;
        .tg_item_top {
          border-radius: 6px;
          height: 220px;
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 0px 2px 6px 0px rgba(230, 215, 228, 0.99);
          text-align: center;
          margin-bottom: 3%;
          padding: 1.25rem;
          display: grid;
          align-items: center;
          font-weight: bold;
          .row {
            .row_red {
              color: red;
              font-size: 26px;
            }
          }
          .row_remark {
            .zhuti {
              font-size: 16px;
              margin-bottom: 10px;
              letter-spacing: 1px;
              font-weight: bold;
            }
            .citi {
              letter-spacing: 1px;
              font-size: 14px;
            }
          }
          .row_button {
            height: 35px;
            border: 1px solid #2f375e; /* 2px边框，实线，红色 */
            border-radius: 25px;
            background-color: #2f375e;
            display: grid;
            align-items: center;
            .zixun {
              color: #fff0ca;
              cursor: pointer;
            }
          }
        }
        .tg_item_bottom {
          border-radius: 6px;
          height: 230px;
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 0px 2px 6px 0px rgba(230, 215, 228, 0.99);
          padding: 0.9rem;
          .row_fw {
            font-weight: bold;
            font-size: 17px;
            height: 15%;
          }
          .content_head {
            display: grid;
            margin-bottom: 5%;
            height: 42%;
            font-size: 0.9rem;
            font-weight: bold;
            .title {
              .biaoti {
                display: flex;
                align-items: center;
                .duihao {
                  width: 24px;
                  height: 24px;
                }
                .yuan {
                  display: inline-block;
                  width: 0.9em;
                }
              }
            }
          }
          .content_bottom {
            height: 25%;
            font-size: 0.9rem;
            font-weight: bold;
            display: grid;
            .title {
              .biaoti {
                display: flex;
                align-items: center;
                .duihao {
                  width: 24px;
                  height: 24px;
                }
                .yuan {
                  display: inline-block;
                  width: 0.9em;
                }
              }
            }
          }
        }
      }
    }
  }
  .trends_card:nth-child(1) {
    .tg_item_body {
      background: linear-gradient(
        180deg,
        rgba(237, 220, 253, 1) 1%,
        rgba(233, 210, 255, 1) 58%,
        rgba(243, 230, 255, 1) 98%
      );
    }
  }
  .trends_card:nth-child(2) {
    .tg_item_body {
      background: linear-gradient(
        180deg,
        rgba(255, 222, 172, 1) 1%,
        rgba(255, 245, 222, 1) 61%,
        rgba(255, 242, 210, 1) 100%
      );
    }
  }
  .trends_card:nth-child(3) {
    .tg_item_body {
      background: linear-gradient(
        180deg,
        rgba(247, 204, 212, 1) 3%,
        rgba(255, 225, 215, 1) 58%,
        rgba(255, 241, 246, 1) 99%
      );
    }
  }
}
.sub_container {
  margin-top: 3%;
  text-align: center;
  font-size: 1.5rem;
  color: rgba(38, 51, 95, 1);
  font-weight: bold;
  letter-spacing: 3px;
  .sub_container_item {
    background: linear-gradient(to bottom, transparent 50%, #fff1c8 40%);
  }
  margin-bottom: 2.5%;
}
.bH {
  background-color: #f7f8f9;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-position: top;
  background-size: auto 400px;
  background-repeat: no-repeat;
  .bh_left {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 400px;

    height: 400px;
  }
  .bh_center {
    position: absolute;
    top: -10%;
    left: 0%;
    width: 100%;
  }
  .bh_right {
    position: absolute;
    top: 0px;
    right: -20px;
    height: 400px;
    width: 200px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .body {
    max-width: 1280px;
    margin: 0px auto;
    position: relative;
    padding-top: 3.75rem;
    padding-bottom: 2rem;
    z-index: 1;
    background: transparent;
    .title {
      font-size: 3.25rem;
      text-align: center;
      color: #111925;
      font-weight: bolder;
      margin: 0px auto;
      letter-spacing: 8px;
    }
    .title1 {
      display: none !important;
    }
    .sub_title {
      margin: 0px auto;
      margin-top: 32px;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      color: #111925;
      font-weight: bolder;
      width: 90%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      .email {
        text-decoration: underline;
        color: #4a8ee9;
      }
      .email:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #4a8ee9;
      }
    }
    .sub_title2 {
      display: none;
      .email {
        text-decoration: underline;
        color: #4a8ee9;
      }
      .email:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #4a8ee9;
      }
    }
    .desc {
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: rgba(15, 25, 37, 0.65);
      text-align: center;
      width: 90%;
      margin: 0px auto;
      margin-top: 2em;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
}

/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .bH {
    max-height: 542px;
    padding-top: 80px;
    .body {
      max-height: 462px;
      .desc2 {
        display: none;
      }
      .desc_show_btn {
        display: none;
      }

      .sub_container1 {
        display: none !important;
      }
    }
  }
}

// ipad app
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .bH {
    max-height: 522px;

    padding-top: 60px;
    .body {
      max-height: 462px;
      .desc2 {
        display: none;
      }
      .desc_show_btn {
        display: none;
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .bH {
    .bh_left {
      display: none;
    }
    .bh_right {
      display: none;
    }
    .body {
      .title {
        letter-spacing: 1px;
        font-size: 1.4rem;
      }
    }
  }

  .sub_container {
    font-size: 1.13rem;
  }

  .tg_body {
    padding: 0px 10px;
    .trends_row {
      .trends_card {
        width: calc(33.333333% - 0px);
        padding-left: 0px;
        padding-right: 0px;
        .tg_item_body {
          width: 88%;
          padding: 20px 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .bH {
    .bh_left {
      display: none;
    }
    .bh_right {
      display: none;
    }
    .body {
      .title {
        letter-spacing: 1px;
        font-size: 1.1rem;
        display: none !important;
      }
      .title1 {
        font-size: 1.5rem;
        text-align: center;
        color: #111925;
        font-weight: bolder;
        margin: 0px auto;
        letter-spacing: 8px;
        display: block !important;
        letter-spacing: 1px;
      }
    }
  }

  .sub_container {
    display: none !important;
  }

  .sub_container1 {
    margin-top: 3%;
    font-size: 1rem;
    letter-spacing: 0.1px;
    padding: 0px 13px;
    text-align: center;
    font-weight: bold;
    .sub_container_item {
      background: linear-gradient(to bottom, transparent 50%, #fff1c8 40%);
    }
  }

  .tg_body {
    padding: 0px 0px;
    .trends_row {
      .trends_card {
        width: 100%;
      }
    }
  }

  .tg_body {
    .trends_row {
      .trends_card {
        .tg_item_body {
          .tg_item_bottom {
            .content_head {
              .title {
                .biaoti {
                  display: flex;
                  align-items: center;
                  .yuan {
                    display: inline-block;
                    width: 1.4em;
                    font-size: 0.6em;
                    vertical-align: middle; /* 垂直居中 */
                  }
                }
              }
            }
            .content_bottom {
              display: grid;
              .title {
                .biaoti {
                  display: flex;
                  align-items: center;
                  .yuan {
                    display: inline-block;
                    width: 1.4em;
                    font-size: 0.6em;
                    vertical-align: middle; /* 垂直居中 */
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .rightAligned {
    z-index: 999;
    position: fixed;
    right: 10px; /* 这将元素定位到最右边 */
    top: 350px; /* 根据需要调整，这里是定位到最顶部 */
    .image1 {
      width: 75px;
      height: 50px;
    }
    .image2 {
      width: 100px;
      height: 100px;
      border-radius: 7px;
    }
    .button1 {
      display: none;
      font-weight: bold;
    }
    .button2 {
      margin-top: 1px;
      font-weight: bold;
      width: 100px;
      height: 22px;
      line-height: 17px;
      border-radius: 14px;
      background: linear-gradient(
        96.62deg,
        rgba(238, 75, 55, 1) 6.4%,
        rgba(222, 165, 79, 1) 95.57%
      );
      color: rgba(247, 248, 250, 1);
      font-size: 10px;
      text-align: center;
      font-family: Roboto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>