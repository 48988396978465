<template>
  <div>
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>
    <van-form
      ><van-field
        readonly
        clickable
        required
        :value="form.startTime"
        label="推广开始时间"
        placeholder="请选择推广开始时间"
        @click="startTimeShow = true"
      />
      <van-popup v-model="startTimeShow" position="bottom">
        <van-datetime-picker
          :min-date="startDefaultTime.min()"
          :max-date="startDefaultTime.max()"
          type="datetime"
          @confirm="
            (v) => {
              const date = new Date(v);
              const year = date.getFullYear();
              const month = date.getMonth() + 1; // 月份是从0开始的
              const day = date.getDate();
              const hour = date.getHours();
              const minute = date.getMinutes();

              // 格式化输出
              const formattedDate = `${year}-${month
                .toString()
                .padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hour
                .toString()
                .padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
              form.startTime = formattedDate;
              startTimeShow = false;
            }
          "
          @cancel="startTimeShow = false"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        required
        :value="form.endTime"
        label="推广结束时间"
        placeholder="请选择推广结束时间"
        @click="endTimeShow = true"
      />

      <van-popup v-model="endTimeShow" position="bottom">
        <van-datetime-picker
          :min-date="endDefaultTime.min()"
          :max-date="endDefaultTime.max()"
          type="datetime"
          @cancel="endTimeShow = false"
          @confirm="
            (v) => {
              const date = new Date(v);
              const year = date.getFullYear();
              const month = date.getMonth() + 1; // 月份是从0开始的
              const day = date.getDate();
              const hour = date.getHours();
              const minute = date.getMinutes();

              // 格式化输出
              const formattedDate = `${year}-${month
                .toString()
                .padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hour
                .toString()
                .padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
              form.endTime = formattedDate;
              endTimeShow = false;
            }
          "
        />
      </van-popup>
      <van-field
        required
        readonly
        clickable
        label="推广活动"
        placeholder="请选择推广活动"
        @click="huodongShow = true"
      >
        <template #input>
          <div v-if="selectedLabels.length > 0">
            {{ selectedLabels.join(", ") }}
          </div>
          <div v-else><span style="color: #c4c9cf">请选择推广活动</span></div>
        </template>
      </van-field>
      <van-popup v-model="huodongShow" position="bottom" style="height: 35%">
        <van-checkbox-group v-model="form.huodong">
          <van-checkbox
            style="margin: 10px"
            shape="square"
            v-for="option in options"
            :name="option.value"
            :key="option.value"
          >
            <span>{{ option.label }}</span>
          </van-checkbox>
        </van-checkbox-group>
      </van-popup>
      <van-field
        required
        v-model="form.companyName"
        label="企业名称"
        placeholder="请输入企业名称"
      />
      <van-field
        required
        v-model="form.shopAddress"
        label="门店地址"
        placeholder="请输入门店地址"
      />
      <van-field
        required
        v-model="form.comment"
        label="业务简介"
        placeholder="请输入门店业务简介，如：甜品店"
      />

      <div style="margin: 23px">
        <van-button
          round
          block
          type="danger"
          native-type="submit"
          @click="next"
          style="font-weight: bold"
          >下一步</van-button
        >
      </div>
    </van-form>
    <van-popup
      v-model="showTwo"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <standardTwo
        :title="title"
        :indexType="index"
        :parentData="form"
        @custom-event="onClickSubmit"
        @custom-event-hidetwo="onClickHideTwo"
      ></standardTwo>
    </van-popup>
  </div>
</template>
<script>
import trends from "@/components/home/trends.vue";
import standardTwo from "@/views/appAttractUsers/standard_two.vue";
export default {
  components: {
    trends,
    standardTwo,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      startDefaultTime: {
        min: () => {
          const starttime = new Date().getTime() - 0 * (1000 * 60 * 60 * 24);
          let date = new Date(starttime + 0 * (1000 * 60 * 60 * 24));
          let dateWithoutTime = new Date(date.setHours(0, 0, 0, 0));
          return dateWithoutTime;
        },
        max: () => {
          const starttime = new Date().getTime() - 0 * (1000 * 60 * 60 * 24);
          let date = new Date(starttime + 180 * (1000 * 60 * 60 * 24));
          let dateWithoutTime = new Date(date.setHours(0, 0, 0, 0));
          return dateWithoutTime;
        },
      },
      endDefaultTime: {
        min: () => {
          const starttime = new Date().getTime() - 0 * (1000 * 60 * 60 * 24);
          let date = new Date(starttime + 0 * (1000 * 60 * 60 * 24));
          let dateWithoutTime = new Date(date.setHours(23, 59, 59, 0));
          return dateWithoutTime;
        },
        max: () => {
          const starttime = new Date().getTime() - 0 * (1000 * 60 * 60 * 24);
          let date = new Date(starttime + 180 * (1000 * 60 * 60 * 24));
          let dateWithoutTime = new Date(date.setHours(23, 59, 59, 0));
          return dateWithoutTime;
        },
      },
      show: true,
      showTwo: false,
      startTimeShow: false,
      endTimeShow: false,
      huodongShow: false,
      options: [
        {
          value: "1",
          label: "新店体验",
        },
        {
          value: "2",
          label: "新品体验",
        },
        {
          value: "3",
          label: "节假日活动",
        },
        {
          value: "4",
          label: "数字营销",
        },
        {
          value: "5",
          label: "口碑传播",
        },
        {
          value: "6",
          label: "获客消费",
        },
      ],
      appShow: true,
      form: {
        startTime: null,
        endTime: null,
        huodong: [],
        companyName: "",
        shopAddress: "",
        comment: "",
      },
    };
  },
  watch: {},
  computed: {
    baseUrl() {
      if (location.href.indexOf("www.darenlaiye.com") > 0) {
        return "https://e.darenlaiye.com/application/api";
      }
      return "https://e_test.darenlaiye.com/application/api";
    },
    selectedLabels() {
      return this.options
        .filter(
          (option) =>
            this.form.huodong && this.form.huodong.includes(option.value)
        )
        .map((option) => option.label);
    },
  },
  methods: {
    next() {
      if (!this.form.startTime) {
        this.$toast("请选择推广开始时间");
        return;
      }
      if (!this.form.endTime) {
        this.$toast("请选择推广结束时间");
        return;
      }
      if (this.form.startTime > this.form.endTime) {
        this.$message.error("推广周期开始时间不能大于结束时间");
        return false;
      }
      const date1 = new Date(this.form.startTime);
      const date2 = new Date(this.form.endTime);
      const oneDay = 24 * 60 * 60 * 1000; // 每天的毫秒数
      const daysDifference = Math.abs((date2 - date1) / oneDay);
      if (daysDifference < 29) {
        this.$toast("推广周期至少间隔30天");
        return false;
      }

      if (this.form.huodong.length == 0) {
        this.$toast("请选择推广活动");
        return;
      }
      if (!this.form.companyName) {
        this.$toast("请输入企业名称");
        return;
      } else {
        this.form.companyName = this.form.companyName.replace(/\s+/g, "");
        if (!this.form.companyName) {
          this.$message.error("请输入企业名称");
          return false;
        }
      }
      if (!this.form.shopAddress) {
        this.$toast("请输入门店地址");
        return;
      } else {
        this.form.shopAddress = this.form.shopAddress.replace(/\s+/g, "");
        if (!this.form.shopAddress) {
          this.$message.error("请输入门店地址");
          return false;
        }
      }
      if (!this.form.comment) {
        this.$toast("请输入门店业务简介，如：甜品店");
        return;
      } else {
        this.form.comment = this.form.comment.replace(/\s+/g, "");
        if (!this.form.comment) {
          this.$message.error("请输入业务简介");
          return false;
        }
      }
      this.showTwo = true;
    },
    onClickLeft() {
      this.$emit("custom-event");
    },
    onClickHideTwo() {
      this.showTwo = false;
    },
    onClickSubmit() {
      this.showTwo = false;
      this.$emit("custom-event");
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='less' scoped>
/deep/ .el-dialog__header {
  border-bottom: 1px solid #eee;
}
/deep/ .el-dialog__title {
  font-weight: bold;
  color: #000;
}
/deep/ .el-form-item__label {
  font-weight: bold;
  color: #000;
}
/deep/ .el-popup-parent--hidden {
  padding-right: 0px !important;
  overflow: hidden !important;
}
</style>