<template>
  <div>
    <div>
      <div class="rightAligned">
        <div style="display: grid; place-items: center">
          <img class="image1" :src="require('@/assets/dingwei1.png')" />
        </div>
        <div>
          <el-image
            class="image2"
            :src="require('@/assets/dingwei2.jpg')"
            :preview-src-list="[require('@/assets/dingwei2.jpg')]"
            fit="contain"
          />
        </div>
        <div class="button1">扫描了解大人来也</div>
        <div class="button2" @click="liaojie" style="font-weight: bold">
          点击了解大人来也
        </div>
      </div>
    </div>
    <div class="trends">
      <div class="head">
        <div class="G">资讯动态</div>
        <div
          class="H"
          style="cursor: pointer"
          @click="
            () => {
              $emit('showAll');
            }
          "
        >
          查看全部<i class="el-icon-arrow-right"></i>
        </div>
      </div>

      <div class="body">
        <div class="trends_row">
          <trends-card
            v-if="data[0]"
            :data="data[0]"
            :image="data[0].headImageMax"
          />
          <trends-card
            v-if="data[1]"
            :data="data[1]"
            :image="data[1].headImage"
          />
        </div>
        <div class="trends_row">
          <trends-card
            v-if="data[2]"
            :data="data[2]"
            :image="data[2].headImage"
          />
          <trends-card
            v-if="data[3]"
            :data="data[3]"
            :image="data[3].headImage"
          />
          <trends-card
            v-if="data[4]"
            :data="data[4]"
            :image="data[4].headImage"
          />
        </div>
        <div class="trends_row">
          <trends-card
            v-if="data[5]"
            :data="data[5]"
            :image="data[5].headImage"
          />
          <trends-card
            v-if="data[6]"
            :data="data[6]"
            :image="data[6].headImage"
          />
          <trends-card
            v-if="data[7]"
            :data="data[7]"
            :image="data[7].headImage"
          />
        </div>
      </div>

      <div class="footer">
        <div
          class="C"
          style="cursor: pointer"
          @click="
            () => {
              $emit('showAll');
            }
          "
        >
          查看全部<i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import trends_card from "./trends_card.vue";
export default {
  components: {
    "trends-card": trends_card,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    liaojie() {
      window.open("https://yygj.darenlaiye.com/tuiguang2/index.html");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang='less' scoped>
.rightAligned {
  z-index: 999;
  position: fixed;
  right: 10px; /* 这将元素定位到最右边 */
  top: 350px; /* 根据需要调整，这里是定位到最顶部 */
  .image1 {
    width: 110px;
    height: 75px;
  }
  .image2 {
    width: 150px;
    height: 150px;
    border-radius: 7px;
  }
  .button2 {
    display: none;
    font-weight: bold;
  }
  .button1 {
    margin-top: 2px;
    font-weight: bold;
    width: 150px;
    height: 30px;
    line-height: 17px;
    border-radius: 38px;
    background: linear-gradient(
      96.62deg,
      rgba(238, 75, 55, 1) 6.4%,
      rgba(222, 165, 79, 1) 95.57%
    );
    color: rgba(247, 248, 250, 1);
    font-size: 12px;
    text-align: center;
    font-family: Roboto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.trends {
  max-width: 1280px;
  padding: 0px 20px;
  margin: 0px auto;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    padding-top: 3px;
    padding-bottom: 18px;
  }

  .body {
    .trends_row:nth-child(1) {
      .trends_card:nth-child(1) {
        width: calc(66.666667% - 20px);
      }
    }
    .trends_row {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      .trends_card {
        width: calc(33.333333% - 20px);
      }
    }
  }
  .footer {
    .C {
      cursor: pointer;
    }
  }
}
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .head {
    .G {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
    .H {
      border-color: rgba(17, 25, 37, 0.2);
      border-radius: 0.375rem;
      border-width: 1px;
      border-style: solid;

      height: 2rem;
      width: 6rem;
      line-height: 2rem;

      font-size: 0.875rem;
      padding-left: 4px;
      text-align: center;
      i {
        margin-left: 3px;
        font-size: 0.7rem;
      }
    }
  }

  .footer {
    padding-top: 2.5rem;
    padding-bottom: 6.25rem;
    .C {
      height: 48px;
      width: 138px;
      border-color: rgba(17, 25, 37, 0.2);
      border-radius: 0.5rem;
      border-width: 1px;
      color: #111925;
      border-style: solid;
      font-size: 1.25rem;
      margin: 0px auto;
      padding-left: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        margin-top: 5px;
        margin-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .head {
    .G {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
    .H {
      border-color: rgba(17, 25, 37, 0.2);
      border-radius: 0.375rem;
      border-width: 1px;
      border-style: solid;

      height: 2rem;
      width: 6rem;
      line-height: 2rem;

      font-size: 0.875rem;
      padding-left: 4px;

      text-align: center;
      i {
        margin-left: 3px;
        font-size: 0.7rem;
      }
    }
  }

  .footer {
    padding-top: 2.5rem;
    padding-bottom: 6.25rem;
    .C {
      height: 48px;
      width: 138px;
      border-color: rgba(17, 25, 37, 0.2);
      border-radius: 0.5rem;
      border-width: 1px;
      color: #111925;
      border-style: solid;
      font-size: 1.25rem;
      margin: 0px auto;
      padding-left: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        margin-top: 5px;
        margin-left: 5px;
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .head {
    .G {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.25rem;
    }
    .H {
      border-color: rgba(17, 25, 37, 0.2);
      border-radius: 0.375rem;
      border-width: 1px;
      border-style: solid;
      height: 1.5rem;
      width: 74px;
      line-height: 1.5rem;

      font-size: 0.75rem;
      padding-left: 4px;

      text-align: center;
      i {
        margin-left: 3px;
        font-size: 0.7rem;
      }
    }
  }

  .footer {
    padding-top: 2.5rem;
    padding-bottom: 6.25rem;
    .C {
      height: 48px;
      width: 138px;
      border-color: rgba(17, 25, 37, 0.2);
      border-radius: 0.5rem;
      border-width: 1px;
      color: #111925;
      border-style: solid;
      font-size: 1.25rem;
      margin: 0px auto;
      padding-left: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        margin-top: 5px;
        margin-left: 5px;
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 770px) {
  .body {
    .trends_row {
      justify-content: center;
      .trends_card {
        width: 100% !important;
      }
    }
  }

  .footer {
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
    .C {
      height: 30px;
      width: 92px;
      border-color: rgba(17, 25, 37, 0.2);
      border-radius: 0.25rem;
      border-width: 1px;
      color: #111925;
      border-style: solid;
      font-size: 0.875rem;
      margin: 0px auto;
      padding-left: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        margin-top: 4px;
        margin-left: 5px;
      }
    }
  }

  .rightAligned {
    z-index: 999;
    position: fixed;
    right: 10px; /* 这将元素定位到最右边 */
    top: 350px; /* 根据需要调整，这里是定位到最顶部 */
    .image1 {
      width: 75px;
      height: 50px;
    }
    .image2 {
      width: 100px;
      height: 100px;
      border-radius: 7px;
    }
    .button1 {
      display: none;
      font-weight: bold;
    }
    .button2 {
      margin-top: 1px;
      font-weight: bold;
      width: 100px;
      height: 22px;
      line-height: 17px;
      border-radius: 14px;
      background: linear-gradient(
        96.62deg,
        rgba(238, 75, 55, 1) 6.4%,
        rgba(222, 165, 79, 1) 95.57%
      );
      color: rgba(247, 248, 250, 1);
      font-size: 10px;
      text-align: center;
      font-family: Roboto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>