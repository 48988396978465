<template>
  <div class="drly_article">
    <div class="body" v-if="data">
      <div v-if="src">
        <el-image :src="src" style="width: 100%" fit="cover"></el-image>
      </div>

      <div class="content" v-html="data.contentLink"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  props: {},
  data() {
    return {
      src: null,
      data: null,
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {
    this.src = this.$route.params.data.headImageMax;

    this.data = this.$route.params.data;
  },
};
</script>
<style lang='less' >
@media screen and (min-width: 1280px) {
  .drly_article {
    margin-top: 80px;
    .body {
      width: 100%;
      max-width: 1280px;
      margin: 0px auto;
      .content {
        color: #111925;
        padding: 0px 2rem;
        .h1 {
          font-size: 2rem;
          line-height: 1.6;
        }
        .blank_row {
          height: 1.5rem;
        }
        .text {
          font-size: 1.2rem;
          line-height: 1.6;
        }
      }
    }
  }
}
@media screen and (max-width: 1279px) {
  .drly_article {
    margin-top: 60px;
    .body {
      width: 100%;
      max-width: 1280px;
      margin: 0px auto;
      .content {
        color: #111925;
        padding: 0px 2rem;
        .h1 {
          font-size: 2rem;
          line-height: 1.6;
        }
        .blank_row {
          height: 1.5rem;
        }
        .text {
          font-size: 1rem;
          line-height: 1.6;
        }
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .drly_article {
    margin-top: 60px;
    .body {
      width: 100%;
      max-width: 1280px;
      margin: 0px auto;
      .content {
        color: #111925;
        padding: 0px 1.2rem;
        .h1 {
          font-size: 1.2rem;
          line-height: 1.6;
        }
        .blank_row {
          height: 0.9rem;
        }
        .text {
          font-size: 0.725rem;
          line-height: 1.6;
        }
      }
    }
  }
}
</style>