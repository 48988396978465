<template>
  <div>

    <el-dialog
      :title="contract.step == 0 ? '电子合同信息' : '电子合同'"
      :visible.sync="contract.dialogVisible"
      :width="contract.step == 0 ? '700px' : '80%'"
      append-to-body
      class="demo-ruleForm"
      :close-on-click-modal="false"
    >
      <el-form
        v-if="contract.step == 0"
        :model="contract.ruleForm"
        :rules="contract.rules"
        ref="contractForm"
        label-width="100px"
      >
        <el-form-item
          label="公司名称"
          prop="compName"
        >
          <el-input v-model="contract.ruleForm.compName"></el-input>
        </el-form-item>
        <el-form-item
          label="公司地址"
          prop="compAddr"
        >
          <el-input v-model="contract.ruleForm.compAddr"></el-input>
        </el-form-item>
        <el-form-item
          label="联系人"
          prop="name"
        >
          <el-input v-model="contract.ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item
          label="电话"
          prop="tel"
        >
          <el-input v-model="contract.ruleForm.tel"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="() => {contract.dialogVisible = false}">取消</el-button>
          <el-button
            type="primary"
            @click="contract.submitForm('contractForm')"
          >生成合同（预）</el-button>
        </el-form-item>
      </el-form>
      <div v-else-if="contract.step == 1">
        <div class="keynote lineHeight2">
          合同编号：预览合同
        </div>
        <div style="height: 15px"></div>
        <div class="keynote lineHeight2">
          <div>
            甲方： {{ contract.ruleForm.compName }}
          </div>
          <div>
            地址：
            {{ contract.ruleForm.compAddr }}
          </div>
          <div>
            联系人：
            {{ contract.ruleForm.name }}
          </div>
          <div>
            联系电话：
            {{ contract.ruleForm.tel }}
          </div>
        </div>

        <div style="height: 15px"></div>
        <div class="keynote lineHeight2">
          <div>乙方：{{ this.huami.companyName2 }}</div>
          <div>地址：{{ this.huami.companyAddr2 }}</div>
          <div>联系人：何女士</div>
          <div>联系电话：021-64483509</div>
        </div>
        <div style="height: 15px"></div>
        <div class="keynote lineHeight2">
          一、甲方通过账号 {{ contract.ruleForm.compName }}
          订购“大人来也商户端”提供的如下产品/服务：
        </div>

        <div style="height: 15px"></div>
        <table
          id="contract_order_table"
          v-if="contract.itemList"
        >
          <tr>
            <td class="contract_order_table_col">
              <div>序号</div>
            </td>

            <td class="contract_order_table_col">
              <div>服务名称</div>
            </td>
            <td class="contract_order_table_col">
              <div>服务描述</div>
            </td>
            <td class="contract_order_table_col">
              <div>服务期限</div>
            </td>
            <td class="contract_order_table_col">
              <div>服务费用</div>
            </td>
            <td class="contract_order_table_col">
              <div>预付金额</div>
            </td>
          </tr>

          <tr
            v-for="(item, index) in contract.itemList"
            :key="'itemList' + index"
          >
            <td class="contract_order_table_col">
              <div>{{ index + 1 }}</div>
            </td>

            <td class="contract_order_table_col">
              <div>{{ item.serviceName }}</div>
            </td>
            <td class="contract_order_table_col">
              <div v-html="item.serviceDesc"></div>
            </td>
            <td class="contract_order_table_col">
              <div>{{ item.serviceCycle }}</div>
            </td>
            <td class="contract_order_table_col">
              <div>{{ Number(item.serviceAmount).toFixed(2) }}</div>
            </td>
            <td class="contract_order_table_col">
              <div>{{ Number(item.advanceAmout).toFixed(2) }}</div>
            </td>
          </tr>

          <tr>
            <td class="contract_order_table_col">
              <div>总计</div>
            </td>

            <td
              colspan="5"
              class="contract_order_table_col"
            >
              <div
                class="keynote"
                style="text-align: left;"
              >
                预付费：{{ $strToMoney(clacTotalMoney) }}元
              </div>
            </td>
          </tr>
        </table>

        <div style="height: 15px"></div>
        <div class="lineHeight2">
          二、甲方付费之后，“大人来也商户端”才开始为甲方提供调研任务服务，甲方未完成付费，则甲方与“大人来也商户端”就服务所达成的一切行为失效。
        </div>
        <div class="lineHeight2">
          三、“大人来也商户端”保留甲方未按照约定支付全部费用之前不向甲方提供服务和/或技术支持，或者终止服务和/或技术支持的权利。
        </div>
        <div class="lineHeight2">
          四、甲方应将款项支付至“大人来也商户端”指定的如下账号 ： <br />
          <span style="display: inline-block; width: 2em"></span>开户名称：{{ this.huami.companyName2 }} <br />
          <span style="display: inline-block; width: 2em"></span>开户行：{{this.huami.kaiHuHang}} <br />
          <span style="display: inline-block; width: 2em"></span>账号：{{this.huami.yinHangZhang}}<br />
        </div>
        <div class="lineHeight2">
          五、发票：登录“大人来也商户端”点击【我的钱包】-【开票记录】-【新增发票】，提交申请开具电子发票，开票成功后，可下载到本地存档。
        </div>
        <div class="lineHeight2">
          六、免责说明<br />
          1、账单准确性免责 <br />
          <span style="display: inline-block; width: 2em"></span>乙方将尽最大努力确保提供付费后账单内容准确、完整，但由于各种可能存在的客观因素（如系统故障、人为错误等），账单中可能存在误差或遗漏。甲方在收到账单后，应自行核对并确认账单内容。如因账单内容不准确或遗漏导致的任何损失或纠纷，乙方不承担直接或间接的责任。<br />
          2、争议解决免责 <br />
          <span style="display: inline-block; width: 2em"></span>如双方在执行合同过程中因账单问题发生争议，应首先通过友好协商解决。协商不成的，任何一方均有权向乙方所在地人民法院提起诉讼。在争议解决期间，乙方不承担因争议导致的任何直接或间接损失的责任。<br />
          3、其他免责事项 <br />
          <span style="display: inline-block; width: 2em"></span>a.乙方对账单的传输、存储或处理过程中可能发生的任何延误、中断或错误不承担责任。
          <br />
          <span style="display: inline-block; width: 2em"></span>b.乙方对甲方因依赖账单内容而做出的任何决策或行动不承担责任。甲方应自行评估账单内容的真实性和准确性，并承担由此产生的所有风险。
        </div>
        <div class="lineHeight2">
          七、附则 <br />
          <span style="display: inline-block; width: 2em"></span>
          本免责说明作为合同结算以付费后账单为准条款的补充，与合同其他条款具有同等法律效力。双方应共同遵守并履行本免责说明中的各项规定。请甲方在签署合同前仔细阅读并理解本免责说明的内容，如有疑问或异议，请及时与乙方联系沟通。
        </div>

        <div style="height: 15px"></div>
        <table id="sign_table">
          <tr>
            <td>
              <div
                class="keynote"
                style="text-align: center"
              >
                甲方： {{ contract.ruleForm.compName }}
              </div>
            </td>
            <td>
              <div
                class="keynote"
                style="text-align: center"
              >
                乙方：{{ this.huami.companyName2 }}
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div style="height: 300px">
                <span class="keynote">（盖章处）</span>
              </div>
            </td>
            <td>
              <div style="height: 300px">
                <span class="keynote">（盖章处）</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="keynote">
                日期：{{timestampToDatetime(new
								Date().getTime()).substring(0,10).replace("-","年").replace("-","月")+"日"}}
              </div>
            </td>
            <td>
              <div class="keynote">
                日期：{{timestampToDatetime(new
								Date().getTime()).substring(0,10).replace("-","年").replace("-","月")+"日"}}
              </div>
            </td>
          </tr>
        </table>
        <div style="height: 15px"></div>
        <div class="keynote lineHeight2">
          本合同项下的所有费用结算，均以甲方付款后的账单为准。<br />请甲方务必关注并认真核对账单内容，确保无误后完成付款。
        </div>
        <div style="margin-top: 30px; text-align: center">
          <el-button
            type="primary"
            :loading="downloadLoading"
            @click="download"
          >
            下载电子合同（预）
          </el-button>
        </div>

      </div>
    </el-dialog>

    <el-dialog
      class="gjDialog"
      title="调研估价"
      :visible.sync="dialogVisible"
      width="740px"
      :close-on-click-modal="Boolean(false)"
      top="15px"
      @close="close"
    >
      <div
        class="title"
        v-if="step == 1"
      >
        <div class="title_prefix"></div>
        <div class="title_context">基础服务</div>
      </div>
      <div
        class="title"
        v-else-if="step == 2"
      >
        <div class="title_prefix"></div>
        <div class="title_context">增值服务（按需勾选）</div>
      </div>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="130px"
        class="demo-ruleForm"
        v-if="step == 1"
      >
        <el-form-item
          label="任务名称："
          prop="taskName"
          style="display: flex;justify-content: start;"
        >
          <el-input
            v-model="ruleForm.taskName"
            placeholder="请输入任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label=" "
          prop=""
        >
          <div class="infoDetails">任务名称请清晰表达，建议商户品牌+任务类型+时间”如：大人来也消费者体验2024年8月任务</div>
        </el-form-item>
        <el-form-item
          label="样本数："
          prop="num"
          style="display: flex;justify-content: start;"
        >
          <el-input
            v-model="ruleForm.num"
            @input="v => ruleForm.num = v.replace(/[^\d]/g,'')"
          ></el-input>&nbsp;个
        </el-form-item>
        <el-form-item
          label=" "
          prop=""
        >
          <div class="infoDetails">调研样本数，如：5家店，每月调研2次，10个样本</div>
        </el-form-item>
        <el-form-item
          label="调研周期："
          prop="date"
          style="display: flex;justify-content: start;"
        >
          <el-date-picker
            v-model="ruleForm.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label=" "
          prop=""
        >
          <div class="infoDetails">设置调研周期，建议以月为周期整体</div>
        </el-form-item>
        <el-form-item
          label="单样本报酬："
          prop="money"
          style="display: flex;justify-content: start;"
        >
          <el-input
            v-model="ruleForm.money"
            placeholder="最少25元"
            @input="v => ruleForm.money = v.replace(/[^\d]/g,'')"
          ></el-input>&nbsp;元
        </el-form-item>
        <el-form-item
          label=" "
          prop=""
        >
          <div class="infoDetails">支付给调研用户的酬劳，建议人均消费x要求的同行人数，如：人均消费89元，要求两人同行，建议酬劳（含餐费）不低于180元</div>
        </el-form-item>

        <div v-for="(item,index) in taskList">
          <el-form-item
            label="任务名称"
            prop="taskName"
            style="display: flex;justify-content: start;"
          >
            <template slot="label">
              <el-link
                :underline="false"
                type="primary"
                @click="()=>{
								taskList.splice(index, 1)
							}"
              ><i class="el-icon-remove"></i></el-link>
              任务名称：
            </template>
            <el-input
              v-model="item.taskName"
              placeholder="请输入任务名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=" "
            prop=""
          >
            <div class="infoDetails">任务名称请清晰表达，建议商户品牌+任务类型+时间”如：大人来也消费者体验 2024 年 8月任务</div>
          </el-form-item>
          <el-form-item
            label="样本数："
            prop="num"
            style="display: flex;justify-content: start;"
          >
            <el-input
              v-model="item.num"
              @input="v => item.num = v.replace(/[^\d]/g,'')"
            ></el-input>&nbsp;个
          </el-form-item>
          <el-form-item
            label=" "
            prop=""
          >
            <div class="infoDetails">调研样本数，如：5家店，每月调研2次，10个样本</div>
          </el-form-item>
          <el-form-item
            label="调研周期："
            prop="date"
            style="display: flex;justify-content: start;"
          >
            <el-date-picker
              v-model="item.date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label=" "
            prop=""
          >
            <div class="infoDetails">设置调研周期，建议以月为周期整体</div>
          </el-form-item>
          <el-form-item
            label="单样本报酬："
            prop="money"
            style="display: flex;justify-content: start;"
          >
            <el-input
              v-model="item.money"
              placeholder="最少25元"
              @input="v => item.money = v.replace(/[^\d]/g,'')"
            ></el-input>&nbsp;元
          </el-form-item>
          <el-form-item
            label=" "
            prop=""
          >
            <div class="infoDetails">支付给调研用户的酬劳，建议人均消费x要求的同行人数，如：人均消费89元，要求两人同行，建议酬劳（含餐费）不低于180元</div>
          </el-form-item>
        </div>
      </el-form>

      <div
        style="margin: 0px 27px;"
        v-else-if="step == 2"
      >

        <div style="display: flex;justify-content: space-between;align-items: center;">
          <el-checkbox
            label="tape"
            v-model="incrementServerCheckList[0]"
            :disabled="isShowGuSuan"
            @change="incrementServerChange"
            style="color: #333;"
          >录音</el-checkbox>
          <div style="padding: 5px;background-color: #F5F5F5;width: calc(100% - 140px);    border-radius: 5px;">
            30元/单。问卷包含录音题及相关辅助审核服务。</div>
        </div>
        <div style="height: 20px;"></div>
        <div style="display: flex;justify-content: space-between;align-items: center;"><el-checkbox
            :disabled="isShowGuSuan"
            label="appeal"
            v-model="incrementServerCheckList[1]"
            @change="incrementServerChange"
            style="color: #333;"
          >申诉

          </el-checkbox>
          <div style="padding: 5px;background-color: #F5F5F5;width: calc(100% - 140px);    border-radius: 5px;">
            10元/单。提供申诉工作流，门店申诉，总部统一管理。</div>
        </div>
        <div style="height: 20px;"></div>
        <div style="display: flex;justify-content: space-between;align-items: center;"><el-checkbox
            :disabled="isShowGuSuan"
            label="promotion"
            v-model="incrementServerCheckList[2]"
            @change="incrementServerChange"
            style="color: #333;"
          >任务推广

          </el-checkbox>
          <div style="padding: 5px;background-color: #F5F5F5;width: calc(100% - 140px);    border-radius: 5px;">
            1）30元/单（适用于单样本费用≤100元）。<br />2）收取单样本费用的30%，最高不超过200元（适用单样本费用＞100元）。 <br />
            包含：任务实施过程中的短信、人工推广、调研培训等，任务完成率超过99%。</div>
        </div>
        <div style="height: 20px;"></div>
        <el-checkbox
          :disabled="isShowGuSuan"
          v-model="incrementServerNotCheck"
          @change="incrementServerChange"
          style="color: #333;"
        >以上暂不需要
        </el-checkbox>

      </div>
      <div
        class="row"
        v-if="isShowGuSuan"
        style="margin-top: 15px;margin-bottom: 0px;"
      >
        <div style="font-weight: bolder;text-align: left;width: 85px !important;">估价结果：
        </div>
        <div>

          <span
            style="color:#f15236"
            v-if="calcIncrServerMoney"
          >基础服务估价（含税）：<span style="color:#4A8EE9">￥</span><span style="font-size: 2em;color:#4A8EE9">{{ $strToMoney(clacBaseMoney)}}</span><span style="color:#4A8EE9">元</span><br /></span>
          <span
            style="color:#f15236"
            v-if="calcIncrServerMoney"
          >增值服务估价（含税）：<span style="color:#4A8EE9">￥</span><span style="font-size: 2em;color:#4A8EE9">{{     $strToMoney(calcIncrServerMoney)}}</span><span style="color:#4A8EE9">元</span><br /></span>

          <span style="color:#f15236">总估价费用（含税）：<span style="color:#4A8EE9">￥</span><span style="font-size: 2em;color:#4A8EE9">{{$strToMoney(clacTotalMoney)}}</span><span style="color:#4A8EE9">元</span></span>
          <span style="color:#f15236">，实际费用以发布调研的收费单为准。</span>
        </div>

      </div>
      <div
        class="row"
        v-else
        style="margin-top: 15px;margin-bottom: 0px;"
      >
        <div style="font-weight: bolder;text-align: left;width: 85px !important;">估价结果：</div>
        <div>
          <span style="color:#f15236">您未开始估价，请填写您的需求，快速获取执行可行性调研估价（已含平台服务费）。</span>
        </div>
      </div>
      <div
        v-if="!isShowGuSuan && step == 2"
        style="text-align: center;"
      >
        <el-button
          type="primary"
          style="    background: #f15236;zoom:120%;
								border-color: #f15236;"
          @click="gujiao()"
          :loading="gujiaLogding"
        >开始估价</el-button>
      </div>
      <div
        v-if="isShowGuSuan"
        style="text-align: center;"
      >
        <el-link
          type="primary"
          @click="contract.show()"
          style="color:#4A8EE9;font-weight: bolder;"
        >查看电子合同（预）</el-link>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="close()">取 消</el-button>
        <el-button
          type="primary"
          @click="stepGujiao"
          v-if="step == 1"
          style="    background: #f15236;
				border-color: #f15236;"
        >下一步</el-button>
        <el-button
          type="primary"
          @click="()=>{step = 1;isShowGuSuan = false}"
          v-if="step == 2&& !isShowGuSuan"
          style="    background: #f15236;
									border-color: #f15236;"
        >上一步</el-button>

        <el-button
          type="primary"
          v-if="isShowGuSuan"
          style="    background: #f15236;
				border-color: #f15236;"
          @click="jump"
        >去登录</el-button>
      </span>

    </el-dialog>

    <el-dialog
      class="phoneDialog"
      :title="'获取估价结果'"
      :visible.sync="showDialog"
      :width="'700px'"
      append-to-body
      :close-on-click-modal="false"
      @closed="
          () => {
            if (phone.timer) {
              clearInterval(phone.timer);
            }
          }
        "
    >
      <el-form
        :model="phone.ruleForm"
        :rules="phone.rules"
        ref="phoneForm"
        label-width="100px"
      >
        <el-form-item
          label="联系方式："
          prop="tel"
        >
          <el-input
            placeholder="请输入手机号"
            v-model="phone.ruleForm.tel"
            style="width: 435px;"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="验证码："
          prop="code"
        >
          <el-input
            placeholder="请输入短信验证码"
            v-model="phone.ruleForm.code"
            style="width: 435px;"
          >
            <template slot="append">
              <el-button
                v-if="countDownTimer"
                disabled
              >
                重新获取{{ countDownTime }}秒后</el-button>
              <el-button
                v-else
                @click="sendSmsCode"
              >
                获取短信验证码</el-button>
            </template>
          </el-input>
          <br />
        </el-form-item>
      </el-form>
      <div style="
                color: #000;
                padding: 15px;
                font-size: 14px;
                line-height: 2em;
                padding-left: 100px;
				
                padding-top: 0px;
              ">
        输入您的联系方式后，我们将通过短信把结果发送到您的手机上。
      </div>

      <div style="
                color: #000;
                padding: 15px;
                font-size: 14px;
                line-height: 2em;
                padding-left: 100px;
				
                padding-top: 0px;
              ">
        <img
          :src=" require('@/assets/home/H-Teacher.png')"
          style="width: 127px;"
        >
      </div>
      <div style="
                color: #000;
                padding: 15px;
                font-size: 14px;
                line-height: 2em;
                padding-left: 100px;
				
                padding-top: 0px;
              ">
        感谢您关注大人来也实证调研平台。
      </div>
      <div style="
                color: #000;
                padding: 15px;
                font-size: 14px;
                line-height: 2em;
                padding-left: 100px;
				
                padding-top: 0px;
              ">
        如需咨询更多，请扫码。
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="showDialog = false"
          style="padding: 8px 10px; margin-left: 10px"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="submitPhone"
          style="
              background: #f15236;
              border-color: #f15236;
              padding: 8px 10px;
              margin-left: 10px;
            "
        >确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Decimal from 'decimal.js';
export default {
  components: {},
  props: {},
  data() {
    return {
      logItems: [],
      step: 1,
      downloadLoading: false,
      taskCatch: [],
      showDialog: false,
      phoneId: "",
      ptServerNumber: 0,
      countDownTimer: false,
      countDownTime: 60, // 假设60秒
      phone: {
        ruleForm: {
          code: "",
          tel: "",
        },
        rules: {
          code: [
            { required: true, message: "请输入短信验证码", trigger: "blur" },
          ],
          tel: [
            { required: true, message: "请输入手机号", trigger: "blur" },
            {
              pattern: /^1\d{10}$/,
              message: "手机号码格式不正确",
              trigger: "blur",
            },
          ],
        },
        timer: null,
      },
      contract: {
        itemList: [],
        dialogVisible: false,
        step: 0,
        show: () => {
          if (true) {
            this.contract.ruleForm.compName = "";
            this.contract.ruleForm.compAddr = "";
            this.contract.ruleForm.name = "";
            this.contract.ruleForm.tel = "";
            this.contract.step = 0;
            this.contract.dialogVisible = true;
          }

        },
        submitForm: (formName) => {
          this.$refs[formName].validate((valid) => {
            if (valid) {

              let tape = 0;
              let appeal = 0;
              let promotion = 0;

              this.contract.step = 1;
              this.contract.itemList = [];

              let _itemList = []
              let task1Money = 0;

              console.log("开始合同");
              console.log(this.taskCatch);
              let startTime = new Date(this.ruleForm.date[0]).getTime();
              let endTime = new Date(this.ruleForm.date[1]).getTime();


              this.logItems.forEach((item) => {
                startTime = Math.min(
                  startTime,
                  new Date(item.param3).getTime()
                );
                endTime = Math.max(endTime, new Date(item.param4).getTime());

                let _single_sample_money = 0;
                let _single_sample_money_tax = 0;
                for (let i = 0; i < item.param2; i++) {

                  let m = parseInt(item.param5 * 100 / 0.7) - (item.param5 * 100);
                  m = Math.min(
                    //老逻辑
                    // Math.ceil((parseInt((item.samplePrice / 7) * 100) / 100) * 3),
                    //新逻辑
                    m,
                    Number(30000.0)
                  );
                  m += (item.param5 * 100)
                  _single_sample_money += m;
                  _single_sample_money_tax += parseInt((m * (item.param15 * 100)) / 100);

                }


                _itemList.push({
                  serviceName: `任务费用（${item.param1}）`,
                  serviceDesc: `样本数量：${item.param2}份`,
                  serviceCycle:
                    this.timestampToDatetime(new Date(item.param3).getTime()) +
                    "至" +
                    this.timestampToDatetime(new Date(item.param4).getTime()),
                  serviceAmount: this.$strToMoney(_single_sample_money),
                  advanceAmout: this.$strToMoney(_single_sample_money),
                });

                _itemList.push({
                  serviceName: `任务费用（${item.param1}）-税费`,
                  serviceDesc: `税费（6%）`,
                  serviceCycle: "",
                  serviceAmount: this.$strToMoney(_single_sample_money_tax),
                  advanceAmout: this.$strToMoney(_single_sample_money_tax),
                });

                item.param12 = 0;
                item.param13 = 0;
                item.param14 = 0;
                let param12Tax = 0;
                let param13Tax = 0;
                let param14Tax = 0;


                for (let i = 0; i < item.param2; i++) {
                  // item.param5;
                  //开启录音
                  if (item.param6 == "true" || item.param6 == true) {
                    item.param12 += 3000;
                    //税费
                    param12Tax += 180;
                  }

                  //申诉
                  if (item.param7 == "true" || item.param7 == true) {
                    item.param13 += 1000;
                    //税费
                    param13Tax += 60;
                  }

                  //培训推广
                  if (item.param8 == "true" || item.param8 == true) {
                    let promotion_money = parseInt(parseInt(item.param5 * 100 / 0.7) * 0.3);
                    promotion_money = Math.max(3000, promotion_money);
                    promotion_money = Math.min(20000, promotion_money);
                    promotion_money = parseInt(promotion_money);

                    item.param14 += promotion_money;

                    let pro_tax = (promotion_money * (item.param15 * 100)) / 100;

                    param14Tax += parseInt(pro_tax);

                  }
                }


                // 录音
                if (item.param6 == "true" || item.param6 == true) {
                  _itemList.push({
                    serviceName: `录音（${item.param1}）`,
                    serviceDesc: `问卷包含录音题及相关辅助审核服务`,
                    serviceCycle:
                      this.timestampToDatetime(
                        new Date(item.param3).getTime()
                      ) +
                      "至" +
                      this.timestampToDatetime(new Date(item.param4).getTime()),
                    serviceAmount: this.$strToMoney(item.param12),
                    advanceAmout: this.$strToMoney(item.param12),
                  });

                  _itemList.push({
                    serviceName: `录音（${item.param1}）-税费`,
                    serviceDesc: `税费（6%）`,
                    serviceCycle: "",
                    serviceAmount: this.$strToMoney(param12Tax),
                    advanceAmout: this.$strToMoney(param12Tax),
                  });
                }
                //申诉
                if (item.param7 == "true" || item.param7 == true) {
                  let appealData = new Date(item.param4);

                  _itemList.push({
                    serviceName: `申诉（${item.param1}）`,
                    serviceDesc: `提供申诉工作流，门店申诉，总部统一管理`,
                    serviceCycle:
                      this.timestampToDatetime(item.param3) +
                      "至" +
                      this.timestampToDatetime(
                        appealData.setDate(appealData.getDate() + 7)
                      ),
                    serviceAmount: this.$strToMoney(item.param13),
                    advanceAmout: this.$strToMoney(item.param13),
                  });

                  _itemList.push({
                    serviceName: `申诉（${item.param3}）-税费`,
                    serviceDesc: `税费（6%）`,
                    serviceCycle: "",
                    serviceAmount: this.$strToMoney(param13Tax),
                    advanceAmout: this.$strToMoney(param13Tax),
                  });
                }
                //任务推广
                if (item.param8 == "true" || item.param8 == true) {
                  _itemList.push({
                    serviceName: `任务推广（${item.param3}）`,
                    serviceDesc: `任务实施过程中的短信、人工推广、调研培训等，任务完成率超过99%`,
                    serviceCycle:
                      this.timestampToDatetime(
                        new Date(item.param3).getTime()
                      ) +
                      "至" +
                      this.timestampToDatetime(new Date(item.param4).getTime()),
                    serviceAmount: this.$strToMoney(item.param14),
                    advanceAmout: this.$strToMoney(item.param14),
                  });

                  _itemList.push({
                    serviceName: `任务推广（${item.param3}）-税费`,
                    serviceDesc: `税费（6%）`,
                    serviceCycle: "",
                    serviceAmount: this.$strToMoney(param14Tax),
                    advanceAmout: this.$strToMoney(param14Tax),
                  });
                }
              });

              let useTime = endTime - startTime;
              let time = 30 * 24 * 60 * 60 * 1000;
              let platformServiceCount = Math.ceil(useTime / time);
              _itemList.unshift({
                serviceName: `平台信息技术服务费-税费`,
                serviceDesc: `税费（6%）`,
                serviceCycle: "",
                serviceAmount: platformServiceCount * 1999 * 0.06,
                advanceAmout: platformServiceCount * 1999 * 0.06
              });
              _itemList.unshift({
                serviceName: `平台信息技术服务费`,
                serviceDesc: `平台信息技术服务费`,
                serviceCycle: this.timestampToDatetime(startTime) + "至" + this.timestampToDatetime(startTime + time * platformServiceCount),
                serviceAmount: platformServiceCount * 1999,
                advanceAmout: platformServiceCount * 1999
              });





              this.$set(this.contract, "itemList", _itemList);

            }
          });
        },
        ruleForm: {
          compName: '',
          compAddr: "",
          name: "",
          tel: ""
        },
        rules: {
          compName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
          ],
          compAddr: [
            { required: true, message: '请输入公司地址', trigger: 'blur' },
          ],
          name: [
            { required: true, message: '请输入联系人名称', trigger: 'blur' },
          ],
          tel: [
            { required: true, message: '请输入电话', trigger: 'blur' },
            { pattern: /^1\d{10}$/, message: '电话号码格式不正确', trigger: 'blur' }
          ],
        }
      },
      dialogVisible: true,
      isShowGuSuan: false,
      dialogVisible2: false,
      kfH: "0px",
      kfBodyW: "0px",
      ruleForm: {
        taskName: "",
        num: 50,
        money: "",
        date: [],
      },
      taskList: [

      ],
      rules: {
        taskName: [
          { required: true, message: ' ', trigger: 'blur' },
        ],
        num: [
          { required: true, message: ' ', trigger: 'blur' },
        ],
        date: [
          { required: true, message: ' ', trigger: 'blur' },
        ],
        money: [
          { required: true, message: ' ', trigger: 'blur' },
        ],
      },
      pickerOptions: {
        disabledDate: (time) => {
          let starttime = new Date().getTime() - 1 * (1000 * 60 * 60 * 24);
          let lasttime = starttime + 180 * (1000 * 60 * 60 * 24);
          return time.getTime() < starttime || time.getTime() > lasttime;
        }
      },
      pgData: {
        dyf: 0,
        money: 0,
        num: 0,
        fwf: "",
        shui: "",
        sumMoney: 0,
        taskMoney: 0,
        incrementServerMoney: 0,
      },
      incrementServerCheckList: [false, false, false],
      incrementServerNotCheck: true,
      gujiaLogding: false,
      huami: {
        // companyName2: "华米信息技术（上海）有限公司",
        // companyAddr2: "上海市徐汇区乐山路33号3号楼",
        // kaiHuHang: "上海浦东发展银行肇嘉浜路支行",
        // yinHangZhang: "97130154740003144",
        // companyPerson2: "何女士",
        // companyTel2: "021-64483509",


        "companyName2": "华米数据科技（上海）有限公司",
        "companyAddr2": "上海市徐汇区乐山路33号3号楼",
        "companyPerson2": "何女士",
        "companyTel2": "021-64483509",
        "kaiHuHang": "上海浦东发展银行徐汇支行",
        "yinHangZhang": "98300078801780000120"
      },

    }
  },
  watch: {},
  computed: {
    calcIncrServerMoney() {
      let total = 0;
      this.logItems.forEach((item) => {
        for (let i = 0; i < item.param2; i++) {
          // item.param5;
          //开启录音
          if (item.param6 == true || item.param6 == 'true') {
            total += 3000;
            //税费
            total += 180;
          }

          //申诉
          if (item.param7 == true || item.param7 == 'true') {
            total += 1000;
            //税费
            total += 60;
          }

          //培训推广
          if (item.param8 == true || item.param8 == 'true') {
            let promotion_money = parseInt(parseInt(item.param5 * 100 / 0.7) * 0.3);
            promotion_money = Math.max(3000, promotion_money);
            promotion_money = Math.min(20000, promotion_money);
            promotion_money = parseInt(promotion_money);

            total += promotion_money;

            let pro_tax = (promotion_money * (item.param15 * 100)) / 100;

            total += parseInt(pro_tax);

          }
        }

        let itemMoney = 0;
        //录音
        itemMoney = this.numberAdd(
          this.numberAdd(
            this.calcTax(Number(item.param12), Number(item.param15)),
            Number(item.param12)
          ),
          itemMoney
        );

        //申诉
        itemMoney = this.numberAdd(
          this.numberAdd(
            this.calcTax(Number(item.param13), Number(item.param15)),
            Number(item.param13)
          ),
          itemMoney
        );

        //任务推广
        itemMoney = this.numberAdd(
          this.numberAdd(
            this.calcTax(Number(item.param14), Number(item.param15)),
            Number(item.param14)
          ),
          itemMoney
        );

        // total = this.numberAdd(total, itemMoney);
      });

      return total;
    },
    clacBaseMoney() {
      let total = 0;
      this.logItems.forEach((item) => {
        //平台订阅费
        total += (item.param9 * 100)
        total += parseInt(((item.param9 * 100) * (item.param15 * 100)) / 100)

        for (let i = 0; i < item.param2; i++) {

          let m = parseInt(item.param5 * 100 / 0.7) - (item.param5 * 100);
          m = Math.min(
            //老逻辑
            // Math.ceil((parseInt((item.samplePrice / 7) * 100) / 100) * 3),
            //新逻辑
            m,
            Number(30000.0)
          );
          m += (item.param5 * 100)

          total += m;
          total += parseInt((m * (item.param15 * 100)) / 100);

        }


        let itemMoney = 0;
        //平台订阅费
        itemMoney = this.numberAdd(
          this.numberAdd(
            this.calcTax(Number(item.param9), Number(item.param15)),
            Number(item.param9)
          ),
          itemMoney
        );

        //用户酬劳
        itemMoney = this.numberAdd(
          this.numberAdd(
            this.calcTax(Number(item.param10), Number(item.param15)),
            Number(item.param10)
          ),
          itemMoney
        );

        //平台审核费
        itemMoney = this.numberAdd(
          this.numberAdd(
            this.calcTax(Number(item.param11), Number(item.param15)),
            Number(item.param11)
          ),
          itemMoney
        );

        // total = this.numberAdd(total, itemMoney);
      });

      return total;
    },
    clacTotalMoney() {
      return this.numberAdd(this.calcIncrServerMoney, this.clacBaseMoney);
    },
    baseUrl() {
      if (location.href.indexOf("www.darenlaiye.com") > 0) {
        return "https://e.darenlaiye.com/application/api";
      }
      return "https://e_test.darenlaiye.com/application/api";
    },
  },
  methods: {
    requestApi(actions, params) {
      params.platform = "guanwang"
      return new Promise((resolve, reject) => {
        fetch(`${this.baseUrl}?action=${actions}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ params: params })
        })
          .then(response => response.json()) // 如果服务器返回JSON，则解析它
          .then((resp) => {
            if (resp.code == 0) {
              resolve(resp);
            } else {

              reject(resp);
            }

          }) // 处理服务器返回的数据
          .catch((error) => {

            reject(error);
          }); // 处理错误
      });
    },
    sendResult() {
      let taskName = this.logItems[0].param1;
      if (this.logItems.length > 1) {
        taskName += `等${this.logItems.length - 1}个`;
      }
      this.requestApi("api.biz.business.eval.log.send.result", {
        guid: this.phoneId,
        callback: location.href,
        money: this.$strToMoney(this.clacTotalMoney),
        taskName: taskName,
      })
        .then((resp) => { })
        .catch((err) => { })
        .finally(() => { });
    },
    logV2(
      param1,
      param2,
      param3,
      param4,
      param5,
      param6,
      param7,
      param8,
      param9,
      param10,
      param11,
      param12,
      param13,
      param14,
      param15
    ) {

      //平台订阅费
      param10 = 0;
      param11 = 0;
      param12 = 0;
      param13 = 0;
      param14 = 0;

      let param10Tax = 0;
      let param11Tax = 0;
      let param12Tax = 0;
      let param13Tax = 0;
      let param14Tax = 0;

      for (let i = 0; i < param2; i++) {
        let m = Math.min(
          //老逻辑
          // Math.ceil((parseInt((item.samplePrice / 7) * 100) / 100) * 3),
          //新逻辑
          parseInt(param5 * 100 / 0.7) - (param5 * 100),
          Number(30000.0)
        );
        param10 += (param5 * 100);
        param10Tax += (parseInt(((param5 * 100) * (param15 * 100)) / 100));
        console.log("=============");
        console.log(param10);
        console.log(param10Tax);


        param11 += m;
        param11Tax += parseInt((m * (param15 * 100)) / 100);


        //开启录音
        if (param6 == true || param6 == 'true') {
          param12 += 3000;
          //税费
          param12Tax += 180;
        }

        //申诉
        if (param7 == true || param7 == 'true') {
          param13 += 1000;
          //税费
          param13Tax += 60;
        }

        //培训推广
        if (param8 == true || param8 == 'true') {
          let promotion_money = parseInt(parseInt(param5 * 100 / 0.7) * 0.3);
          promotion_money = Math.max(3000, promotion_money);
          promotion_money = Math.min(20000, promotion_money);
          promotion_money = parseInt(promotion_money);

          param14 += promotion_money;

          let pro_tax = (promotion_money * (param15 * 100)) / 100;

          param14Tax += parseInt(pro_tax);

        }

      }
      param10 += param10Tax;
      param11 += param11Tax;
      param12 += param12Tax;
      param13 += param13Tax;
      param14 += param14Tax;

      this.logItems.push({
        logGuid: this.phoneId,
        param1,
        param2,
        param3,
        param4,
        param5,
        param6,
        param7,
        param8,
        param9: this.toFixedTow(param9),
        param10: this.$strToMoney(param10),
        param11: this.$strToMoney(param11),
        param12: this.$strToMoney(param12),
        param13: this.$strToMoney(param13),
        param14: this.$strToMoney(param14),
        param15: this.toFixedTow(param15),
      });
      this.requestApi("api.biz.business.eval.log.item.create", {
        logGuid: this.phoneId,
        param1,
        param2,
        param3,
        param4,
        param5,
        param6,
        param7,
        param8,
        param9: this.toFixedTow(param9),
        param10: this.$strToMoney(param10),
        param11: this.$strToMoney(param11),
        param12: this.$strToMoney(param12),
        param13: this.$strToMoney(param13),
        param14: this.$strToMoney(param14),
        param15: this.toFixedTow(param15),
      })
        .then((resp) => {
          this.logItems = resp.data;
        })
        .catch((err) => { })
        .finally(() => { });
    },
    getPhoneId() {
      return new Promise((resolve, reject) => {
        if (this.phoneId) {
          resolve();
        } else {
          this.checkLoginInfo()
            .then(() => {
              resolve();
            })
            .catch(() => {
              console.log("loggggg");
              this.showDialog = true;
              reject();
            });
        }
      });
    },
    submitPhone() {
      this.$refs["phoneForm"].validate((valid) => {
        if (valid) {
          this.requestApi("api.biz.business.eval.log.sms.login", {
            phone: this.phone.ruleForm.tel,
            sms: this.phone.ruleForm.code,
          })
            .then((resp) => {
              this.setTempUser(this.phone.ruleForm.tel);
              this.countDownFinished();
              this.$message.success("登录成功");
              this.phoneId = resp.data.guid;
              this.showDialog = false;
              this.gujiao();
            })
            .catch((err) => {
              console.log(err);
              this.$message.error(err.msg);
            })
            .finally(() => { });
        }
      });
    },
    sendSmsCode() {
      if (this.countDownTimer) {
        return;
      }
      this.$refs.phoneForm.validateField(["tel"], (valid) => {
        if (!valid) {
          this.countDownTimer = true;
          this.countDownTime = 60;
          // 发送短信验证码逻辑
          // api.biz.business.eval.log.sms
          this.requestApi("api.biz.business.eval.log.sms", {
            phone: this.phone.ruleForm.tel,
          })
            .then((resp) => {
              this.$message.success("获取验证码成功！");
              this.phone.timer = setInterval(() => {
                if (this.countDownTime == 1) {
                  this.countDownFinished();
                }
                this.countDownTime = this.countDownTime - 1;
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              // 模拟计时器
              this.countDownTimer = false;

              this.$message.error(err.msg);
            })
            .finally(() => { });
        }
      });
    },
    countDownFinished() {
      if (this.phone.timer) {
        clearInterval(this.phone.timer);
      }
      this.countDownTimer = false;
      this.countDownTime = 60;
    },
    calcPromotion(num, money) {
      return this.toFixedTow(num * money);
    },
    calcPromotionMoney(money) {
      let promotion_money = this.toFixedFour(
        (money / 0.7) * 0.3
      );
      promotion_money = Math.max(30, promotion_money);
      promotion_money = Math.min(200, promotion_money);
      return promotion_money;
    },
    incrementServerChange(v) {
      console.log(!this.incrementServerCheckList.includes(true));
      this.$set(this, "incrementServerNotCheck", !this.incrementServerCheckList.includes(true));
    },
    download() {
      this.downloadLoading = true;

      let param = {
        contractNo: "预览合同",
        companyName1: this.contract.ruleForm.compName,
        companyAddr1: this.contract.ruleForm.compAddr,
        companyPerson1: this.contract.ruleForm.name,
        companyTel1: this.contract.ruleForm.tel,
        companyName2: this.huami.companyName2,
        companyAddr2: this.huami.companyAddr2,
        companyPerson2: this.huami.companyPerson2,
        companyTel2: this.huami.companyTel2,
        kaiHuHang: this.huami.kaiHuHang,
        yinHangZhang: this.huami.yinHangZhang,
        loginEmail: this.contract.ruleForm.compName,
        itemList: [],

      };
      for (let i = 0; i < this.contract.itemList.length; i++) {
        param.itemList.push({
          no: i + 1,
          name: this.contract.itemList[i].serviceName,
          desc: this.contract.itemList[i].serviceDesc,
          cycle: this.contract.itemList[i].serviceCycle,
          serviceAmount: this.contract.itemList[i].serviceAmount,
          advanceAmout: this.contract.itemList[i].advanceAmout,
        });
      }



      // 将对象转换为JSON字符串
      const jsonData = JSON.stringify({
        params: param
      });

      // 发送POST请求
      fetch('https://e_test.darenlaiye.com/application/api?action=api.biz.business.contract.preview.create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: jsonData
      })
        .then(response => response.json()) // 如果服务器返回JSON，则解析它
        .then((resp) => {
          if (resp.data && resp.data.base64) {
            let fileName = resp.data.file_name;
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${resp.data.base64}`;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }) // 处理服务器返回的数据
        .catch(error => console.error('Error:', error)); // 处理错误

      this.downloadLoading = false;
    },
    addTask() {
      if (this.taskList.length == 5) {
        this.$message('最多只能添加5个任务');
        return;
      }
      this.taskList.push({
        taskName: "",
        num: 50,
        money: "",
        date: [],
      });
    },
    showKf() {
      let _h = document.body.clientHeight * 0.6;
      this.kfH = _h + "px";
      console.log(_h);
      this.kfBodyW = (_h / 1613) * 1182 + 40 + "px";
      this.dialogVisible2 = true;
    },
    jump() {
      window.open("https://e.darenlaiye.com/www", "_blank");
    },
    show() {
      this.dialogVisible = true;
      this.logItems = [];
      this.phoneId = "";
      this.ptServerNumber = 0;
      this.phone.ruleForm.tel = "";
      this.phone.ruleForm.code = "";
      this.$set(this, "isShowGuSuan", false);
      this.$set(this, "step", 1);
      this.$set(this.ruleForm, "num", "");

      this.$set(this.ruleForm, "money", "25");
      this.$set(this.ruleForm, "taskName", "");

      this.$set(this.ruleForm, "date",
        [

        ]
      );
      this.$set(this, "taskList",
        [
        ]
      );
    },
    checkLoginInfo() {
      return new Promise((resolve, reject) => {
        let tempUser = this.getTempUser();
        if (tempUser) {
          this.requestApi("api.biz.business.eval.log.auto.login", {
            phone: tempUser,
          })
            .then((resp) => {
              this.setTempUser(tempUser);
              this.phoneId = resp.data.guid;
              resolve();
            })
            .catch((err) => {
              reject();
            })
            .finally(() => { });
        } else {
          reject();
        }
      });
    },
    setTempUser(value) {
      const now = new Date();
      // ttl 是过期时间（单位：毫秒），now.getTime() 是当前时间的时间戳
      const item = {
        value: value,
        expiry: now.getTime() + 1000 * 60 * 60 * 24,
      };
      localStorage.setItem(this.baseUrl.replaceAll('/', '').replaceAll(':', '') + "EvalTempUser", JSON.stringify(item));
    },
    getTempUser() {
      const itemStr = localStorage.getItem(this.baseUrl.replaceAll('/', '').replaceAll(':', '') + "EvalTempUser");
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      if (!item || !item.value) {
        return null;
      }
      return item.value;
    },
    stepGujiao() {
      if (!this.ruleForm.taskName || !this.ruleForm.num || !this.ruleForm.money || !this.ruleForm.date || this.ruleForm.date.length != 2) {
        this.$message.error('请将需求信息填写完整');
        return false;
      }
      if (!this.ruleForm.taskName) {
        this.$message.error('请输入任务名称');
        return false;
      }
      if (Number(this.ruleForm.num) <= 0) {
        this.$message.error('请至少填写一分样本数');
        return false;
      }
      if (Number(this.ruleForm.money) < 25) {
        this.$message.error('单份样本报酬至少为25元');
        return false;
      }
      let startTime = new Date(this.ruleForm.date[0]).getTime();
      let endTime = new Date(this.ruleForm.date[1]).getTime();
      this.pgData.money = this.ruleForm.num * this.ruleForm.money;
      let _fwf = 0;
      for (var i = 0; i < this.ruleForm.num; i++) {
        _fwf += Math.min(Math.ceil(parseInt(this.ruleForm.money / 7 * 100) / 100 * 3), 300);
      }
      this.pgData.fwf = _fwf;
      for (let i = 0; i < this.taskList.length; i++) {
        if (!this.taskList[i].taskName || !this.taskList[i].num || !this.taskList[i].money || !this.taskList[i].date || this.taskList[i].date.length != 2) {
          this.$message.error(`第${i + 2}个任务，请将需求信息填写完整`);
          return false;
        }
        if (!this.taskList[i].taskName) {
          this.$message.error(`第${i + 2}个任务，请输入任务名称`);
          return false;
        }
        if (Number(this.taskList[i].num) <= 0) {
          this.$message.error(`第${i + 2}个任务，请至少填写一分样本数`);
          return false;
        }
        if (Number(this.taskList[i].money) < 25) {
          this.$message.error(`第${i + 2}个任务，单份样本报酬至少为25元`);
          return false;
        }
        startTime = Math.min(startTime, new Date(this.taskList[i].date[0]).getTime());

        endTime = Math.max(endTime, new Date(this.taskList[i].date[1]).getTime());
        this.pgData.money += this.taskList[i].num * this.taskList[i].money;
        for (var i1 = 0; i1 < this.taskList[i].num; i1++) {
          this.pgData.fwf += Math.min(Math.ceil(parseInt(this.taskList[i].money / 7 * 100) / 100 * 3), 300);
        }
      }

      let useTime = endTime - startTime;
      if (useTime <= 0) {
        this.$message.error('至少选择一天调研周期！');
        return;
      }
      let time = 30 * 24 * 60 * 60 * 1000;
      let platformServiceCount = Math.ceil(useTime / time);

      this.pgData.dyf = platformServiceCount * 1999;

      this.pgData.shui = (this.pgData.dyf * 0.06) + (this.pgData.money * 0.06) + (this.pgData.fwf * 0.06)

      this.pgData.sumMoney = this.pgData.dyf + this.pgData.money + this.pgData.fwf + this.pgData.shui;
      // this.isShowGuSuan = true;
      this.step = 2;


      console.log(this.ruleForm.num);
      console.log(this.ruleForm.money);
      console.log(Math.ceil(useTime / (24 * 60 * 60 * 1000)));
      this.log(this.ruleForm.num, 1, this.ruleForm.money, Math.ceil(useTime / (24 * 60 * 60 * 1000)));
      return true;
    },

    gujiao() {
      if (!this.ruleForm.taskName || !this.ruleForm.num || !this.ruleForm.money || !this.ruleForm.date || this.ruleForm.date.length != 2) {
        this.$message.error('请将需求信息填写完整');
        return false;
      }
      if (!this.ruleForm.taskName) {
        this.$message.error('请输入任务名称');
        return false;
      }
      if (Number(this.ruleForm.num) <= 0) {
        this.$message.error('请至少填写一分样本数');
        return false;
      }
      if (Number(this.ruleForm.money) < 25) {
        this.$message.error('单份样本报酬至少为25元');
        return false;
      }
      let tape = 0;
      let appeal = 0;
      let promotion = 0;

      let startTime = new Date(this.ruleForm.date[0]).getTime();
      let endTime = new Date(this.ruleForm.date[1]).getTime();
      this.pgData.money = this.ruleForm.num * this.ruleForm.money;
      let _fwf = 0;
      for (var i = 0; i < this.ruleForm.num; i++) {
        _fwf += Math.min(Math.ceil(parseInt(this.ruleForm.money / 7 * 100) / 100 * 3), 300);
      }
      console.log(this.incrementServerCheckList);


      this.pgData.fwf = _fwf;


      //增值服务
      if (this.incrementServerCheckList[0]) {
        tape += (this.ruleForm.num * 30)
      }
      if (this.incrementServerCheckList[1]) {
        appeal += (this.ruleForm.num * 10)
      }
      if (this.incrementServerCheckList[2]) {
        let promotion_money = this.calcPromotionMoney(this.ruleForm.money);

        console.log(promotion_money);
        promotion += this.calcPromotion(this.ruleForm.num, promotion_money);
        console.log(promotion);
      }

      for (let i = 0; i < this.taskList.length; i++) {
        if (!this.taskList[i].taskName || !this.taskList[i].num || !this.taskList[i].money || !this.taskList[i].date || this.taskList[i].date.length != 2) {
          this.$message.error(`第${i + 2}个任务，请将需求信息填写完整`);
          return false;
        }
        if (!this.taskList[i].taskName) {
          this.$message.error(`第${i + 2}个任务，请输入任务名称`);
          return false;
        }
        if (Number(this.taskList[i].num) <= 0) {
          this.$message.error(`第${i + 2}个任务，请至少填写一分样本数`);
          return false;
        }
        if (Number(this.taskList[i].money) < 25) {
          this.$message.error(`第${i + 2}个任务，单份样本报酬至少为25元`);
          return false;
        }
        startTime = Math.min(startTime, new Date(this.taskList[i].date[0]).getTime());

        endTime = Math.max(endTime, new Date(this.taskList[i].date[1]).getTime());
        this.pgData.money += this.taskList[i].num * this.taskList[i].money;
        for (var i1 = 0; i1 < this.taskList[i].num; i1++) {
          this.pgData.fwf += Math.min(Math.ceil(parseInt(this.taskList[i].money / 7 * 100) / 100 * 3), 300);
        }


        if (this.incrementServerCheckList[0] == true) {
          tape += this.toFixedFloat(this.taskList[i].num * 30)
        }
        if (this.incrementServerCheckList[1] == true) {
          appeal += this.toFixedFloat(this.taskList[i].num * 10)
        }
        if (this.incrementServerCheckList[2] == true) {
          let promotion_money = this.calcPromotionMoney(this.taskList[i].money);
          promotion += this.calcPromotion(this.taskList[i].num, promotion_money)
        }
      }

      for (let i = 0; i < this.taskCatch.length; i++) {
        startTime = Math.min(startTime, new Date(this.taskCatch[i].date[0]).getTime());

        endTime = Math.max(endTime, new Date(this.taskCatch[i].date[1]).getTime());
        this.pgData.money += this.taskCatch[i].num * this.taskCatch[i].money;
        for (var i1 = 0; i1 < this.taskCatch[i].num; i1++) {
          this.pgData.fwf += Math.min(Math.ceil(parseInt(this.taskCatch[i].money / 7 * 100) / 100 * 3), 300);
        }


        if (this.taskCatch[i].incrementServerCheckList[0] == true) {
          tape += this.toFixedFloat(this.taskCatch[i].num * 30)
        }
        if (this.taskCatch[i].incrementServerCheckList[1] == true) {
          appeal += this.toFixedFloat(this.taskCatch[i].num * 10)
        }
        if (this.taskCatch[i].incrementServerCheckList[2] == true) {
          let promotion_money = this.calcPromotionMoney(this.taskCatch[i].money);

          console.log(promotion_money);
          promotion += this.calcPromotion(this.taskCatch[i].num, promotion_money)
          console.log(promotion);
        }
      }



      console.log(this.taskCatch);

      let useTime = endTime - startTime;
      if (useTime <= 0) {
        this.$message.error('至少选择一天调研周期！');
        return;
      }
      let time = 30 * 24 * 60 * 60 * 1000;
      let platformServiceCount = Math.ceil(useTime / time);



      this.pgData.dyf = platformServiceCount * 1999;

      this.pgData.shui = (this.pgData.dyf * 0.06) + (this.pgData.money * 0.06) + (this.pgData.fwf * 0.06)

      this.pgData.taskMoney = this.pgData.dyf + this.pgData.money + this.pgData.fwf + this.pgData.shui;

      console.log(tape);

      console.log(appeal);

      console.log(promotion);
      this.pgData.incrementServerMoney = this.toFixedTow(this.toFixedTow(tape * 1.06) + this.toFixedTow(appeal * 1.06) + this.toFixedTow(promotion * 1.06));
      // this.pgData.incrementServerMoney = parseFloat(Number(this.pgData.incrementServerMoney).toFixed(2));

      this.pgData.sumMoney = this.pgData.incrementServerMoney + this.pgData.taskMoney;

      this.gujiaLogding = true;
      this.getPhoneId()
        .then(() => {
          this.isShowGuSuan = true;


          this.logV2(
            this.ruleForm.taskName,
            this.ruleForm.num,
            this.timestampToDatetime(this.ruleForm.date[0]),
            this.timestampToDatetime(this.ruleForm.date[1]),
            this.ruleForm.money,
            this.incrementServerCheckList[0],
            this.incrementServerCheckList[1],
            this.incrementServerCheckList[2],
            (platformServiceCount - this.ptServerNumber) * 1999,
            this.ruleForm.num * this.ruleForm.money,
            this.ruleForm.num *
            this.toFixedTow(this.ruleForm.money / 0.7 - this.ruleForm.money),
            !this.incrementServerCheckList[0]
              ? 0
              : this.toFixedTow(this.ruleForm.num * 30),
            !this.incrementServerCheckList[1]
              ? 0
              : this.toFixedTow(this.ruleForm.num * 10),
            !this.incrementServerCheckList[2]
              ? 0
              : this.calcPromotion(
                this.ruleForm.num,
                this.calcPromotionMoney(this.ruleForm.money)
              ),
            0.06
          );
          this.ptServerNumber = platformServiceCount;


          this.$confirm('是否添加多个任务进行调研估价?', '添加多个任务提醒', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            customClass: 'evaluateConfirm'
          }).then(() => {
            this.taskCatch.push({
              date: [new Date(this.ruleForm.date[0]), new Date(this.ruleForm.date[1])],
              taskName: this.ruleForm.taskName,
              num: this.ruleForm.num,
              money: this.ruleForm.money,
              incrementServerCheckList: [this.incrementServerCheckList[0], this.incrementServerCheckList[1], this.incrementServerCheckList[2]]
            });


            this.ruleForm.taskName = "";
            this.ruleForm.num = 50;
            this.ruleForm.date = [];
            this.ruleForm.money = "";
            this.incrementServerCheckList = [false, false, false];
            this.step = 1;
            this.isShowGuSuan = false;
          }).catch(() => {
            this.sendResult();
          });

          console.log(this.ruleForm.num);
          console.log(this.ruleForm.money);
          console.log(Math.ceil(useTime / (24 * 60 * 60 * 1000)));
          this.log(this.ruleForm.num, 1, this.ruleForm.money, Math.ceil(useTime / (24 * 60 * 60 * 1000)));
        })
        .catch(() => { })
        .finally(() => {
          this.gujiaLogding = false;
        });


      return true;
    },
    log(yangben, mendian, choulao, zhouqi) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', `https://yygj.darenlaiye.com/mer99/api/home/log_TaskCal?duan=guanWang&yangben=${yangben}&mendian=${mendian}&choulao=${choulao}&zhouqi=${zhouqi}`, true);
      xhr.onload = function () {
        if (xhr.status === 200) {
          console.log(xhr.responseText);
        }
        else {
          console.log('Request failed.  Returned status of ' + xhr.status);
        }
      };
      xhr.send();
    },
    close() {
      console.log("1111");
      this.$emit("close")
    },
    timestampToDatetime(timestamp) {
      let date = new Date(timestamp);
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + date.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    toFixedFloat(num) {
      return parseFloat(Number(num).toFixed(2))
    },
    numberAdd(num1, num2) {
      let over = new Decimal(10000);
      num1 = new Decimal(num1).mul(over);
      num2 = new Decimal(num2).mul(over);
      return num1.add(num2).div(over).toNumber();
    },
    calcTax(money, tax) {
      let over = new Decimal(100);
      tax = new Decimal(tax).mul(over);
      return new Decimal(money).mul(tax).div(over).toNumber();
    },
    toFixedTow(num) {
      num = Number(num).toFixed(13); // 转换为字符串格式的两位小数
      let result = Number(num.slice(0, num.length - 11)); // 去掉最后一位数字
      return result;
    },
    toFixedFour(num) {
      num = num.toFixed(5); // 转换为字符串格式的两位小数
      return Number(num.slice(0, num.length - 1)); // 去掉最后一位数字
    },
    $strToMoney(str) {
      if (!str || str == '0') {
        return "0.00"
      }
      let num = parseInt(str);

      let rstrArr = num.toString().split('');
      const index = rstrArr.length - 2;
      rstrArr.splice(index, 0, '.')
      return rstrArr.join('');
    },
    loadCache() {
      let cacheParamArr = [
        {
          module: "1001",
          key: "huami_company",
          callback: (resp) => {
            console.log(resp);
            if (
              resp.data &&
              resp.data.dictValue &&
              resp.data.dictValueType == 3
            ) {
              //               huami: {
              //   companyName2: "",
              //   companyAddr2: "",
              //   kaiHuHang: "",
              //   yinHangZhang: "",
              //   companyPerson2: "",
              //   companyTel2: "",
              // },
              this.huami_company = JSON.parse(resp.data.dictValue);
              let _hm = JSON.parse(resp.data.dictValue);
              this.huami.companyName2 = _hm.company;
              this.huami.companyAddr2 = _hm.address;
              this.huami.kaiHuHang = _hm.kaiHuHang;
              this.huami.yinHangZhang = _hm.yinHangZhang;
              this.huami.companyPerson2 = _hm.person;
              this.huami.companyTel2 = _hm.phone;
            }
          },
        },
      ];
      cacheParamArr.forEach((element) => {
        this.doCache(element);
      });
    },
    doCache({ module, key, callback }) {
      this.requestApi("api.system.dictionary.cache", {
        dictModule: module,
        dictKey: key,
      })
        .then((resp) => {
          console.log("callback");
          callback(resp);
        })
        .catch(() => { });
    },
  },
  created() { },
  mounted() {
    this.loadCache();
  }
};
</script>
<style >
.evaluateConfirm {
  position: relative;
  top: -200px;
}
</style>
<style lang='less' scoped>
.el-message-box__wrapper {
  position: absolute !important;
  /* 使用绝对定位 */
  top: 30% !important;
  /* 位于页面垂直中央 */
  left: 50% !important;
  /* 位于页面水平中央 */
  transform: translate(-50%, -50%) !important;
  /* 使用transform进行微调 */
}

#contract_order_table {
  font-size: 16px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

#contract_order_table tr {
  width: 100%;
}

#contract_order_table tr .contract_order_table_col {
  width: 16%;
  border: 1px solid #bbb;
  padding: 10px;
  text-align: center;
}

#contract_order_table tr .contract_order_table_col:nth-child(1) {
  width: 6%;
  border: 1px solid #bbb;
  padding: 10px;
  text-align: center;
}

#contract_order_table tr .contract_order_table_col:nth-child(3) {
  width: 26%;
}

#sign_table {
  font-size: 16px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

#sign_table tr {
  width: 100%;
}

#sign_table tr td {
  width: 50%;
  padding: 10px;

  border: 1px solid #bbb;
}

.keynote {
  font-weight: bolder;
  color: #101010;
}

.lineHeight2 {
  font-size: 16px;
  line-height: 2em;
}

.gjDialog .el-dialog__body {
  padding: 15px;
}

.gjDialog .el-dialog__footer {
  padding: 15px;
  border-top: 1px solid rgba(204, 204, 204, 1);
}

.gjDialog .el-form-item__error {
  display: none;
}

* {
  // margin: 0px;
}

#head {
  width: 100%;
  height: 40%;
  background-color: rgba(250, 247, 239, 1);
  display: flex;
}

#head_content {
  width: calc(60% - 1.2vw);
  height: 100%;
  padding-left: 1.2vw;
}

#head_banner {
  width: 40%;
  height: 100%;
  background-color: aquamarine;
}

#body {
  width: calc(100% - 1.2vw);
  height: calc(60% - 1.2vw);
  padding-left: 1.2vw;
}

.myli {
  display: flex;
  align-items: center;

  font-size: 0.75vw;
  padding: 0.6em 0px;
}

.myli_before {
  background-color: black;
  font-size: 0.75vw;
  width: 0.5em;
  height: 0.5em;
  border-radius: 1em;
  line-height: 0.75vw;
}

.myli_content {
  font-size: 0.75vw;
  color: black;
  line-height: 0.75vw;
  height: 0.75vw;
  margin: 0px 2em 0px 0.6em;
}

.myli_details {
  color: #333333;
}

.btn {
  font-size: 0.8vw;
  width: 9em;
  height: 3em;
  line-height: 3em;
  border-radius: 5px;
  background-color: #f15236;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  margin-right: 2em;
  font-weight: bolder;
  cursor: pointer;
}

.el-carousel__container,
.el-carousel {
  height: 100% !important;
}

.step {
  border: 1px solid rgba(187, 187, 187, 1);
  width: 10em;
  height: 3em;
  line-height: 3em;
  text-align: center;
  color: #333;
  font-weight: 500;
}

.title {
  display: flex;
  align-items: center;
  margin: 15px 0px 15px 27px;
}

.title .title_prefix {
  left: 161px;
  top: 129px;
  width: 3px;
  height: 14px;
  line-height: 20px;
  border-radius: 16px;
  background-color: rgba(74, 142, 233, 1);
  text-align: center;
}

.title .title_context {
  left: 173px;
  top: 121px;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  text-align: left;
  margin-left: 0.5em;
  line-height: 1em;
}

.el-dialog__header {
  border-bottom: 1px solid rgba(204, 204, 204, 1);
}

.el-dialog__body {
  padding-top: 10px;
}

.gjDialog .el-form-item {
  margin-bottom: 0px !important;
}

.el-form-item__label,
.el-form-item__content {
  color: #333;
}

.el-form-item__label {
  text-align: left;
  width: 140px !important;
  padding-left: 24px;
}

.infoDetails {
  color: #9a9a9a;
}

/deep/ .el-form-item__content {
  margin-left: 0px !important;
  display: flex;
  justify-content: start;
}

.infoDetails {
  margin-left: 136px;
  line-height: 1.5em;
  margin-bottom: 15px;
  margin-top: 0.5em;
}

.row {
  display: flex;
  padding-bottom: 15px;
  font-size: 14px;
}

.row > div:nth-child(1) {
  width: 135px;
  padding-left: 2em;
}

.el-checkbox {
  font-weight: normal;
  display: flex;
  align-items: center;
}

.phoneDialog .el-form-item__label {
  text-align: left;
  font-weight: 500 !important;
  color: #000;
}
.phoneDialog .el-form-item__content {
  display: table !important;
}
.phoneDialog .el-dialog__footer {
  padding: 15px;
  border-top: 1px solid rgba(204, 204, 204, 1);
}
</style>