var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drInstitute",staticStyle:{"background-color":"#f7f8f9"}},[_c('div',{staticClass:"bH",style:({
      backgroundImage: 'url(' + require('@/assets/home/bh_center.svg') + ')',
    })},[_c('div',{staticClass:"bh_left",style:({
        backgroundImage: 'url(' + require('@/assets/home/bh_left.svg') + ')',
      })}),_c('div',{staticClass:"bh_right",style:({
        backgroundImage: 'url(' + require('@/assets/home/bh_right.svg') + ')',
      })}),_c('div',{staticClass:"body"},[_c('div',{staticClass:"title"},[_vm._v("大人来也致力于为客户提供真实、有效的实证调查")]),_c('div',{staticClass:"sub_title"},[_vm._v(" 大人研究所的合作伙伴包括高校、研究机构、行业协会、连锁企业、咨询智库，研究合作请通过邮件联系我们 "),_c('span',{staticClass:"email",on:{"click":function($event){return _vm.mailto('marketing@huami-tech.com')}}},[_vm._v("marketing@huami-tech.com")])]),_c('div',{staticClass:"sub_title2"},[_c('div',[_vm._v("大人研究所的合作伙伴")]),_c('div',[_vm._v("含高校、研究机构、行业协会、连锁企业、咨询智库，")]),_c('div',[_vm._v(" 研究合作请通过邮件联系我们 "),_c('span',{staticClass:"email",on:{"click":function($event){return _vm.mailto('marketing@huami-tech.com')}}},[_vm._v("marketing@huami-tech.com")])])]),_c('div',{staticClass:"desc"}),_c('div',{staticClass:"desc_show_btn",on:{"click":function($event){return _vm.showDesc()}}}),_c('div',{staticClass:"desc2"}),_c('img',{attrs:{"width":"100%","src":require('@/assets/dr_institute/head.png')}})])]),_c('trends',{attrs:{"data":_vm.trendsData},on:{"showAll":() => {
        _vm.$router.push('/dryjsTrends');
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }