<template>
  <div>
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>

    <van-form
      ><van-field required name="radio" label="推广平台">
        <template #input>
          <div v-if="indexType == 0">
            <van-radio-group v-model="form.pingtaiType" direction="horizontal">
              <van-radio name="1">抖音</van-radio>
              <van-radio name="2">小红书</van-radio>
            </van-radio-group>
          </div>
          <div v-else style="width: 11em">
            <el-row>
              <el-col :span="12">
                <van-checkbox
                  v-model="form.douyin"
                  @change="form3dycheckboxChange"
                  shape="square"
                >
                  抖音
                </van-checkbox></el-col
              >
              <el-col :span="12">
                <van-checkbox
                  shape="square"
                  v-model="form.xiaohongshu"
                  @change="form3xhscheckboxChange"
                >
                  小红书
                </van-checkbox>
              </el-col>
            </el-row>

            <!-- <van-checkbox-group
              v-model="form.pingtaiTypes"
              direction="horizontal"
            >
              <van-checkbox name="1" shape="square">抖音</van-checkbox>
              <van-checkbox name="2" shape="square">小红书</van-checkbox>
            </van-checkbox-group> -->
          </div>
        </template>
      </van-field>
      <div v-if="indexType == 0">
        <van-field required name="checkboxGroup" label="粉丝量">
          <template #input>
            <div>
              <van-row
                v-for="item in form.pingtaiType == '2'
                  ? xhsFensiliangArr
                  : dyFensiliangArr"
                :key="item.name"
              >
                <van-col :span="7">
                  <van-checkbox
                    disabled
                    style="padding: 3px 0px"
                    v-model="item.checked"
                    shape="square"
                    ><span style="color: rgb(108 108 110)">{{
                      item.name
                    }}</span>
                  </van-checkbox></van-col
                >
                <van-col :span="7">
                  <input
                    disabled
                    readonly
                    id="renInput"
                    style="width: 70%; height: 0.8rem"
                    :value="item.count"
                    type="text"
                    maxlength="6"
                    onkeyup="value=value.replace(/\D/g,'')"
                    onafterpaste="value=value.replace(/\D/g,'')"
                    @blur="form.testA = $event.target.value"
                /></van-col>
                <van-col :span="2"> 人</van-col>
              </van-row>
            </div>
          </template>
        </van-field>
      </div>
      <div v-else>
        <van-field required name="checkboxGroup" label="抖音粉丝量">
          <template #input>
            <div>
              <van-row v-for="item in dyFensiliangArr" :key="item.name">
                <van-col :span="7">
                  <van-checkbox
                    disabled
                    style="padding: 3px 0px"
                    v-model="item.checked"
                    shape="square"
                    ><span style="color: rgb(108 108 110)">{{
                      item.name
                    }}</span>
                  </van-checkbox></van-col
                >
                <van-col :span="7">
                  <input
                    disabled
                    readonly
                    id="renInput"
                    style="width: 70%; height: 0.8rem"
                    :value="item.count"
                    type="text"
                    maxlength="6"
                    onkeyup="value=value.replace(/\D/g,'')"
                    onafterpaste="value=value.replace(/\D/g,'')"
                    @blur="form.testA = $event.target.value"
                /></van-col>
                <van-col :span="2"> 人</van-col>
              </van-row>
            </div>
          </template>
        </van-field>
        <van-field required name="checkboxGroup" label="小红书粉丝量">
          <template #input>
            <div>
              <van-row v-for="item in xhsFensiliangArr" :key="item.name">
                <van-col :span="7">
                  <van-checkbox
                    disabled
                    style="padding: 3px 0px"
                    v-model="item.checked"
                    shape="square"
                    ><span style="color: rgb(108 108 110)">{{
                      item.name
                    }}</span>
                  </van-checkbox></van-col
                >
                <van-col :span="7">
                  <input
                    disabled
                    readonly
                    id="renInput"
                    style="width: 70%; height: 0.8rem"
                    :value="item.count"
                    type="text"
                    maxlength="6"
                    onkeyup="value=value.replace(/\D/g,'')"
                    onafterpaste="value=value.replace(/\D/g,'')"
                    @blur="form.testA = $event.target.value"
                /></van-col>
                <van-col :span="2"> 人</van-col>
              </van-row>
            </div>
          </template>
        </van-field>
      </div>
      <van-field
        required
        v-model="form.tiyanguan"
        type="digit"
        readonly
        label="大人体验官"
        class="gray-input"
      />
      <van-field required name="radio" label="到店消费金额">
        <template #input>
          <div>
            <van-row>
              <el-col :span="24">
                <van-radio-group v-model="form.jineType" direction="horizontal">
                  <van-radio name="1">现金</van-radio>
                  <van-radio name="2" @click="initQuan"
                    >无门槛的消费券</van-radio
                  >
                </van-radio-group>
              </el-col>
            </van-row>
            <div v-if="form.jineType == '2'">
              <van-row style="display: flex; margin-top: 0.5em">
                <van-col :span="8" style="display: flex; width: 6em"
                  ><van-radio-group v-model="form.quan1" direction="horizontal">
                    <van-radio @click="quan1Change" name="1">代金券</van-radio>
                  </van-radio-group></van-col
                >
                <van-col :span="14">
                  <input
                    id="daijinquan"
                    class="myInput"
                    style="width: 65%; margin-top: 0px"
                    type="text"
                    placeholder=""
                    maxlength="6"
                    onkeyup="value=value.replace(/\D/g,'')"
                    onafterpaste="value=value.replace(/\D/g,'')"
                    @blur="form.testA = $event.target.value"
                  /><span style="margin-left: 0.2em">份</span></van-col
                >
              </van-row>
              <van-row style="display: flex; margin-top: 10px">
                <van-col :span="8" style="display: flex; width: 6em"
                  ><van-radio-group v-model="form.quan2" direction="horizontal">
                    <van-radio @click="quan2Change" name="1">产品券</van-radio>
                  </van-radio-group></van-col
                >
                <van-col :span="14">
                  <input
                    id="chanpinquan"
                    class="myInput"
                    style="width: 65%; margin-top: 0px"
                    type="text"
                    placeholder=""
                    maxlength="6"
                    onkeyup="value=value.replace(/\D/g,'')"
                    onafterpaste="value=value.replace(/\D/g,'')"
                    @blur="form.testA = $event.target.value"
                  /><span style="margin-left: 0.2em">份</span></van-col
                >
              </van-row>
              <van-row>
                <van-col
                  >代金券、产品券设置建议不能低于该店铺的人均消费。</van-col
                >
              </van-row>
            </div>
          </div>
        </template>
      </van-field>

      <div v-if="form.jineType == '1'">
        <van-field
          required
          v-model="form.darencanbiao"
          label="达人："
          type="digit"
          placeholder="请输入达人到店消费金额"
        />
        <van-field
          required
          v-model="form.renqicanbiao"
          label="大人体验官："
          type="digit"
          placeholder="请输入大人体验官到店消费金额"
        />
      </div>

      <van-field
        required
        v-model="form.wxno"
        label="微信号"
        placeholder="请输入微信号"
      >
        <template slot="button">
          <el-link
            size="small"
            type="primary"
            @click="showWxImage"
            style="font-weight: bolder"
            >示例</el-link
          >
        </template></van-field
      >
      <van-field
        required
        v-model="form.email"
        label="邮箱"
        placeholder="请输入邮箱"
      />
      <van-field
        required
        v-model="form.phone"
        label="联系电话："
        type="digit"
        placeholder="请输入联系电话"
      />
      <van-field
        required
        v-model="form.code"
        label="验证码"
        placeholder="请输入验证码"
        type="digit"
        maxlength="6"
      >
        <template slot="button">
          <el-link
            v-if="countDownTimer"
            size="small"
            type="primary"
            style="font-weight: bolder"
            >{{ countDownTime }}</el-link
          >
          <el-link
            v-else
            size="small"
            type="primary"
            @click="sendSmsCode"
            style="font-weight: bolder"
            >获取验证码</el-link
          >
        </template></van-field
      >
      <div style="margin: 23px; font-weight: bold">
        <van-button
          round
          block
          type="danger"
          native-type="submit"
          @click="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import trends from "@/components/home/trends.vue";
import { ImagePreview } from "vant";
export default {
  components: {
    trends,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    indexType: {
      type: Number,
      default: 0,
    },
    parentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      shuifei: 0.06,
      tyoneFiveNumber: 8,
      tyfiveTenNumber: 2,
      tytenNumber: 0,
      tythreeQNumber: 7,
      tythreeWNumber: 3,
      bzoneFiveNumber: 18,
      bzfiveTenNumber: 5,
      bztenNumber: 3,
      bzthreeQNumber: 20,
      bzthreeWNumber: 10,
      countDownTimer: false,
      countDownTime: 60, // 假设60秒
      checked: true,
      form: {
        douyin: false,
        xiaohongshu: false,
        pingtaiType: "1",
        pingtaiTypes: [],
        tgItmes: [],
        tiyanguan: "",
        jineType: "1",
        quan1: "1",
        quan2: "",
        darencanbiao: "",
        renqicanbiao: "",
        daijinquan: "",
        chanpinquan: "",
        email: "",
        phone: "",
        phoneObj: {},
        code: "",
        wxno: "",
      },
      xhsFensiliangArr: [
        { name: "3000+", count: 0, checked: true },
        { name: "3w+", count: 0, checked: true },
      ],
      dyFensiliangArr: [
        { name: "1-5w", count: 0, checked: true },
        { name: "5-10w", count: 0, checked: true },
        { name: "10w+", count: 0, checked: true },
      ],
    };
  },
  watch: {},
  computed: {
    baseUrl() {
      if (location.href.indexOf("www.darenlaiye.com") > 0) {
        return "https://e.darenlaiye.com/application/api";
      } else if (location.href.indexOf("localhost") > 0) {
        return "http://127.0.0.1:9090/api";
      }
      return "https://e_test.darenlaiye.com/application/api";
    },
  },
  methods: {
    form3dycheckboxChange(value) {
      if (value) {
        this.dyFensiliangArr.forEach((e) => {
          e.checked = true;
        });
      } else {
        this.dyFensiliangArr.forEach((e) => {
          e.checked = false;
        });
      }
    },
    form3xhscheckboxChange(value) {
      if (value) {
        this.xhsFensiliangArr.forEach((e) => {
          e.checked = true;
        });
      } else {
        this.xhsFensiliangArr.forEach((e) => {
          e.checked = false;
        });
      }
    },
    showWxImage() {
      const imageURL = require("@/assets/home/wxshili.png");
      ImagePreview([imageURL]); // 使用ImagePreview展示图片
    },
    initQuan() {
      const chanpinquan = document.getElementById("chanpinquan");
      chanpinquan.disabled = true;

      this.form.darencanbiao = "";
      this.form.renqicanbiao = "";

      this.form.quan1 = "1";
      this.form.quan2 = "";
    },
    quan1Change() {
      this.form.quan1 = "1";
      this.form.quan2 = "";
      const chanpinquan = document.getElementById("chanpinquan");
      chanpinquan.value = "";
      chanpinquan.disabled = true;

      const daijinquan = document.getElementById("daijinquan");
      daijinquan.disabled = false;
    },
    quan2Change() {
      this.form.quan1 = "";
      this.form.quan2 = "1";
      const daijinquan = document.getElementById("daijinquan");
      daijinquan.value = "";
      daijinquan.disabled = true;

      const chanpinquan = document.getElementById("chanpinquan");
      chanpinquan.disabled = false;
    },
    sendSmsCode() {
      if (this.countDownTimer) {
        return;
      }
      if (!this.form.phone) {
        this.$toast("请输入联系电话");
        return false;
      } else {
        let reg = /^1\d{10}$/;
        if (!reg.test(this.form.phone)) {
          this.$toast("联系电话格式不正确");
          return false;
        }
      }
      if (!this.errorMessage) {
        this.countDownTimer = true;
        // 发送短信验证码逻辑
        this.requestApi("api.biz.business.eval.log.sms", {
          phone: this.form.phone,
        })
          .then((resp) => {
            this.$toast("获取验证码成功！");
            this.form.phoneObj.timer = setInterval(() => {
              if (this.countDownTime == 1) {
                this.countDownFinished();
              }
              this.countDownTime = this.countDownTime - 1;
            }, 1000);
          })
          .catch((err) => {
            // 模拟计时器
            this.countDownTimer = false;

            this.$notify({
              message: err.msg,
              type: "danger",
            });
          })
          .finally(() => {});
      }
    },
    countDownFinished() {
      if (this.form.phoneObj.timer) {
        clearInterval(this.form.phoneObj.timer);
      }
      this.countDownTimer = false;
      this.countDownTime = 60;
    },

    clearChanpinVal() {
      const chanpinquan = document.getElementById("chanpinquan");
      chanpinquan.value = "";
      this.form.chanpinquan = "";
    },
    clearDaijinVal() {
      const daijinquan = document.getElementById("daijinquan");
      daijinquan.value = "";
      this.form.daijinquan = "";
    },
    onClickLeft() {
      this.$emit("custom-event-hidetwo");
    },
    requestApi(actions, params) {
      params.platform = "guanwang";
      return new Promise((resolve, reject) => {
        fetch(`${this.baseUrl}?action=${actions}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ params: params }),
        })
          .then((response) => response.json()) // 如果服务器返回JSON，则解析它
          .then((resp) => {
            if (resp.code == 0) {
              resolve(resp);
            } else {
              reject(resp);
            }
          }) // 处理服务器返回的数据
          .catch((error) => {
            reject(error);
          }); // 处理错误
      });
    },
    submit() {
      if (this.indexType == 0) {
        if (!this.form.pingtaiType) {
          this.$toast("请至少选择1项推广平台");
          return false;
        }
      } else {
        if (!this.form.douyin && !this.form.xiaohongshu) {
          this.$toast("请至少选择1项推广平台");
          return false;
        }
      }
      if (this.form.jineType == "1") {
        if (!this.form.darencanbiao) {
          this.$toast("请输入达人到店消费金额");
          return false;
        } else if (Number(this.form.darencanbiao) < 25) {
          this.$toast("达人到店消费金额不能低于25元");
          return false;
        }
        if (!this.form.renqicanbiao) {
          this.$toast("请输入大人体验官消费金额");
          return false;
        } else if (Number(this.form.renqicanbiao) < 25) {
          this.$toast("大人体验官消费金额不能低于25元");
          return false;
        }
      } else {
        if (this.form.quan1 == "1") {
          const daijinquan = document.getElementById("daijinquan");
          this.form.daijinquan = daijinquan.value;
          if (!this.form.daijinquan) {
            this.$toast("请输入代金券份数");
            return false;
          } else if (Number(this.form.daijinquan) == 0) {
            this.$toast("请至少输入1份代金券");
            return false;
          }
        } else if (this.form.quan2 == "1") {
          const chanpinquan = document.getElementById("chanpinquan");
          this.form.chanpinquan = chanpinquan.value;
          if (!this.form.chanpinquan) {
            this.$toast("请输入产品券份数");
            return false;
          } else if (Number(this.form.chanpinquan) == 0) {
            this.$toast("请至少输入1份产品券");
            return false;
          }
        }
      }
      if (!this.form.wxno) {
        this.$toast("请输入微信号");
        return false;
      } else {
        this.form.wxno = this.form.wxno.replace(/\s+/g, "");
        if (!this.form.wxno) {
          this.$message.error("请输入微信号");
          return false;
        }
      }
      if (!this.form.email) {
        this.$toast("请输入邮箱");
        return false;
      } else {
        this.form.email = this.form.email.replace(/\s+/g, "");
        if (!this.form.email) {
          this.$message.error("请输入邮箱");
          return false;
        }
      }
      if (!this.form.phone) {
        this.$toast("请输入联系电话");
        return false;
      } else {
        let reg = /^1\d{10}$/;
        if (!reg.test(this.form.phone)) {
          this.$toast("联系电话格式不正确");
          return false;
        }
      }
      if (!this.form.code) {
        this.$toast("请输入验证码");
        return false;
      }

      let productType = 0;
      let dyFlag = 0;
      let xhsFlag = 0;
      let oneFiveWNumber = 0;
      let fiveTenWNumber = 0;
      let tenPlusWNumber = 0;
      let threeQNumber = 0;
      let threeWNumber = 0;

      let expertTotalMoney = 0;
      let drUserTotalMoney = 0;
      let consultTotalMoney = 0;
      let platformTotalMoney = 0;

      let appraisalMoney = 0;

      if (this.indexType == 0) {
        productType = 1;
        if (this.form.pingtaiType == "1") {
          dyFlag = 1;
          xhsFlag = 0;
          oneFiveWNumber = this.tyoneFiveNumber;
          fiveTenWNumber = this.tyfiveTenNumber;
          tenPlusWNumber = this.tytenNumber;
        } else {
          xhsFlag = 1;
          dyFlag = 0;
          threeQNumber = this.tythreeQNumber;
          threeWNumber = this.tythreeWNumber;
        }
        expertTotalMoney = 7420;
        drUserTotalMoney = 3180;

        appraisalMoney = 10000;
      } else if (this.indexType == 1) {
        productType = 2;
        if (this.form.douyin) {
          dyFlag = 1;
          oneFiveWNumber = this.bzoneFiveNumber;
          fiveTenWNumber = this.bzfiveTenNumber;
          tenPlusWNumber = this.bztenNumber;
          expertTotalMoney += 23426;
          drUserTotalMoney += 8374;
          appraisalMoney += 30000;
        }
        if (this.form.xiaohongshu) {
          xhsFlag = 1;
          threeQNumber = this.bzthreeQNumber;
          threeWNumber = this.bzthreeWNumber;
          expertTotalMoney += 24380;
          drUserTotalMoney += 7420;
          appraisalMoney += 30000;
        }
      }

      let baifenbai = 100;
      let shuifeibfb = this.shuifei * baifenbai;

      let expertMoney = 0;
      let drUserMoney = 0;
      let consumeTicketType = 0;
      let consumeTicketNumber = 0;
      if (this.form.jineType == "1") {
        expertMoney = this.form.darencanbiao;
        drUserMoney = this.form.renqicanbiao;
      } else {
        consumeTicketType = this.form.quan1 == "1" ? "1" : "2";
        if (consumeTicketType == "1") {
          consumeTicketNumber = this.form.daijinquan;
        } else {
          consumeTicketNumber = this.form.chanpinquan;
        }
      }
      this.requestApi("api.internet.traffic.promotion.create", {
        productType: productType,
        startDate: new Date(this.parentData.startTime).getTime(),
        endDate: new Date(this.parentData.endTime).getTime(),
        activity: this.parentData.huodong.join(","),
        companyName: this.parentData.companyName,
        shopAddress: this.parentData.shopAddress,
        companyProfile: this.parentData.comment,
        dyFlag: Number(dyFlag),
        xhsFlag: Number(xhsFlag),
        oneFiveWNumber: Number(oneFiveWNumber),
        fiveTenWNumber: Number(fiveTenWNumber),
        tenPlusWNumber: Number(tenPlusWNumber),
        threeQNumber: Number(threeQNumber),
        threeWNumber: Number(threeWNumber),
        drUserNumber: Number(this.form.tiyanguan),
        consumeType: Number(this.form.jineType),
        expertMoney: Number(expertMoney),
        drUserMoney: Number(drUserMoney),
        consumeTicketType: Number(consumeTicketType),
        consumeTicketNumber: Number(consumeTicketNumber),
        userWechat: this.form.wxno,
        userEmail: this.form.email,
        userPhone: this.form.phone,
        appraisalMoney: appraisalMoney,
        code: this.form.code,
        expertTotalMoney: Number(expertTotalMoney),
        drUserTotalMoney: Number(drUserTotalMoney),
        consultTotalMoney: Number(consultTotalMoney),
        platformTotalMoney: Number(platformTotalMoney),
      })
        .then((resp) => {
          this.$toast("提交成功");
          this.$emit("custom-event");
          //关闭页面
        })
        .catch((err) => {
          this.$toast(err.msg);
          return false;
        })
        .finally(() => {});
    },
  },
  created() {
    if (this.indexType == 0) {
      this.xhsFensiliangArr[0].count = this.tythreeQNumber;
      this.xhsFensiliangArr[1].count = this.tythreeWNumber;
      this.dyFensiliangArr[0].count = this.tyoneFiveNumber;
      this.dyFensiliangArr[1].count = this.tyfiveTenNumber;
      this.dyFensiliangArr[2].count = this.tytenNumber;
      this.form.tiyanguan = 30;
    } else {
      this.xhsFensiliangArr[0].count = this.bzthreeQNumber;
      this.xhsFensiliangArr[1].count = this.bzthreeWNumber;
      this.dyFensiliangArr[0].count = this.bzoneFiveNumber;
      this.dyFensiliangArr[1].count = this.bzfiveTenNumber;
      this.dyFensiliangArr[2].count = this.bztenNumber;
      this.form.tiyanguan = 50;
      this.xhsFensiliangArr[0].checked = false;
      this.xhsFensiliangArr[1].checked = false;
      this.dyFensiliangArr[0].checked = false;
      this.dyFensiliangArr[1].checked = false;
      this.dyFensiliangArr[2].checked = false;
    }
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='less' scoped>
/deep/ .van-cell__value {
  border: none;
}
/deep/ .van-grid-item__content {
  padding: 0px 0px;
}
/deep/ .van-grid-item__content--center {
  align-items: flex-start;
}

/deep/ .myInput {
  border: 1px solid #c4c9cf;
  margin-top: 8px;
  height: 29px;
}
/deep/ .van-checkbox__icon--disabled .van-icon {
  background-color: #dddddd; /* 禁用状态下的背景颜色 */
}
input:disabled {
  background-color: #c8c9cc; /* 禁用状态下的背景颜色 */
}
/deep/ #renInput {
  color: #000;
  border: 1px solid #c4c9cf;
}
// /deep/ .van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon {
//   background-color: #fbfbfb !important;
// }
</style>