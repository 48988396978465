<template>
  <div
    class="drInstitute"
    style="background-color: #f7f8f9"
  >
    <div
      class="bH"
      :style="{
        backgroundImage: 'url(' + require('@/assets/home/bh_center.svg') + ')',
      }"
    >
      <div
        class="bh_left"
        :style="{
          backgroundImage: 'url(' + require('@/assets/home/bh_left.svg') + ')',
        }"
      />

      <div
        class="bh_right"
        :style="{
          backgroundImage: 'url(' + require('@/assets/home/bh_right.svg') + ')',
        }"
      ></div>

      <div class="body">
        <div class="title">大人来也致力于为客户提供真实、有效的实证调查</div>
        <div class="sub_title">
          大人研究所的合作伙伴包括高校、研究机构、行业协会、连锁企业、咨询智库，研究合作请通过邮件联系我们
          <span
            class="email"
            @click="mailto('marketing@huami-tech.com')"
          >marketing@huami-tech.com</span>
        </div>
        <div class="sub_title2">
          <div>大人研究所的合作伙伴</div>
          <div>含高校、研究机构、行业协会、连锁企业、咨询智库，</div>
          <div>
            研究合作请通过邮件联系我们
            <span
              class="email"
              @click="mailto('marketing@huami-tech.com')"
            >marketing@huami-tech.com</span>
          </div>
        </div>
        <div class="desc"></div>
        <div
          class="desc_show_btn"
          @click="showDesc()"
        ></div>
        <div class="desc2"></div>
        <img
          width="100%"
          :src="require('@/assets/dr_institute/head.png')"
        />
      </div>
    </div>

    <trends
      :data="trendsData"
      @showAll="
        () => {
          $router.push('/dryjsTrends');
        }
      "
    />
  </div>
</template>

<script>
import trends from "@/components/home/trends.vue";

export default {
  components: {
    trends,
  },
  props: {},
  data() {
    return {
      trendsData: [
        {
          "guid": "1115947061030686736",
          "title": "【小调查】消费者最爱即时折扣，餐饮人卷了价格还要卷创意",
          "trendsDesc": "  据美团数据显示，本次国庆假期的前5天，餐饮堂食消费日均值与2023年同期相比，实现了33.4%的显著增长。\n\n\n\n    2024年国庆黄金周开启以来，上海市政府推出了“乐品上海”餐饮消费券活动。上海市商务委表示，假期期间，在餐饮消费券的加持下，朋友聚餐，家庭聚餐等餐饮消费场景显著增加，餐饮消费呈现集聚消费、家庭消费增长的趋势。\n\n\n\n    针对消费券的使用，网友们在小红书等社交媒体上分享基于不同手气、不同折扣的“使用攻略”，形成了#消费券攻略 等热门话题。既有吐槽，也有十分实用的“羊毛战术”。总之，实打实的折扣一定程度上起到了“把消费者带进餐厅”的效果。\n\n\n\n    在我们的调查中，有97.46%的受访者表示愿意因促销活动尝试新餐厅/菜品，这一数据不仅证明了促销活动在促进消费方面的有效性，也反映了消费者对于优惠和促销活动的高度敏感性和积极响应。",
          "trendsGuid": "1115947061030686736",
          "headImage": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-10-14/image/5e2e67f0aa0d0929f85782c0fe95c748.png",
          "headImageMax": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-10-14/image/665728d7ce98e0e9e04afa1a6cc6bd4a.png",
          "headImageMin": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-10-14/image/e35a2c744079e308905f6ec23cf34760.png",
          "contentLink": "https://mp.weixin.qq.com/s/818pJxA8WWkEgu9T0eH0-A",
          "tag": "",
          "type": "1",
          "optStatus": 1,
          "isDelete": 0,
          "createTime": "2024-10-13T01:48:35.000+00:00",
          "updateTime": "2024-10-13T01:48:43.000+00:00",
          "sort": 1
        },
        {
          "guid": "1115947061030686735",
          "title": "【大人研究所】民宿≠民诉",
          "trendsDesc": "随着文旅产业复苏，民宿行业凭借其独特的魅力和个性化服务迅速崛起，成为追求独特旅行体验的旅行者的首选。然而，在这股热潮背后，一系列问题也逐渐浮出水面。如何避免民宿在发展过程中成为“民诉”的集合体，成为现今民宿行业发展的重大难题。本次调研旨在了解消费者在预订和住宿过程中遇到的权益问题，以及他们对民宿服务的满意度。",
          "trendsGuid": "1115947061030686735",
          "headImage": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-30/image/ad7f626c41df7164b6f9c1140770e233.png",
          "headImageMax": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-30/image/aff13846023c3f1d26fa8b184084ae17.png",
          "headImageMin": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-30/image/b4977d1ebad76bb0378a1528530d2b45.png",
          "contentLink": "https://mp.weixin.qq.com/s/q93vBqigB7X1gPeD4oafQA",
          "tag": "",
          "type": "1",
          "optStatus": 1,
          "isDelete": 0,
          "createTime": "2024-09-29T01:10:41.000+00:00",
          "updateTime": "2024-09-29T01:10:52.000+00:00",
          "sort": 2
        },
        {
          "guid": "1115947061030686734",
          "title": "【大人研究所】有品牌加持和标准化管理的“小吃小喝小聚”更令人安心",
          "trendsDesc": "\"小吃小喝小聚\"作为一种更“轻”的餐饮趋势，日益受到消费者的青睐。其品类涵盖特色小吃、快餐简餐、咖啡茶饮、烘焙甜品、健康轻食等。伴随着消费者健康意识提升、生活节奏快、追求性价比、口味多样化需求、社交媒体影响，甚至资本青睐和技术进步，\"小吃小喝小聚\"在餐饮市场上体现出强烈的竞争力。\n\n \n\n本次调查探究了受访者对“小吃小喝小聚”餐饮类型的消费偏好、消费行为模式及期望。",
          "trendsGuid": "1115947061030686734",
          "headImage": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/d4d47efde8a087b1e53b5ed02b481b8a.png",
          "headImageMax": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/a7aed734faaafe1302d5d1afbf6c04e3.png",
          "headImageMin": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/e0487b281fdd553bed3b7b1d6963f8fc.png",
          "contentLink": "https://mp.weixin.qq.com/s/yv5DojedzcgWHUP5f1PXGA",
          "tag": "",
          "type": "1",
          "optStatus": 1,
          "isDelete": 0,
          "createTime": "2024-09-15T05:25:31.000+00:00",
          "updateTime": "2024-09-15T05:25:39.000+00:00",
          "sort": 3
        },
        {
          "guid": "1115947061030686733",
          "title": "【大人研究所】 数字点餐：社交焦虑的智能解药",
          "trendsDesc": "随着数字时代的脚步加速，我们的日常正在被科技重塑。在餐饮领域，这一变革尤为显著，传统点餐模式已不再是唯一选择。消费者现在追求的，是一种能够提供快速、便捷、且高度个性化的用餐体验，而数字化点餐正满足了这些期待。它不仅提升了餐饮业的运营效率，更通过减少面对面交流，为顾客带来了一种全新的轻松用餐方式。\n\n \n\n在本次调研中，我们搜集了数字化点餐相较于传统方式的优势以及现有的技术问题。调研数据显示，绝大多数受访者（67.83%）认为数字化转型对餐饮业至关重要。数字化点餐不仅因其便捷性和快速性（93.91%）受到青睐，更因其提供的透明化菜品信息和定价（74.78%）而备受好评。",
          "trendsGuid": "1115947061030686733",
          "headImage": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-09/image/f65e943bcfff0da64a0b4cfbbcc42a14.png",
          "headImageMax": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-09/image/8b2adcc2337cafb1605e9d31b6d06541.png",
          "headImageMin": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-09-18/image/844a145128ff7ef106bf4b14b6421769.png",
          "contentLink": "https://mp.weixin.qq.com/s/vxVr9aSKvSzyJcjEDTzLwg",
          "tag": "",
          "type": "1",
          "optStatus": 1,
          "isDelete": 0,
          "createTime": "2024-09-08T08:21:31.000+00:00",
          "updateTime": "2024-09-08T08:21:40.000+00:00",
          "sort": 4
        },
        {
          "guid": "1115947061030686721",
          "title": "餐饮促销活动吸引力调查问卷",
          "trendsDesc": "帮助餐饮从业者了解消费者对促销活动的偏好、期望和接受度，以便设计出更具吸引力和有效性的促销活动，提升客流量和销售额。",
          "trendsGuid": "1115947061030686721",
          "headImage": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/f24160dbfd381fdfc13e56b24597a9f7.png",
          "headImageMax": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/eec346128f07494675d777439aab70ec.png",
          "headImageMin": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/e65e0e6a421a57cb81134cd0881d9596.png",
          "contentLink": "141",
          "tag": "",
          "type": "2",
          "optStatus": 1,
          "isDelete": 0,
          "createTime": "2024-07-25T09:16:57.000+00:00",
          "updateTime": "2024-07-25T09:16:57.000+00:00",
          "sort": 5
        },
        {
          "guid": "1115947061030686722",
          "title": "餐厅食品安全信任度调查问卷",
          "trendsDesc": "了解消费者对餐厅食品安全的信任度以及影响信任度的因素，以便餐饮从业者采取相应措施提升食品安全水平，增强消费者信心。",
          "trendsGuid": "1115947061030686722",
          "headImage": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/6d3a786c64d9a5655765690701a3a669.png",
          "headImageMax": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/edd1613589dd6793ac36fa7285772141.png",
          "headImageMin": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/f11b8c78ddfed23851a1724c827cd96a.png",
          "contentLink": "142",
          "tag": "",
          "type": "2",
          "optStatus": 1,
          "isDelete": 0,
          "createTime": "2024-07-25T09:49:11.000+00:00",
          "updateTime": "2024-07-25T09:49:11.000+00:00",
          "sort": 6
        },
        {
          "guid": "1115947061030686723",
          "title": "餐饮品牌社交媒体销量提升调查问卷",
          "trendsDesc": "了解消费者对餐饮品牌在社交媒体上的内容、活动和互动方式的看法和偏好，以便餐饮品牌更好地利用社交媒体提升品牌知名度和销量。",
          "trendsGuid": "1115947061030686723",
          "headImage": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/a4fc6c497699ee02a3ea1a2a818261f4.png",
          "headImageMax": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/71359250e02754b980c158cbf6977b2f.png",
          "headImageMin": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/d9cec53d9256df3e28ab4fc52e959a56.png",
          "contentLink": "143",
          "tag": "",
          "type": "2",
          "optStatus": 1,
          "isDelete": 0,
          "createTime": "2024-07-25T09:49:11.000+00:00",
          "updateTime": "2024-07-25T09:49:11.000+00:00",
          "sort": 7
        },
        {
          "guid": "1115947061030686724",
          "title": "餐饮企业数字化转型调查问卷",
          "trendsDesc": "了解消费者对餐饮企业数字化转型的看法、体验和期望，以便餐饮企业在转型过程中更好地满足消费者需求，提升服务质量，并优化运营流程。",
          "trendsGuid": "1115947061030686724",
          "headImage": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/3eb454183ea49e19ed761fe25af0cc5b.png",
          "headImageMax": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/ee9927baa8389d493c5b03f0b412ab13.png",
          "headImageMin": "https://huami-merchant.oss-cn-hangzhou.aliyuncs.com/merchant-upload/2024-07-25/image/639f0fb053c5479d5d0527da17b26c80.png",
          "contentLink": "144",
          "tag": "",
          "type": "2",
          "optStatus": 1,
          "isDelete": 0,
          "createTime": "2024-07-25T09:49:11.000+00:00",
          "updateTime": "2024-07-25T09:49:11.000+00:00",
          "sort": 8
        }
      ],
    };
  },
  watch: {},
  computed: {
    baseUrl() {
      if (location.href.indexOf("www.darenlaiye.com") > 0) {
        return "https://e.darenlaiye.com/application/api";
      }
      return "https://e_test.darenlaiye.com/application/api";
    },
  },
  methods: {
    getTrendsData() {
      this.requestApi(
        "admin.drly.company.website.dryjs.trends.sort.list.gw",
        {}
      )
        .then((resp) => {
          this.trendsData = resp.data.list;
        })
        .catch((err) => { })
        .finally(() => { });
    },

    requestApi(actions, params) {
      params.platform = "guanwang";
      return new Promise((resolve, reject) => {
        fetch(`${this.baseUrl}?action=${actions}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ params: params }),
        })
          .then((response) => response.json()) // 如果服务器返回JSON，则解析它
          .then((resp) => {
            if (resp.code == 0) {
              resolve(resp);
            } else {
              reject(resp);
            }
          }) // 处理服务器返回的数据
          .catch((error) => {
            reject(error);
          }); // 处理错误
      });
    },
    mailto(url) {
      window.location.href = "mailto:" + url;
    },
    showDesc() {
      console.log(this.$(".desc2"));
      this.$(".desc_show_btn").css("display", "none");
      this.$(".desc2").css("display", "block");
    },
  },
  created() { },
  mounted() {
    this.trendsData = []
    this.getTrendsData();
  },
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .bH {
    max-height: 542px;
    padding-top: 80px;
    .body {
      max-height: 462px;
      .desc2 {
        display: none;
      }
      .desc_show_btn {
        display: none;
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .bH {
    max-height: 522px;

    padding-top: 60px;
    .body {
      max-height: 462px;
      .desc2 {
        display: none;
      }
      .desc_show_btn {
        display: none;
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .bH {
    .bh_left {
      display: none;
    }
    .bh_right {
      display: none;
    }
    .body {
      //   padding-top: 3rem !important;
      padding-bottom: 0px !important;
      .title {
        font-size: 1.5rem !important;
        line-height: 1.6 !important;
        padding: 0px 0.5em;
      }
      .sub_title {
        display: none !important;
        .email {
          text-decoration: underline;
          color: #4a8ee9;
        }
        .email:hover {
          cursor: pointer;
          text-decoration: underline;
          color: #4a8ee9;
        }
      }
      .sub_title2 {
        display: block !important;
        margin: 0px auto;
        margin-top: 32px;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #111925;
        text-align: center;
        width: 90%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        .email {
          text-decoration: underline;
          color: #4a8ee9;
        }
        .email:hover {
          cursor: pointer;
          text-decoration: underline;
          color: #4a8ee9;
        }
      }
      .desc {
        display: none !important;
      }
      .desc2 {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: rgba(15, 25, 37, 0.65);
        text-align: center;
        width: 90%;
        margin: 0px auto;
        margin-top: 2em;
        display: none;
      }
      .desc_show_btn {
        padding-bottom: 30px;
        padding-top: 1.5rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
        display: block;
        text-align: center;
        i {
          margin-left: 3px;
        }
      }
    }
  }
}

.bH {
  background-color: #f7f8f9;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-position: top;
  background-size: auto 400px;
  background-repeat: no-repeat;
  .bh_left {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 400px;

    height: 400px;
  }
  .bh_center {
    position: absolute;
    top: -10%;
    left: 0%;
    width: 100%;
  }
  .bh_right {
    position: absolute;
    top: 0px;
    right: -20px;
    height: 400px;
    width: 200px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .body {
    min-height: 362px;
    max-width: 1280px;
    margin: 0px auto;
    position: relative;
    padding-top: 3.75rem;
    padding-bottom: 2rem;
    z-index: 1;
    background: transparent;
    .title {
      font-size: 3.25rem;
      line-height: 1em;
      text-align: center;
      color: #111925;
      font-weight: bolder;
    }
    .sub_title {
      margin: 0px auto;
      margin-top: 32px;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      color: #111925;
      font-weight: bolder;
      width: 90%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      .email {
        text-decoration: underline;
        color: #4a8ee9;
      }
      .email:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #4a8ee9;
      }
    }
    .sub_title2 {
      display: none;
      .email {
        text-decoration: underline;
        color: #4a8ee9;
      }
      .email:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #4a8ee9;
      }
    }
    .desc {
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: rgba(15, 25, 37, 0.65);
      text-align: center;
      width: 90%;
      margin: 0px auto;
      margin-top: 2em;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
}
</style>