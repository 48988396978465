<template>
  <div>
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>
    <van-form>
      <van-field
        required
        readonly
        clickable
        label="推广活动"
        placeholder="请选择推广活动"
        @click="huodongShow = true"
      >
        <template #input>
          <div v-if="selectedLabels.length > 0">
            {{ selectedLabels.join(", ") }}
          </div>
          <div v-else><span style="color: #c4c9cf">请选择推广活动</span></div>
        </template>
      </van-field>
      <van-popup v-model="huodongShow" position="bottom" style="height: 35%">
        <van-checkbox-group v-model="form.huodong">
          <van-checkbox
            style="margin: 10px"
            shape="square"
            v-for="option in options"
            :name="option.value"
            :key="option.value"
          >
            <span>{{ option.label }}</span>
          </van-checkbox>
        </van-checkbox-group>
      </van-popup>
      <van-field
        required
        v-model="form.companyName"
        label="企业名称"
        placeholder="请输入企业名称"
      />
      <van-field
        required
        v-model="form.shopAddress"
        label="门店地址"
        placeholder="请输入门店地址"
      />
      <van-field
        required
        v-model="form.comment"
        label="业务简介"
        placeholder="请输入门店业务简介，如：甜品店"
      />
      <van-field
        required
        v-model="form.wxno"
        label="微信号"
        placeholder="请输入微信号"
        ><template slot="button">
          <el-link
            size="small"
            type="primary"
            @click="showWxImage"
            style="font-weight: bolder"
            >示例</el-link
          >
        </template></van-field
      >
      <van-field
        required
        v-model="form.email"
        label="邮箱"
        placeholder="请输入邮箱"
      />
      <van-field
        required
        v-model="form.phone"
        label="联系电话："
        type="digit"
        placeholder="请输入联系电话"
      />
      <van-field
        required
        v-model="form.code"
        label="验证码"
        type="digit"
        placeholder="请输入验证码"
        maxlength="6"
      >
        <template slot="button">
          <el-link
            v-if="countDownTimer"
            size="small"
            type="primary"
            style="font-weight: bolder"
            >{{ countDownTime }}</el-link
          >
          <el-link
            v-else
            size="small"
            type="primary"
            @click="sendSmsCode"
            style="font-weight: bolder"
            >获取验证码</el-link
          >
        </template></van-field
      >
      <div style="margin: 23px">
        <van-button
          round
          block
          type="danger"
          native-type="submit"
          @click="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <van-popup
      v-model="showTwo"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <appAttracUsersSubmitTwo
        :title="title"
        @custom-event="closeTwo"
      ></appAttracUsersSubmitTwo>
    </van-popup>
  </div>
</template>
<script>
import trends from "@/components/home/trends.vue";
import appAttracUsersSubmitTwo from "@/views/appAttractUsers/standard_two.vue";
import { ImagePreview } from "vant";
export default {
  components: {
    trends,
    appAttracUsersSubmitTwo,
  },
  props: {
    title: {
      type: String,
      default: "",
    },

    parentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      shuifei: 0.06,
      countDownTimer: false,
      countDownTime: 60, // 假设60秒
      show: true,
      showTwo: false,
      startTime: false,
      endTime: false,
      selectedValues: [], // 选中的值
      huodongShow: false,
      options: [
        {
          value: "1",
          label: "新店体验",
        },
        {
          value: "2",
          label: "新品体验",
        },
        {
          value: "3",
          label: "节假日活动",
        },
        {
          value: "4",
          label: "数字营销",
        },
        {
          value: "5",
          label: "口碑传播",
        },
        {
          value: "6",
          label: "获客消费",
        },
      ],
      appShow: true,
      appShow: true,
      form: {
        huodong: [],
        companyName: "",
        shopAddress: "",
        comment: "",
        wxno: "",
        email: "",
        phone: "",
        phoneObj: {},
        code: "",
      },
    };
  },
  watch: {},
  computed: {
    baseUrl() {
      if (location.href.indexOf("www.darenlaiye.com") > 0) {
        return "https://e.darenlaiye.com/application/api";
      } else if (location.href.indexOf("localhost") > 0) {
        return "http://127.0.0.1:9090/api";
      }
      return "https://e_test.darenlaiye.com/application/api";
    },
    selectedLabels() {
      return this.options
        .filter(
          (option) =>
            this.form.huodong && this.form.huodong.includes(option.value)
        )
        .map((option) => option.label);
    },
  },
  methods: {
    showWxImage() {
      const imageURL = require("@/assets/home/wxshili.png");
      ImagePreview([imageURL]); // 使用ImagePreview展示图片
    },
    closeTwo() {
      this.showTwo = false;
    },
    onClickLeft() {
      this.$emit("custom-event-left");
    },
    sendSmsCode() {
      if (this.countDownTimer) {
        return;
      }
      if (!this.form.phone) {
        this.$toast("请输入联系电话");
        return false;
      } else {
        let reg = /^1\d{10}$/;
        if (!reg.test(this.form.phone)) {
          this.$toast("联系电话格式不正确");
          return false;
        }
      }

      if (!this.errorMessage) {
        this.countDownTimer = true;
        // 发送短信验证码逻辑
        this.requestApi("api.biz.business.eval.log.sms", {
          phone: this.form.phone,
        })
          .then((resp) => {
            this.$toast("获取验证码成功！");
            this.form.phoneObj.timer = setInterval(() => {
              if (this.countDownTime == 1) {
                this.countDownFinished();
              }
              this.countDownTime = this.countDownTime - 1;
            }, 1000);
          })
          .catch((err) => {
            // 模拟计时器
            this.countDownTimer = false;

            this.$notify({
              message: err.msg,
              type: "danger",
            });
          })
          .finally(() => {});
      }
    },
    countDownFinished() {
      if (this.form.phoneObj.timer) {
        clearInterval(this.form.phoneObj.timer);
      }
      this.countDownTimer = false;
      this.countDownTime = 60;
    },
    requestApi(actions, params) {
      params.platform = "guanwang";
      return new Promise((resolve, reject) => {
        fetch(`${this.baseUrl}?action=${actions}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ params: params }),
        })
          .then((response) => response.json()) // 如果服务器返回JSON，则解析它
          .then((resp) => {
            if (resp.code == 0) {
              resolve(resp);
            } else {
              reject(resp);
            }
          }) // 处理服务器返回的数据
          .catch((error) => {
            reject(error);
          }); // 处理错误
      });
    },
    submit() {
      if (this.form.huodong.length == 0) {
        this.$toast("请选择推广活动");
        return;
      }
      if (!this.form.companyName) {
        this.$toast("请输入企业名称");
        return;
      } else {
        this.form.companyName = this.form.companyName.replace(/\s+/g, "");
        if (!this.form.companyName) {
          this.$message.error("请输入企业名称");
          return false;
        }
      }
      if (!this.form.shopAddress) {
        this.$toast("请输入门店地址");
        return;
      } else {
        this.form.shopAddress = this.form.shopAddress.replace(/\s+/g, "");
        if (!this.form.shopAddress) {
          this.$message.error("请输入门店地址");
          return false;
        }
      }
      if (!this.form.comment) {
        this.$toast("请输入门店业务简介，如：甜品店");
        return;
      } else {
        this.form.comment = this.form.comment.replace(/\s+/g, "");
        if (!this.form.comment) {
          this.$message.error("请输入门店业务简介，如：甜品店");
          return false;
        }
      }
      if (!this.form.wxno) {
        this.$toast("请输入微信号");
        return false;
      } else {
        this.form.wxno = this.form.wxno.replace(/\s+/g, "");
        if (!this.form.wxno) {
          this.$message.error("请输入微信号");
          return false;
        }
      }
      if (!this.form.email) {
        this.$toast("请输入邮箱");
        return false;
      } else {
        this.form.email = this.form.email.replace(/\s+/g, "");
        if (!this.form.email) {
          this.$message.error("请输入邮箱");
          return false;
        }
      }
      if (!this.form.phone) {
        this.$toast("请输入联系电话");
        return false;
      } else {
        let reg = /^1\d{10}$/;
        if (!reg.test(this.form.phone)) {
          this.$toast("联系电话格式不正确");
          return false;
        }
      }
      if (!this.form.code) {
        this.$toast("请输入验证码");
        return false;
      }

      let dyFlag = 0;
      let xhsFlag = 0;

      let oneFiveWNumber = 0;
      let fiveTenWNumber = 0;
      let tenPlusWNumber = 0;
      let threeQNumber = 0;
      let threeWNumber = 0;

      let expertTotalMoney = 0;
      let drUserTotalMoney = 0;
      let consultTotalMoney = 0;
      let platformTotalMoney = 0;

      expertTotalMoney = this.parentData.darenfeiyong / 100;
      drUserTotalMoney = this.parentData.darentiyanguanfeiyong / 100;
      consultTotalMoney = 10000;

      if (this.parentData.douyin) {
        for (let i = 0; i < this.parentData.dyFensiliangArr.length; i++) {
          let obj = this.parentData.dyFensiliangArr[i];
          if (i == 0 && obj.checked && obj.count) {
            oneFiveWNumber = obj.count;
          }
          if (i == 1 && obj.checked && obj.count) {
            fiveTenWNumber = obj.count;
          }
          if (i == 2 && obj.checked && obj.count) {
            tenPlusWNumber = obj.count;
          }
        }
        dyFlag = 1;
      }
      debugger;
      if (this.parentData.xiaohongshu) {
        xhsFlag = 1;
        for (let i = 0; i < this.parentData.xhsFensiliangArr.length; i++) {
          let obj = this.parentData.xhsFensiliangArr[i];
          if (i == 0 && obj.checked && obj.count) {
            threeQNumber = obj.count;
          }
          if (i == 1 && obj.checked && obj.count) {
            threeWNumber = obj.count;
          }
        }
      }

      let expertMoney = 0;
      let drUserMoney = 0;
      let consumeTicketType = 0;
      let consumeTicketNumber = 0;
      if (this.parentData.jineType == "1") {
        expertMoney = this.parentData.darencanbiao;
        drUserMoney = this.parentData.renqicanbiao;
      } else {
        consumeTicketType = this.parentData.quan;
        if (this.parentData.quan == "1") {
          consumeTicketNumber = this.parentData.daijinquan;
        } else {
          consumeTicketNumber = this.parentData.chanpinquan;
        }
      }

      this.requestApi("api.internet.traffic.promotion.create", {
        productType: 3,
        startDate: new Date(this.parentData.startTime).getTime(),
        endDate: new Date(this.parentData.endTime).getTime(),
        activity: this.form.huodong.join(","),
        companyName: this.form.companyName,
        shopAddress: this.form.shopAddress,
        companyProfile: this.form.comment,
        dyFlag: Number(dyFlag),
        xhsFlag: Number(xhsFlag),
        oneFiveWNumber: Number(oneFiveWNumber),
        fiveTenWNumber: Number(fiveTenWNumber),
        tenPlusWNumber: Number(tenPlusWNumber),
        threeQNumber: Number(threeQNumber),
        threeWNumber: Number(threeWNumber),
        drUserNumber: Number(this.parentData.tiyanguan),
        consumeType: Number(this.parentData.jineType),
        expertMoney: Number(expertMoney),
        drUserMoney: Number(drUserMoney),
        consumeTicketType: Number(consumeTicketType),
        consumeTicketNumber: Number(consumeTicketNumber),
        userWechat: this.form.wxno,
        userEmail: this.form.email,
        userPhone: this.form.phone,
        appraisalMoney: this.parentData.money,
        code: this.form.code,
        expertTotalMoney: Number(expertTotalMoney),
        drUserTotalMoney: Number(drUserTotalMoney),
        consultTotalMoney: Number(consultTotalMoney),
        platformTotalMoney: Number(platformTotalMoney),
      })
        .then((resp) => {
          this.$toast("提交成功");
          this.$emit("custom-event");
          //关闭页面
        })
        .catch((err) => {
          this.$toast(err.msg);
          return false;
        })
        .finally(() => {});
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='less' scoped>
/deep/ .el-dialog__header {
  border-bottom: 1px solid #eee;
}
/deep/ .el-dialog__title {
  font-weight: bold;
  color: #000;
}
/deep/ .el-form-item__label {
  font-weight: bold;
  color: #000;
}
/deep/ .el-popup-parent--hidden {
  padding-right: 0px !important;
  overflow: hidden !important;
}
</style>