<template>
  <div
    class="trends_card"
    @click="showDetail()"
  >
    <div class="body">
      <div class="imgBody">
        <el-image
          style="width: 100%; height: 100%"
          :src="image"
          fit="cover"
        ></el-image>
      </div>
      <div class="content">
        <div class="title">{{ data.title }}</div>
        <div
          class="desc"
          v-html="data.trendsDesc"
        ></div>

        <div class="date">{{ data.createTime.substring(0, 10) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    image: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    showDetail() {
      console.log(this.data);
      if (this.data.type == "article" || this.data.type == "1") {
        //文章
        window.open(this.data.contentLink);
        // this.$router.push("/article")
      } else if (this.data.type == "video" || this.data.type == "3") {
        //视频
        // this.$router.push("/video")
        this.$router.push({
          name: "videoPage",
          params: {
            data: this.data, // 请求参数在这
          },
        });
      } else if (this.data.type == "applet") {
        this.$router.push("/wechatApplet");
        //小程序
        //#小程序://大人来也调研/OFRtkYPT4NzqT5u
      } else if (this.data.type == "questionnaire" || this.data.type == "2") {
        //问卷
        this.$router.push({
          name: "weChatPaperPage",
          params: {
            data: this.data, // 请求参数在这
          },
        });
      } else if (this.data.type == "4") {
        //大人来也文章
        this.$router.push({
          name: "drlyArticlePage",
          params: {
            data: this.data, // 请求参数在这
          },
        });
      }
    },
  },
  created() { },
  mounted() { },
};
</script>
<style lang='less' scoped>
/* 特定于桌面的样式 */
@media screen and (min-width: 1280px) {
  .trends_card {
    .body {
      .content {
        padding: 1.25rem;
        height: calc(186px - 2.5rem);
        position: relative;
        .title {
          font-size: 1.25rem;
          line-height: 1.75rem;
          color: #111925;
          font-weight: 500;
          margin-bottom: 0.5rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
        .desc {
          font-size: 1rem;
          line-height: 1.5rem;
          color: #646971;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
        .date {
          position: absolute;
          margin-top: 0.5rem;
          --tw-text-opacity: 1;
          color: rgb(184 186 190 / var(--tw-text-opacity));
          font-size: 0.875rem;
          bottom: 20px;
        }
      }
    }
  }
}
/* 特定于移动设备的样式 */
@media screen and (max-width: 1279px) {
  .trends_card {
    .body {
      .content {
        padding: 1.25rem;
        height: calc(186px - 2.5rem);
        position: relative;
        .title {
          font-size: 1.25rem;
          line-height: 1.75rem;
          color: #111925;
          font-weight: 500;
          margin-bottom: 0.5rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
        .desc {
          font-size: 1rem;
          line-height: 1.5rem;
          color: #646971;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
        .date {
          position: absolute;
          margin-top: 0.5rem;
          --tw-text-opacity: 1;
          color: rgb(184 186 190 / var(--tw-text-opacity));
          font-size: 0.875rem;
          bottom: 20px;
        }
      }
    }
  }
}

/* 特定于移动设备的样式 */
@media screen and (max-width: 1024px) {
  .trends_card {
    .body {
      .content {
        padding: 1rem;
        height: calc(168px - 2rem);
        position: relative;
        .title {
          font-size: 1rem;
          line-height: 1.5rem;
          color: #111925;
          font-weight: 500;
          margin-bottom: 0.5rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
        .desc {
          font-size: 0.75rem;
          line-height: 1.25rem;
          color: #646971;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;

          margin-bottom: 1rem;
        }
        .date {
          position: absolute;
          margin-top: 0.5rem;
          --tw-text-opacity: 1;
          color: rgb(184 186 190 / var(--tw-text-opacity));
          font-size: 0.625rem;
          bottom: 20px;
        }
      }
      .imgBody {
        width: 100%;
        min-height: 130px !important;
        max-height: 168px !important;
        height: calc(100vw - 60px) !important;
      }
    }
  }
}
.trends_card {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 1.25rem;
  .body {
    border-radius: 16px;

    .imgBody {
      width: 100%;
      min-height: 160px;
      max-height: 310px;
      height: 18vw;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      overflow: hidden;
      /deep/ img {
        transition: transform 0.3s ease, filter 0.3s ease; /* 平滑过渡效果 */
      }
    }
    .content {
      //   width: 100%;

      background-color: #fff;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      transition: box-shadow 0.3s; /* 平滑过渡阴影效果 */
    }
  }
  .body:hover {
    cursor: pointer;
    .content {
      box-shadow: 0px 5px 10px 2px rgba(83, 83, 83, 0.2); /* 鼠标悬浮时的阴影 */
      .title {
        text-decoration: underline;
        color: #4a8ee9 !important;
      }
    }
    .imgBody {
      /deep/ img {
        transform: scale(1.2); /* 放大10% */
        filter: brightness(80%); /* 鼠标悬浮时亮度降低到50% */
      }
    }
  }
}
</style>