var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drlyTrends"},[_c('div',{staticClass:"headImg",style:({
      width: '100%',
      backgroundImage: 'url(' + require('@/assets/trends/head.png') + ')',
      backgroundSize: '100% 100%',
    })},[_c('div',{staticClass:"head_body"},[_c('div',{staticClass:"head_title"},[_vm._v("资讯动态")]),_c('img',{attrs:{"src":require('@/assets/trends/more.png')}})])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"menu"},[_c('div',{class:_vm.active == '全部' ? 'menu_item active' : 'menu_item',on:{"click":() => {
            _vm.active = '全部';
          }}},[_vm._v(" 全部 ")])]),_c('div',{staticClass:"content"},_vm._l((_vm.trendsData),function(c){return _c('card',{key:c.guid,attrs:{"data":c}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }