// router.js
import Vue from 'vue';
import VueRouter from 'vue-router';

import HomePage from './views/home.vue';
import DrInstitutePage from './views/dr_institute.vue';
import ContactUsPage from './views/contact_us.vue';
import BusinessHandbookPage from './views/business_handbook.vue';

import DrlyTrendsPage from './views/trends/drlyTrends.vue';

import DryjsTrendsPage from './views/trends/dryjsTrends.vue';

import ArticlePage from './views/trends/article.vue';
import VideoPage from './views/trends/video.vue';
import WechatAppletPage from './views/trends/wechat_applet.vue';
import WeChatPaperPage from './views/trends/wechat_paper.vue';

import DrlyArticlePage from './views/trends/drly_article.vue';


import AttractUusersPage from './views/attract_users.vue';


import WXGujiaUrl from './views/appAttractUsers/customized_one.vue';

 
Vue.use(VueRouter);
 
const routes =  [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/home',
      name: 'home',
      component: HomePage
    },
    {
      path: '/drInstitute',
      name: 'drInstitutePage',
      component: DrInstitutePage
    },
    {
      path: '/contactUs',
      name: 'contactUsPage',
      component: ContactUsPage
    },
    {
      path: '/businessHandbook',
      name: 'businessHandbookPage',
      component: BusinessHandbookPage
    },
    {
      path: '/article',
      name: 'articlePage',
      component: ArticlePage
  },
        {
      path: '/drlyArticle',
      name: 'drlyArticlePage',
      component: DrlyArticlePage
    },
    {
      path: '/video',
      name: 'videoPage',
      component: VideoPage
    },
    {
      path: '/wechatApplet',
      name: 'wechatAppletPage',
      component: WechatAppletPage
  },
      {
      path: '/weChatPaper',
      name: 'weChatPaperPage',
      component: WeChatPaperPage
  },
  {
    path: '/drlyTrends',
    name: 'drlyTrendsPage',
    component:DrlyTrendsPage
  },
    {
    path: '/dryjsTrends',
    name: 'dryjsTrendsPage',
    component:DryjsTrendsPage
  },
  {
    path: '/introduceTrafficPromotion',
    name: 'attracUusersPage',
    component: AttractUusersPage
  },
  {
    path: '/wxGujiaUrl',
    name: 'wxGujiaUrl',
    component: WXGujiaUrl
  },
  ];
 

const router = new VueRouter({
    routes
})

router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
});
export default router;