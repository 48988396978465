var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"rightAligned"},[_c('div',{staticStyle:{"display":"grid","place-items":"center"}},[_c('img',{staticClass:"image1",attrs:{"src":require('@/assets/dingwei1.png')}})]),_c('div',[_c('el-image',{staticClass:"image2",attrs:{"src":require('@/assets/dingwei2.jpg'),"preview-src-list":[require('@/assets/dingwei2.jpg')],"fit":"contain"}})],1),_c('div',{staticClass:"button1"},[_vm._v("扫描了解大人来也")]),_c('div',{staticClass:"button2",staticStyle:{"font-weight":"bold"},on:{"click":_vm.liaojie}},[_vm._v(" 点击了解大人来也 ")])])]),_c('div',{staticClass:"contactUs"},[_c('div',{staticClass:"t1"},[_vm._v("服务热线")]),_c('div',{staticClass:"t2"},[_vm._v("欢迎您通过邮件方式与我们取得联络")]),_c('div',{staticClass:"body"},[_c('us-card',{attrs:{"title":'商务合作',"desc":'biz@huami-tech.com',"jump":() => {
            _vm.mailto('biz@huami-tech.com');
          }}}),_c('us-card',{attrs:{"title":'客服支持',"desc":'service@huami-tech.com',"jump":() => {
            _vm.mailto('service@huami-tech.com');
          }}}),_c('us-card',{attrs:{"title":'市场活动',"desc":'marketing@huami-tech.com',"jump":() => {
            _vm.mailto('marketing@huami-tech.com');
          }}}),_c('us-card',{attrs:{"title":'第三方开发',"desc":'supports@huami-tech.com',"jump":() => {
            _vm.mailto('supports@huami-tech.com');
          }}}),_c('us-card',{attrs:{"title":'人员招聘',"desc":'hr@huami-tech.com',"jump":() => {
            _vm.mailto('hr@huami-tech.com');
          }}}),_c('us-card',{attrs:{"title":'其他事务',"desc":'darenlaiye@huami-tech.com',"jump":() => {
            _vm.mailto('darenlaiye@huami-tech.com');
          }}}),_c('us-card',{attrs:{"title":'公司地址',"desc":'上海市徐汇区乐山路33号3号楼',"jump":() => {
            _vm.baiduMap('上海市徐汇区乐山路33号3号楼');
          }}}),_c('us-card',{attrs:{"title":'官网',"desc":'www.darenlaiye.com',"jump":() => {
            _vm.jump('www.darenlaiye.com');
          }}}),_c('us-card',{attrs:{"title":'服务咨询',"desc":'联系微信客服',"jump":() => {
            _vm.open('https://work.weixin.qq.com/kfid/kfcac88be45ee8d1bc4');
          }}}),_c('us-card',{staticStyle:{"opacity":"0"}})],1),_c('div',{staticClass:"contact_us_bottom"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }